import AdminSideBar from "../common/AdminSideBar/AdminSideBar";
import React, { useState, useEffect } from "react";
import TableWithPagination from "../../../utils/TableWithPagination/TableWithPagination";
import {
  getFullApiUrl,
  GET_ALL_WORKSPACE,
  GET_ALL_WORKSPACE_MEMBERS,
  DELETE_WORKSPACE_MEMBER,
  UPDATE_WORKSPACE_MEMBER,
  GET_ALL_CAMPAIGNS,
  ADD_NEW_CAMPAIGN,
  DELETE_CAMPAIGN,
  UPDATE_CAMPAIGN,
  GET_ALL_USERS,
} from "../../../utils/apiPath";
import { RiAddLine, RiDeleteBack2Fill } from "react-icons/ri";
import { MdEdit, MdDelete, MdOutlineDeleteSweep } from "react-icons/md";
import Modal from "@mui/material/Modal";
import {
  Box,
  Typography,
  TextField,
  Button,
  useTheme,
  Tooltip,
  IconButton,
} from "@mui/material";
import { IoMdCloseCircle, IoIosAddCircleOutline } from "react-icons/io";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { LiaEdit } from "react-icons/lia";

import Header from "../../common/Header/Header";

const Campaigns = ({ toggleTheme }) => {
  const theme = useTheme();
  const [campaign_data, set_campaign_data] = useState([]);
  const [selectedcampaignsForDelete, setselectedcampaignsForDelete] = useState(
    []
  );
  const [selected_campaign, set_selected_campaign] = useState([]); // State to store selected campaigns for deletion
  const [showDeletecampaignModal, setshowDeletecampaignModal] = useState(false);
  const [selectedcampaignsForBulkDelete, setselectedcampaignsForBulkDelete] =
    useState([]);
  // const memberId = localStorage.getItem('user_id')

  // State to store the selected campaign for deletion

  const [selectedcampaign, setselectedcampaign] = useState({
    campaign_id: "",
    workspace_id: "",
    campaign_name: "",
  });
  const [new_campaign_data, set_new_campaign_data] = useState({
    workspace_id: "",
    campaign_name: "",
  });
  const [newcampaignData, setnewcampaignData] = useState({
    campaign_id: "",
    workspace_id: "",
    campaign_name: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setselectedcampaign({ ...selectedcampaign, [name]: value });
  };
  const handleNewInputChange = (e) => {
    const { name, value } = e.target;
    set_new_campaign_data({ ...newcampaignData, [name]: value });
  };
  const handleAddcampaignSubmit = async () => {
    try {
      // Send a POST request to the server
      const response = await fetch(`${getFullApiUrl(ADD_NEW_CAMPAIGN)}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(new_campaign_data),
      });

      // Check if the response is OK
      if (!response.ok) {
        throw new Error("Failed to add new workspace member");
      }

      // Handle success
      console.log("New workspace member added successfully");

      // Close the modal or perform any other actions as needed
      handleAddClose();

      // Fetch the updated list of workspace members
      fetchData();
    } catch (error) {
      // Handle errors
      console.error("Error adding new workspace member:", error.message);
    }
  };

  const fetchData = async () => {
    const workspaces = await fetchworkspaceList();
    await fetchCampaignList(workspaces);
  };
  useEffect(() => {
    fetchData();
  }, [1000]);

  const fetchworkspaceList = async () => {
    try {
      const response = await fetch(getFullApiUrl(GET_ALL_WORKSPACE));
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // const fetchCampaignList = async () => {
  //   try {

  //     const response = await fetch(
  //       `${getFullApiUrl(GET_ALL_CAMPAIGNS)}`
  //     );

  //     console.log(response);
  //     if (!response.ok) {
  //       throw new Error("Network response was not ok");
  //     }
  //     const data = await response.json();

  //     console.log("data", data);
  //     set_campaign_data(data);
  //     // (usedCreditPointsTotal);
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  // };
  const fetchCampaignList = async (workspace) => {
    try {
      const response = await fetch(`${getFullApiUrl(GET_ALL_CAMPAIGNS)}`);

      console.log(response);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const CampaignListData = await response.json();

      // console.log("data", data);
      const updatedCampaignData = await CampaignListData.map((campaignRow) => {
        const campaignWorkspace = workspace.find(
          (sub) => sub.workspace_id === campaignRow.workspace_id
        );

        if (campaignWorkspace) {
          campaignRow.workspace_name = campaignWorkspace.workspace_name;
        }
        return campaignRow;
      });

      set_campaign_data(updatedCampaignData);
      // const usedCreditPointsTotal = data.map(member => member.used_credit_points_total);

      // Store usedCreditPointsTotal in localStorage
      // localStorage.setItem("credit_points", JSON.stringify(usedCreditPointsTotal));
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleIndividualDeleteClick = (campaign) => {
    setselectedcampaignsForDelete([campaign]); // Select only the clicked campaign
    setshowDeletecampaignModal(true);
  };

  const handleBulkcampaignDeleteClick = async (params) => {
    if (!params || params.length === 0 || !Array.isArray(params)) {
      console.error("Invalid or empty params:", params);
      return;
    }

    try {
      const response = await fetch(getFullApiUrl(GET_ALL_CAMPAIGNS));
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const allcampaigns = await response.json();

      const campaignDetails = params.map((campaignId) => {
        const campaigns = allcampaigns.find(
          (u) => u.campaign_id === campaignId
        );
        console.log("ss", campaigns);
        return campaigns
          ? `${campaigns.campaign_name} (${campaigns.description})`
          : "";
      });
      console.log("detail", campaignDetails);

      setselectedcampaignsForBulkDelete(params);
      setselectedcampaignsForDelete(campaignDetails);
      setShowBulkDeleteModal(true);
    } catch (error) {
      console.error("Error fetching campaign data:", error);
    }
  };

  const handleBulkDeleteConfirm = async () => {
    console.log(selectedcampaignsForBulkDelete);
    try {
      if (
        !selectedcampaignsForBulkDelete ||
        !Array.isArray(selectedcampaignsForBulkDelete)
      ) {
        console.error(
          "Invalid or empty params:",
          selectedcampaignsForBulkDelete
        );
        return;
      } else {
        await handleBulkDelete(selectedcampaignsForBulkDelete);
        // Close modal and refresh campaign list
        setShowBulkDeleteModal(false);
        fetchData();
      }
    } catch (error) {
      console.error("Error deleting campaigns in bulk:", error.message);
    }
  };
  const handleDeletecampaignConfirm = async () => {
    try {
      await Promise.all(
        selectedcampaignsForDelete.map((campaign) =>
          handleDeletecampaign(campaign.campaign_id)
        )
      );
      setselectedcampaignsForDelete([]);
      setshowDeletecampaignModal(false);
      fetchData(); // Refresh campaign list after deletion
    } catch (error) {
      console.error("Error deleting campaigns:", error.message);
    }
  };
  const handleBulkDelete = async (selected_campaign) => {
    set_selected_campaign(selected_campaign);
    try {
      // Iterate over selected campaigns and delete each one
      for (let campaignId of selected_campaign) {
        await handleDeletecampaign(campaignId);
      }
      console.log("Bulk delete successful");
      // After bulk deletion, refresh the campaign list
      fetchData();
    } catch (error) {
      console.error("Error deleting campaigns in bulk:", error.message);
    }
  };

  const handleDeletecampaign = async (campaignId) => {
    console.log("delete click");
    console.log(campaignId);
    try {
      const response = await fetch(getFullApiUrl(DELETE_CAMPAIGN), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ campaign_id: campaignId }),
      });

      if (!response.ok) {
        throw new Error("Failed to delete campaign");
      }

      console.log("campaign deleted successfully:", campaignId);
      // Refresh campaign list after deletion
      fetchData();
    } catch (error) {
      console.error("Error deleting campaign:", error.message);
    }
  };
  const handleEditcampaign = (campaign) => {
    setselectedcampaign(campaign);
    setShowEditcampaignModal(true);
  };

  const handleEditcampaignData = async () => {
    try {
      const response = await fetch(getFullApiUrl(UPDATE_CAMPAIGN), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(selectedcampaign),
      });

      if (!response.ok) {
        throw new Error("Failed to edit campaign");
      }

      console.log("campaign edited successfully");
      setShowEditcampaignModal(false);
      // Refresh campaign list after editing
      fetchData();
    } catch (error) {
      console.error("Error editing campaign:", error.message);
    }
  };

  const [showAddcampaignForm, setShowAddcampaignForm] = React.useState(false);
  const [showEditcampaignModal, setShowEditcampaignModal] =
    React.useState(false);
  const [showBulkDeleteModal, setShowBulkDeleteModal] = React.useState(false);
  const handleAddOpen = () => setShowAddcampaignForm(true);
  const handleAddClose = () => setShowAddcampaignForm(false);
  const handleEditOpen = () => setShowEditcampaignModal(true);
  const handleEditClose = () => setShowEditcampaignModal(false);
  const handleBulkDeleteOpen = () => setShowBulkDeleteModal(true);
  const handleBulkDeleteClose = () => setShowBulkDeleteModal(false);

  const handleDeletecampaignModalClose = () => {
    setshowDeletecampaignModal(false);
  };

  return (
    <div className="page_Section">
      <div className="leftSection_withMenu">
        <AdminSideBar />
      </div>
      <div className="rightSection_withMenu">
      <div className="headerSection_withMenu">
          <Header onToggleTheme={toggleTheme} />
        </div>
        <div className="bodySection">
          <div className="admin_page_heading">Campaign Management</div>

          <div>
            <TableWithPagination
            key={campaign_data.length}

              data={campaign_data}
              fields={[
                {
                  type: "id",
                  field: "campaign_id",
                  headerName: "",
                  minWidth: 1,
                  width: 1,
                  maxWidth: 1,
                },
                {
                  type: "action",
                  field: "actions",
                  headerName: "Actions",
                  width: 65,
                  renderCell: (params) => {
                    return (
                      <div className="action_icons_">
                        <Tooltip title="Edit">
                          <IconButton
                            onClick={() => handleEditcampaign(params)}
                          >
                            <LiaEdit className="table_action_icon" />
                          </IconButton>
                        </Tooltip>

                        <Tooltip title="Delete">
                          <IconButton
                            onClick={() => handleIndividualDeleteClick(params)}
                          >
                            <DeleteOutlineIcon className="table_action_icon" />
                          </IconButton>
                        </Tooltip>
                      </div>
                    );
                  },
                },

                {
                  type: "field",
                  field: "campaign_name",
                  headerName: "Campaign Name",
                  search_type: "text_search",
                  minWidth: 250,
                  width: 280,
                  maxWidth: 400,
                },
                {
                  type: "field",
                  field: "workspace_name",
                  search_type: "text_search",
                  headerName: "Workspace",
                  minWidth: 360,
                  width: 360,
                  maxWidth: 380,
                },
                {
                  type: "field",
                  field: "created_at",
                  headerName: "Created At",
                  search_type: "date_search",
                  minWidth: 250,
                  width: 280,
                  maxWidth: 400,
                },
                {
                  type: "field",
                  field: "last_used",
                  headerName: "Last Used",
                  search_type: "date_search",
                  minWidth: 250,
                  width: 280,
                  maxWidth: 400,
                },
              ]}
              bulk_actions={[
                {
                  action: "add",
                  renderCell: (params) => {
                    return (
                      <Tooltip title="Add Workspace_member">
                        <div
                          className="bulk_classify_button download_icons"
                          onClick={handleAddOpen}
                        >
                          <IoIosAddCircleOutline />
                        </div>
                      </Tooltip>
                    );
                  },
                },
                {
                  action: "delete_multiple",
                  renderCell: (params) => {
                    return (
                      <Tooltip title="Delete Selected Workspace_members">
                        <div
                          onClick={() => handleBulkcampaignDeleteClick(params)}
                          className="bulk_delete_button download_icons"
                        >
                          <MdOutlineDeleteSweep fontSize={30}/>
                        </div>
                      </Tooltip>
                    );
                  },
                },
              ]}
            />
            <Modal
              open={showAddcampaignForm}
              onClose={handleAddClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box id="model_box">
                <div className="add-user-form">
                  <div className="modal_header">Add New Workspace</div>
                  <Box
                    component="form"
                    sx={{
                      "& > :not(style)": {
                        m: 2,
                        width: "80%",
                        marginLeft: "10%",
                      },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      id="workspace_id"
                      label="workspace"
                      variant="standard"
                      name="workspace_id"
                      value={new_campaign_data.workspace_id}
                      onChange={handleNewInputChange}
                      className="modal_form_input"
                    />
                  </Box>
                  <Box
                    component="form"
                    sx={{
                      "& > :not(style)": {
                        m: 2,
                        width: "80%",
                        marginLeft: "10%",
                      },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      id="campaign_name"
                      label="campaign_name"
                      variant="standard"
                      name="campaign_name"
                      value={new_campaign_data.campaign_name}
                      onChange={handleNewInputChange}
                      className="modal_form_input modal_textarea"
                      multiline
                      rows={4}
                    />
                  </Box>

                  <button
                    onClick={handleAddcampaignSubmit}
                    className="add-user-btn"
                  >
                    Add campaign{" "}
                  </button>
                </div>
              </Box>
            </Modal>

            <Modal
              open={showEditcampaignModal}
              onClose={handleEditClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box id="model_box">
                <div className="add-user-form">
                  <div className="modal_header">Update campaign</div>
                  <Box
                    component="form"
                    sx={{
                      "& > :not(style)": {
                        m: 2,
                        width: "80%",
                        marginLeft: "10%",
                      },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      id="campaign_name"
                      label="campaign Name"
                      variant="standard"
                      name="campaign_name"
                      value={
                        selectedcampaign ? selectedcampaign.campaign_name : ""
                      }
                      onChange={handleInputChange}
                      className="modal_form_input"
                    />
                  </Box>
                  {/* <Box
                    component="form"
                    sx={{
                      "& > :not(style)": {
                        m: 2,
                        width: "80%",
                        marginLeft: "10%",
                      },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      id="workspace_id"
                      label="Workspace"
                      variant="standard"
                      name="workspace_id"
                      value={
                        selectedcampaign ? selectedcampaign.workspace_id : ""
                      }
                      onChange={handleInputChange}
                      className="modal_form_input modal_textarea"
                      multiline
                      rows={4}
                      style={{
                        color: theme.palette.text.primary,
                      }}
                    />
                  </Box> */}
                  <button
                    onClick={handleEditcampaignData}
                    className="add-user-btn"
                  >
                    Update campaign
                  </button>
                </div>
              </Box>
            </Modal>

            <Modal
              open={showDeletecampaignModal}
              onClose={handleDeletecampaignModalClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box id="model_box">
                <div className="delete-user-form">
                  <div className="modal_header">Delete campaign(s)</div>
                  {selectedcampaignsForDelete.map((campaign) => (
                    <div key={campaign.campaign_id}>
                      <div className="modal_text">
                        campaign: {campaign.campaign_name} (
                        {campaign.description})
                      </div>
                    </div>
                  ))}
                  <div className="modal_text">
                    Are you sure you want to delete selected campaign(s)?
                  </div>
                  <div className="form-group">
                    <Button onClick={handleDeletecampaignConfirm} color="error">
                      <MdDelete className="formIcons" />
                    </Button>
                    <Button onClick={handleDeletecampaignModalClose}>
                      <IoMdCloseCircle className="formIcons" />
                    </Button>
                  </div>
                </div>
              </Box>
            </Modal>
            {/*Bulk Delete Workspace_members Model */}
            <Modal
              open={showBulkDeleteModal}
              onClose={handleBulkDeleteClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box id="model_box">
                <div className="delete-user-form">
                  <div className="modal_header">Delete campaign(s)</div>

                  {selectedcampaignsForDelete.map((campaign) => (
                    <div key={campaign}>
                      <div className="modal_text">
                        campaign Name: {campaign}{" "}
                      </div>
                    </div>
                  ))}
                  <div className="modal_text">
                    Are you sure you want to delete selected campaign(s)?
                  </div>
                  <div className="form-group">
                    <Button onClick={handleBulkDeleteConfirm} color="error">
                      <MdDelete className="formIcons" />
                    </Button>
                    <Button onClick={handleBulkDeleteClose}>
                      <IoMdCloseCircle className="formIcons" />
                    </Button>
                  </div>
                </div>
              </Box>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Campaigns;
