import "./Subscriptions.css";
import AdminSideBar from "../common/AdminSideBar/AdminSideBar";

import React, { useState, useEffect } from "react";
import TableWithPagination from "../../../utils/TableWithPagination/TableWithPagination";
import {
  GET_ALL_SUBSCRIPTION,
  ADD_NEW_SUBSCRIPTION,
  DELETE_SUBSCRIPTION,
  UPDATE_SUBSCRIPTION,
  getFullApiUrl,
} from "../../../utils/apiPath";
import { RiAddLine, RiDeleteBack2Fill } from "react-icons/ri";
import { MdEdit, MdDelete, MdOutlineDeleteSweep } from "react-icons/md";
import Modal from "@mui/material/Modal";
import {
  Box,
  Typography,
  TextField,
  Button,
  useTheme,
  Tooltip,
  IconButton,
} from "@mui/material";
import { IoMdCloseCircle } from "react-icons/io";
import { LiaExpeditedssl } from "react-icons/lia";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditNoteIcon from "@mui/icons-material/";
import { LiaEdit } from "react-icons/lia";
import Header from "../../common/Header/Header";

const Subscriptions = ({ toggleTheme }) => {
  const theme = useTheme();

  const [subscription_data, set_subscription_data] = useState([]);
  // const [selectedSubscriptionForDelete, setselectedSubscriptionForDelete] = useState(null);
  // const [selectedBulkSubscriptionsForDelete, setselectedBulkSubscriptionsForDelete] = useState([]);
  const [selected_subscription, set_selected_subscription] = useState([]);

  const [selectedSubscriptionsForDelete, setselectedSubscriptionsForDelete] =
    useState([]); // State to store selected subscriptions for deletion
  // const [showDeleteBulkSubscriptionModal, setShowDeleteBlusubscriptionModal] = useState()

  const [showDeleteSubscriptionModal, setshowDeleteSubscriptionModal] =
    useState(false);
  const [
    selectedSubscriptionsForBulkDelete,
    setselectedSubscriptionsForBulkDelete,
  ] = useState([]);

  // State to store the selected subscription for deletion

  const [selectedSubscription, setselectedSubscription] = useState({
    subscription_id: "",
    subscription_name: "",
    sub_description: "",
    discount: "",
    credit_points: " ",
    price: " ",
  });
  const [newSubscriptionData, setnewSubscriptionData] = useState({
    subscription_name: "",
    sub_description: "",
    discount: "",
    credit_points: " ",
    price: " ",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setselectedSubscription({ ...selectedSubscription, [name]: value });
    if(name=="price")
    {
      if ((value.match(/\./g) || []).length <= 1)
      {
        setselectedSubscription({ ...selectedSubscription, [name]: value });
      }
    }
  };
  const handleNewInputChange = (e) => {
    const { name, value } = e.target;
    //console.log("name");
    //console.log(name);
    //console.log("value");
    console.log(value);
    
    // Check if the value contains more than one dot
    setnewSubscriptionData({ ...newSubscriptionData, [name]: value });
    if(name=="price")
    {
      if ((value.match(/\./g) || []).length <= 1)
      {
        setnewSubscriptionData({ ...newSubscriptionData, [name]: value });
      }
    }
  };
  const handleAddSubscriptionSubmit = async () => {
    try {
      const response = await fetch(getFullApiUrl(ADD_NEW_SUBSCRIPTION), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newSubscriptionData),
      });

      if (!response.ok) {
        throw new Error("Failed to add new subscription");
      }
      handleAddClose();

      setnewSubscriptionData({
        subscription_name: "",
        sub_description: "",
        discount: "",
        credit_points: " ",
        price: " ",
      });
      fetchsubscriptionList();

      console.log("New Subscription added successfully");
    } catch (error) {
      console.error("Error adding new Subscription:", error.message);
    }
  };
  useEffect(() => {
    fetchsubscriptionList();
  }, [1000]);

  const fetchsubscriptionList = async () => {
    try {
      const response = await fetch(getFullApiUrl(GET_ALL_SUBSCRIPTION));
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      console.log("data", data);
      set_subscription_data(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleIndividualDeleteClick = (subscription) => {
    setselectedSubscriptionsForDelete([subscription]); // Select only the clicked subscription
    setshowDeleteSubscriptionModal(true);
  };

  const handleBulkSubscriptionDeleteClick = async (params) => {
    if (!params || params.length === 0 || !Array.isArray(params)) {
      console.error("Invalid or empty params:", params);
      return;
    }

    try {
      const response = await fetch(getFullApiUrl(GET_ALL_SUBSCRIPTION));
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const allsubscriptions = await response.json();

      // Extract subscription details including name and email
      const SubscriptionDetails = params.map((subscriptionId) => {
        const subscriptions = allsubscriptions.find(
          (u) => u.subscription_id === subscriptionId
        );
        console.log("ss", subscriptions);
        return subscriptions
          ? `${subscriptions.subscription_name} (${subscriptions.sub_description})`
          : "";
      });
      console.log("detail", SubscriptionDetails);

      setselectedSubscriptionsForBulkDelete(params);
      setselectedSubscriptionsForDelete(SubscriptionDetails);
      setShowBulkDeleteModal(true);
    } catch (error) {
      console.error("Error fetching subscription data:", error);
    }
  };

  const handleBulkDeleteConfirm = async () => {
    // console.log(selectedSubscriptionsForBulkDelete)
    try {
      if (
        !selectedSubscriptionsForBulkDelete ||
        !Array.isArray(selectedSubscriptionsForBulkDelete)
      ) {
        console.error(
          "Invalid or empty params:",
          selectedSubscriptionsForBulkDelete
        );
        return;
      } else {
        await handleBulkDelete(selectedSubscriptionsForBulkDelete);
        // Close modal and refresh subscription list
        setShowBulkDeleteModal(false);
        fetchsubscriptionList();
      }
    } catch (error) {
      console.error("Error deleting subscriptions in bulk:", error.message);
    }
  };
  const handleDeletesubscriptionConfirm = async () => {
    try {
      await Promise.all(
        selectedSubscriptionsForDelete.map((subscription) =>
          handleDeletesubscription(subscription.subscription_id)
        )
      );
      setselectedSubscriptionsForDelete([]);
      setshowDeleteSubscriptionModal(false);
      fetchsubscriptionList(); // Refresh subscription list after deletion
    } catch (error) {
      console.error("Error deleting subscriptions:", error.message);
    }
  };
  const handleBulkDelete = async (selected_subscription) => {
    set_selected_subscription(selected_subscription);
    try {
      // Iterate over selected subscriptions and delete each one
      for (let subscriptionId of selected_subscription) {
        await handleDeletesubscription(subscriptionId);
      }
      console.log("Bulk delete successful");
      // After bulk deletion, refresh the subscription list
      fetchsubscriptionList();
    } catch (error) {
      console.error("Error deleting subscriptions in bulk:", error.message);
    }
  };

  const handleDeletesubscription = async (subscriptionId) => {
    try {
      const response = await fetch(getFullApiUrl(DELETE_SUBSCRIPTION), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ subscription_id: subscriptionId }),
      });

     
      if (!response.ok) {
        const data = await response.json();
        alert(data.error)
        
        throw new Error("Failed to delete subscription");
      }
  
      console.log("subscription deleted successfully:", subscriptionId);
      // Refresh subscription list after deletion
      fetchsubscriptionList();
    } catch (error) {
      
      console.error("Error deleting subscription:", error.message);
    }
  };

  const handleEditsubscription = (subscription) => {
    setselectedSubscription(subscription);
    setShowEditsubscriptionModal(true);
  };

  const handleEditsubscriptionData = async () => {
    try {
      const response = await fetch(getFullApiUrl(UPDATE_SUBSCRIPTION), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(selectedSubscription),
      });

      if (!response.ok) {
        throw new Error("Failed to edit subscription");
      }

      console.log("subscription edited successfully");
      setShowEditsubscriptionModal(false);
      // Refresh subscription list after editing
      fetchsubscriptionList();
    } catch (error) {
      console.error("Error editing subscription:", error.message);
    }
  };

  //model open

  const [showAddsubscriptionForm, setShowAddsubscriptionForm] =
    React.useState(false);
  const [showEditsubscriptionModal, setShowEditsubscriptionModal] =
    React.useState(false);
  const [showBulkDeleteModal, setShowBulkDeleteModal] = React.useState(false);
  const handleAddOpen = () => setShowAddsubscriptionForm(true);
  const handleAddClose = () => setShowAddsubscriptionForm(false);
  const handleEditOpen = () => setShowEditsubscriptionModal(true);
  const handleEditClose = () => setShowEditsubscriptionModal(false);
  const handleBulkDeleteOpen = () => setShowBulkDeleteModal(true);
  const handleBulkDeleteClose = () => setShowBulkDeleteModal(false);

  const setPlaceholderColor = () => {
    const styleTag = document.createElement("style");
    styleTag.innerHTML = `
      input::placeholder,textarea::placeholder {
        color: ${theme.palette.text.primary};
      }
    `;
    document.head.appendChild(styleTag);
  };

  setPlaceholderColor();
  // const handleDeletesubscriptionModalOpen = (subscription) => {
  //   setselectedSubscriptionForDelete(subscription);
  //   setshowDeleteSubscriptionModal(true);
  // };

  // Function to handle the closure of the delete modal
  const handleDeletesubscriptionModalClose = () => {
    setshowDeleteSubscriptionModal(false);
  };

  return (
    <div className="page_Section">
      <div className="leftSection_withMenu">
        <AdminSideBar />
      </div>
      <div className="rightSection_withMenu">
      <div className="headerSection_withMenu">
          <Header onToggleTheme={toggleTheme} />
        </div>
        <div className="bodySection">
          <div className="admin_page_heading">Subscriptions Management</div>
          <div>
            <TableWithPagination
              data={subscription_data}
              key={subscription_data.length}

              fields={[
                {
                  type: "id",
                  field: "subscription_id",
                  headerName: "",
                  minWidth: 1,
                  width: 1,
                  maxWidth: 380,
                },
                {
                  type: "field",
                  field: "subscription_name",
                  headerName: "Subscription Name",
                  search_type: "text_search",
                  minWidth: 150,
                  width: 220,
                  maxWidth: 240,
                },
                {
                  type: "field",
                  field: "sub_description",
                  headerName: "Subscription Description",
                  search_type: "text_search",
                  minWidth: 290,
                  width: 280,
                  maxWidth: 350,
                },
                {
                  type: "field",
                  field: "discount",
                  headerName: "Discount",
                  search_type: "text_search",
                  minWidth: 135,
                  width: 140,
                  maxWidth: 180,
                },
                {
                  type: "field",
                  field: "credit_points",
                  headerName: "Credit Points",
                  search_type: "number_search",
                  minWidth: 150,
                  width: 170,
                  maxWidth: 180,
                },
                {
                  type: "field",
                  field: "price",
                  headerName: "price",
                  search_type: "text_search",
                  minWidth: 135,
                  width: 120,
                  maxWidth: 180,
                },
                {
                  type: "action",
                  field: "actions",
                  headerName: "Actions",
                  minWidth: 120,
                  width: 120,
                  maxWidth: 200,
                  renderCell: (params) => {
                    return (
                      <div className="action_icons_">
                        <Tooltip title="Edit">
                          <IconButton
                            onClick={() => handleEditsubscription(params)}
                          >
                            <LiaEdit className="table_action_icon" />
                          </IconButton>
                        </Tooltip>

                        <Tooltip title="Delete">
                          <IconButton
                            onClick={() => handleIndividualDeleteClick(params)}
                          >
                            <DeleteOutlineIcon className="table_action_icon" />
                          </IconButton>
                        </Tooltip>
                      </div>
                    );
                  },
                },
              ]}
              bulk_actions={[
                {
                  action: "add",
                  renderCell: (params) => {
                    return (
                      <Tooltip title="Add New Subscription">
                        <div
                          onClick={handleAddOpen}
                          className="bulk_classify_button download_icons"
                        >
                          <RiAddLine />
                        </div>
                      </Tooltip>
                    );
                  },
                },
                {
                  action: "delete_multiple",
                  renderCell: (params) => {
                    return (
                      <Tooltip title="Delete Selected Subscription">
                        <div
                          onClick={() =>
                            handleBulkSubscriptionDeleteClick(params)
                          }
                          className="bulk_delete_button download_icons"
                        >
                          <MdOutlineDeleteSweep fontSize={30}/>
                        </div>
                      </Tooltip>
                    );
                  },
                },
              ]}
            />
            <Modal
              open={showAddsubscriptionForm}
              onClose={handleAddClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box id="model_box">
                <div className="add-user-form">
                  <div className="modal_header">Add New Subscription Name</div>
                  <Box
                    component="form"
                    sx={{
                      "& > :not(style)": {
                        m: 2,
                        width: "80%",
                        marginLeft: "10%",
                      },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      id="subscription_name"
                      label="Subscription Name"
                      variant="standard"
                      name="subscription_name"
                      value={newSubscriptionData.subscription_name}
                      onChange={handleNewInputChange}
                      className="modal_form_input"
                    />
                  </Box>
                  <Box
                    component="form"
                    sx={{
                      "& > :not(style)": {
                        m: 2,
                        width: "80%",
                        marginLeft: "10%",
                      },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      id="sub_description"
                      label="Subscription Description"
                      variant="standard"
                      name="sub_description"
                      value={newSubscriptionData.sub_description}
                      onChange={handleNewInputChange}
                      className="modal_form_input modal_textarea"
                      multiline
                      rows={4}
                    />
                  </Box>
                  <Box
                    component="form"
                    sx={{
                      "& > :not(style)": {
                        m: 2,
                        width: "80%",
                        marginLeft: "10%",
                      },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      type="number"
                      id="discount"
                      label="Discount"
                      variant="standard"
                      name="discount"
                      onInput={(e) => {
                        e.target.value = Math.max(0, parseInt(e.target.value))
                          .toString()
                          .slice(0, 2);
                      }}
                      min={0}
                      value={newSubscriptionData.discount}
                      onChange={handleNewInputChange}
                      className="modal_form_input"
                    />
                  </Box>
                  <Box
                    component="form"
                    sx={{
                      "& > :not(style)": {
                        m: 2,
                        width: "80%",
                        marginLeft: "10%",
                      },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      type="number"
                      id="credit_points"
                      label="Credit Points"
                      variant="standard"
                      name="credit_points"
                      onInput={(e) => {
                        e.target.value = Math.max(
                          0,
                          parseInt(e.target.value)
                        ).toString();
                      }}
                      min={0}
                      value={newSubscriptionData.credit_points}
                      onChange={handleNewInputChange}
                      className="modal_form_input"
                    />
                  </Box>
                  <Box
                    component="form"
                    sx={{
                      "& > :not(style)": {
                        m: 2,
                        width: "80%",
                        marginLeft: "10%",
                      },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      type="number"
                      id="price"
                      label="Price"
                      variant="standard"
                      name="price"
                     
                      min={0}
                      value={newSubscriptionData.price}
                      onChange={handleNewInputChange}
                      className="modal_form_input"
                    />
                  </Box>

                  <button
                    onClick={handleAddSubscriptionSubmit}
                    className="add-user-btn"
                  >
                    Add Subscription{" "}
                  </button>
                </div>
              </Box>
            </Modal>
            <Modal
              open={showEditsubscriptionModal}
              onClose={handleEditClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box id="model_box">
                <div className="add-user-form">
                  <div className="modal_header">Update Subscription</div>

                  <Box
                    component="form"
                    sx={{
                      "& > :not(style)": {
                        m: 2,
                        width: "80%",
                        marginLeft: "10%",
                      },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      id="subscription_name"
                      label="Subscription Name"
                      variant="standard"
                      name="subscription_name"
                      value={
                        selectedSubscription
                          ? selectedSubscription.subscription_name
                          : ""
                      }
                      onChange={handleInputChange}
                      className="modal_form_input"
                    />
                  </Box>
                  <Box
                    component="form"
                    sx={{
                      "& > :not(style)": {
                        m: 2,
                        width: "80%",
                        marginLeft: "10%",
                      },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      id="sub_description"
                      label="Subscription Description"
                      variant="standard"
                      name="sub_description"
                      value={
                        selectedSubscription
                          ? selectedSubscription.sub_description
                          : ""
                      }
                      onChange={handleInputChange}
                      className="modal_form_input"
                    />
                  </Box>
                  <Box
                    component="form"
                    sx={{
                      "& > :not(style)": {
                        m: 2,
                        width: "80%",
                        marginLeft: "10%",
                      },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      type="number"
                      id="discount"
                      label="Discount"
                      variant="standard"
                      name="discount"
                      onInput={(e) => {
                        e.target.value = Math.max(0, parseInt(e.target.value))
                          .toString()
                          .slice(0, 2);
                      }}
                      value={
                        selectedSubscription
                          ? selectedSubscription.discount
                          : ""
                      }
                      onChange={handleInputChange}
                      className="modal_form_input"
                    />
                  </Box>

                  <Box
                    component="form"
                    sx={{
                      "& > :not(style)": {
                        m: 2,
                        width: "80%",
                        marginLeft: "10%",
                      },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      type="number"
                      id="credit_points"
                      label="Credit Points"
                      variant="standard"
                      name="credit_points"
                      onInput={(e) => {
                        e.target.value = Math.max(
                          0,
                          parseInt(e.target.value)
                        ).toString();
                      }}
                      min={0}
                      value={
                        selectedSubscription ? selectedSubscription.credit_points : ""
                      }
                      onChange={handleInputChange}
                      className="modal_form_input"
                    />
                  </Box>
                  <Box
                    component="form"
                    sx={{
                      "& > :not(style)": {
                        m: 2,
                        width: "80%",
                        marginLeft: "10%",
                      },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      type="number"
                      id="price"
                      label="Price"
                      variant="standard"
                      name="price"
                    
                      min={0}
                      value={
                        selectedSubscription ? selectedSubscription.price : ""
                      }
                      onChange={handleInputChange}
                      className="modal_form_input"
                    />
                  </Box>

                  <button
                    onClick={handleEditsubscriptionData}
                    className="add-user-btn"
                  >
                    Update Subscription
                  </button>
                </div>
              </Box>
            </Modal>
            <Modal
              open={showDeleteSubscriptionModal}
              onClose={handleDeletesubscriptionModalClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box id="model_box">
                <div className="delete-user-form">
                  <div className="modal_text">Delete Subscription(s)</div>
                  {selectedSubscriptionsForDelete.map((subscription) => (
                    <div key={subscription.subscription_id}>
                      <div className="modal_text">
                        Subscription: {subscription.subscription_name} (
                        {subscription.sub_description})
                      </div>
                    </div>
                  ))}
                  <div className="modal_text">
                    Are you sure you want to delete selected subscription(s)?
                  </div>
                  <div className="form-group">
                    <Button
                      onClick={handleDeletesubscriptionConfirm}
                      color="error"
                    >
                      <MdDelete className="formIcons" />
                    </Button>
                    <Button onClick={handleDeletesubscriptionModalClose}>
                      <IoMdCloseCircle className="formIcons" />
                    </Button>
                  </div>
                </div>
              </Box>
            </Modal>
            <Modal
              open={showBulkDeleteModal}
              onClose={handleBulkDeleteClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box id="model_box">
                <div className="delete-user-form">
                  <div className="modal_text">Delete Subscription(s)</div>

                  {selectedSubscriptionsForDelete.map((subscription) => (
                    <div key={subscription}>
                      <div className="modal_text">
                        Subscription Name: {subscription}{" "}
                      </div>
                    </div>
                  ))}
                  <div className="modal_text">
                    Are you sure you want to delete selected subscription(s)?
                  </div>
                  <div className="form-group">
                    <Button onClick={handleBulkDeleteConfirm} color="error">
                      <MdDelete className="formIcons" />
                    </Button>
                    <Button onClick={handleBulkDeleteClose}>
                      <IoMdCloseCircle className="formIcons" />
                    </Button>
                  </div>
                </div>
              </Box>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Subscriptions;
