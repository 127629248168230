import LeftSectionWithMenu from "../common/Header/LeftSectionWithMenu/LeftSectionWithMenu";
import Header from "../common/Header/Header";
import React, { useState, useEffect } from "react";
import TableWithPagination from "../../utils/TableWithPagination/TableWithPagination";
import {
  GET_ALL_TEAM_MEMBERS,
  ADD_NEW_TEAM_MEMBER,
  DELETE_TEAM_MEMBER,
  UPDATE_TEAM_MEMBER,
  getFullApiUrl,
  GET_ALL_TEAM,
  GET_ALL_USERS,
  TEAM_NAME_FROM_ID,
  GET_ALL_TEAM_MEMBERS_OF_TEAMS,
  GET_TEAM_MEMBER_DETAILS,
  GET_TEAM_MEMBER_DESIGNATION,
  GET_WORKSPACES_OF_TEAM_MEMBER,
  GET_ALL_TEAM_MEMBERS_NOT_IN_TEAM,
  GET_USER_ID_BY_NAME,
  GET_TEAM_DETAILS,
  UPDATE_CREDIT_POINTS_WEEK_LIMIT,
  CHECK_TEAM_ACCESS,
  GET_ALL_WORKSPACE_BY_TEAM,
  ADD_NEW_TEAM_MEMBER_FOR_MEMEBR,
} from "../../utils/apiPath";
import { RiAddLine, RiDeleteBack2Fill } from "react-icons/ri";
import { MdEdit, MdDelete, MdOutlineDeleteSweep } from "react-icons/md";
import Modal from "@mui/material/Modal";
import {
  Box,
  Typography,
  TextField,
  Button,
  useTheme,
  Tooltip,
  IconButton,
  Autocomplete,
  Breadcrumbs,
  Link,
  Checkbox,
  FormControlLabel,
  Alert,
} from "@mui/material";
import { IoMdCloseCircle } from "react-icons/io";
import { LiaExpeditedssl } from "react-icons/lia";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditNoteIcon from "@mui/icons-material/";
import { LiaEdit } from "react-icons/lia";
import MenuItem from "@mui/material/MenuItem";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import { NavigateNext } from "@mui/icons-material";
import Snackbar from "@mui/material/Snackbar";
import { ImCoinDollar } from "react-icons/im";
import "./MyTeamMembers.css";
import StatusModalComponent from "../../components/StatusModalComponent/StatusModalComponent";

const MyTeamMembers = ({ toggleTheme }) => {
  const theme = useTheme();
  const { teamId } = useParams();
  const [team_members_data, set_team_members_data] = useState([]);
  const [errorFetchMassage, setErrorFetchMassage] = useState(null);
  const [selected_team_members, set_selected_team_members] = useState([]);
  const [selectedTeam_membersForDelete, setselectedTeam_membersForDelete] =
    useState([]); // State to store selected team_members for deletion
  const [showDeleteTeam_membersModal, setshowDeleteTeam_membersModal] =
    useState(false);
  const [
    selectedTeam_membersForBulkDelete,
    setselectedTeam_membersForBulkDelete,
  ] = useState([]);
  const [teamMemberDetails, setTeamMemberDetails] = useState({
    member_designation: "",
  });
  const [searchTermWorkspace, setSearchTermWorkspace] = useState("");
  const fetchTeamMemberDetails = async () => {
    try {
      const response = await fetch(
        `${getFullApiUrl(
          GET_TEAM_MEMBER_DESIGNATION
        )}?team_id=${teamId}&team_member_id=${localStorage.getItem("user_id")}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const teamData = await response.json();
      console.log("API Response:", teamData); // Log API response
      setTeamMemberDetails(teamData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  
  const isOwnerOrManager =
    teamMemberDetails === "Owner" || teamMemberDetails === "Manager";
  const isOwnerOrManagerOrLead =
    teamMemberDetails === "Owner" ||
    teamMemberDetails === "Manager" ||
    teamMemberDetails === "Lead";
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };
  const navigate = useNavigate();

  const fetchDataAndCheckAccess = async () => {
    if (!teamId) {
      navigate("/");
      return;
    }

    try {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          user_id: localStorage.getItem("user_id"),
          team_id: teamId,
        }),
      };

      const response = await fetch(
        getFullApiUrl(CHECK_TEAM_ACCESS),
        requestOptions
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      // setHasAccess(data === true);
      if (data !== true) {
        alert("You don't have access to this page.");
        navigate("/");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      navigate("/");
    }
  };

  // State to store the selected team_members for deletion

  const [selectedTeam_members, setselectedTeam_members] = useState({
    team_id: teamId,
    team_member_id: "",
    member_designation: "",
    credit_points_week_limit: "",
    used_credit_points_this_week: "",
  });
  const [
    selected_team_members_to_add_credit_limit,
    set_selected_team_members_to_add_credit_limit,
  ] = useState({
    team_id: teamId,
    user_id: "",
    credits: "",
  });
  const [new_team_member_data, set_new_team_member_data] = useState({
    team_id: teamId,
    team_member_id: "",
    member_designation: "",
    credit_points_week_limit: "",
    used_credit_points_this_week: "",
  });
  const [team_workspace_list, set_team_workspace_list] = useState();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setselectedTeam_members({ ...selectedTeam_members, [name]: value });
  };
  const handleNewInputChange = (e) => {
    const { name, value } = e.target;
    console.log(value);
    set_new_team_member_data({ ...new_team_member_data, [name]: value });
  };
  const handleInputChangeToAddCredits = (e) => {
    const { name, value } = e.target;
    console.log(value);
    set_selected_team_members_to_add_credit_limit({
      ...selected_team_members_to_add_credit_limit,
      [name]: value,
    });
  };

  const [modalState, setModalState] = useState({
    isOpen: false,
    type: "",
    message: "",
  });


  //   add team_members...

  const handleAddTeam_membersSubmit = async () => {
    try {
      setShowAddteam_membersForm(false);
      setModalState({
        isOpen: true,
        type: "loading",
        message: "Adding new team memebr...",
      });
      const response = await fetch(getFullApiUrl(ADD_NEW_TEAM_MEMBER), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(new_team_member_data),
      });

      if (!response.ok) {
        throw new Error("Failed to add new team_members");
      }
      setModalState({
        isOpen: true,
        type: "success",
        message: "New Team member added successfully!",
      });

      setTimeout(() => {
        setModalState({ isOpen: false, type: "", message: "" });
      }, 2000);
      handleAddClose();

      set_new_team_member_data({
        team_id: teamId,
        team_member_id: "",
        member_designation: "",
        credit_points_week_limit: "",
        used_credit_points_this: "",
      });
      fetchData();

      console.log("New Team_members added successfully");
    } catch (error) {
      console.error("Error adding new Team_members:", error.message);
      setModalState({ isOpen: false, type: "", message: "" });
    }
  };
  
  const handleAddTeamMembersWithWorkspacesSubmit = async () => {
    try {
      setShowAddteam_membersForm(false);
      setModalState({
        isOpen: true,
        type: "loading",
        message: "Adding new team memebr...",
      });
      const response = await fetch(
        getFullApiUrl(ADD_NEW_TEAM_MEMBER_FOR_MEMEBR),
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ...new_team_member_data,
            workspace_list: selectedWorkspaces, // Include selected workspaces
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to add new team member with workspaces");
      }
      setModalState({
        isOpen: true,
        type: "success",
        message: "New Team member added successfully!",
      });

      setTimeout(() => {
        setModalState({ isOpen: false, type: "", message: "" });
      }, 2000);
      handleAddClose();
      set_new_team_member_data({
        team_id: teamId,
        team_member_id: "",
        member_designation: "",
        credit_points_week_limit: "",
        used_credit_points_this: "",
      });
      fetchData();

      console.log("New team member with workspaces added successfully");
    } catch (error) {
      console.error(
        "Error adding new team member with workspaces:",
        error.message
      );
      setModalState({ isOpen: false, type: "", message: "" });
    }
  };

  const [showWorkspaceSelection, setShowWorkspaceSelection] = useState(false);
  const [selectedWorkspaces, setSelectedWorkspaces] = useState([]);

  const handleDesignationChange = (event) => {
    const { value } = event.target;
    handleNewInputChange(event);
    setShowWorkspaceSelection(value === "Member");
  };

  const handleWorkspaceChange = (event) => {
    const { value, checked } = event.target;
    if (value === "all") {
      setSelectedWorkspaces(
        checked ? team_workspace_list.map((ws) => ws.workspace_id) : []
      );
    } else {
      const workspaceId = event.target.value;
      const isChecked = event.target.checked;

      if (isChecked) {
        setSelectedWorkspaces([...selectedWorkspaces, workspaceId]);
      } else {
        setSelectedWorkspaces(
          selectedWorkspaces.filter((id) => id !== workspaceId)
        );
      }
    }
  };

  


  const fetch_my_team_members_list = async (teams_id) => {
    try {
      const response = await fetch(
        `${getFullApiUrl(GET_ALL_TEAM_MEMBERS_OF_TEAMS)}?team_id=${teams_id}`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const workspaceListData = await response.json();
      // const updatedWorkspaceListData = await workspaceListData.map(
      //   (workspaceRow) => {
      //     const userWorkspace = users.find(
      //       (u) => u.user_id === workspaceRow.team_member_id
      //     );
      //     if (userWorkspace) {
      //       workspaceRow.user_name = userWorkspace.user_name;
      //     }

      //     return workspaceRow;
      //   }
      // );

      set_team_members_data(workspaceListData);
      setErrorFetchMassage(null);
    } catch (error) {
      setErrorFetchMassage(
        "There was a server error. Please contact your admin."
      );
      console.error("Error fetching data:", error);
    }
  };
  const [workspaces_for_team_member, set_workspaces_for_team_member] = useState(
    []
  );

  const getUserIdFromUserName = async (userName) => {
    try {
      // Fetch the user ID based on the user name
      const response = await fetch(
        `${getFullApiUrl(GET_USER_ID_BY_NAME)}?user_name=${userName}`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch user ID");
      }

      const data = await response.json();
      return data.user_id; // Assuming the response contains the team_member_id
    } catch (error) {
      console.error("Error fetching user ID:", error);
      throw error; // Propagate the error for handling
    }
  };

  const fetchTeamworkspaceList = async (team_id) => {
    try {
      const response = await fetch(
        `${getFullApiUrl(GET_ALL_WORKSPACE_BY_TEAM)}?team_id=${team_id}`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const teamWorkspace = await response.json();

      set_team_workspace_list(teamWorkspace);
      return teamWorkspace;
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchUserList = async () => {
    try {
      const response = await fetch(getFullApiUrl(GET_ALL_USERS));
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const teamUserdata = await response.json();
      // set_user_data(teamUserdata);
      return teamUserdata;
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchUserListToSearch = async () => {
    try {
      const response = await fetch(
        `${getFullApiUrl(GET_ALL_TEAM_MEMBERS_NOT_IN_TEAM)}?team_id=${teamId}`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const teamUserdata = await response.json();
      set_user_data(teamUserdata);
      return teamUserdata;
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleIndividualDeleteClick = (
    team_members,
    isOwnerOrManagerOrLead
  ) => {
    // const memberDesignation = team_members.member_designation;

    if (
      team_members.member_designation === "Owner" ||
      team_members.member_designation === "Manager" ||
      team_members.member_designation === "Lead"
    ) {
      // Display a snackbar message informing the user that deletion is not allowed
      setNotificationMessage(
        "Deletion not allowed for Owners or Managers or Leads"
      );
      setOpenSnackbar(true);
    } else if (!isOwnerOrManagerOrLead) {
      // Display a snackbar message informing the user that they don't have access to delete
      setNotificationMessage("You don't have access to delete.");
      setOpenSnackbar(true);
    } else {
      // Proceed with deletion logic
      setselectedTeam_membersForDelete([team_members]); // Select only the clicked team_members
      setshowDeleteTeam_membersModal(true);
    }
  };

  const handleBulkTeam_membersDeleteClick = async (userNames) => {
    try {
      // Check if the user has permission to delete multiple team members
      if (!isOwnerOrManagerOrLead) {
        setNotificationMessage("You don't have access to delete multiple.");
        setOpenSnackbar(true);
        return;
      }

      // Check if userNames is valid and not empty
      if (!userNames || userNames.length === 0 || !Array.isArray(userNames)) {
        console.error("Invalid or empty userNames:", userNames);
        return;
      }

      // Fetch all team members of the team
      const response = await fetch(
        `${getFullApiUrl(GET_ALL_TEAM_MEMBERS_OF_TEAMS)}?team_id=${teamId}`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch team members");
      }

      const allTeamMembers = await response.json();

      // Fetch team_member_id for each userName
      const teamMemberIds = await Promise.all(
        userNames.map(async (userName) => {
          try {
            const user_id = await getUserIdFromUserName(userName);
            console.log(`Got user_id ${user_id} for userName ${userName}`);
            return user_id;
          } catch (error) {
            console.error(
              `Error getting user_id for userName ${userName}:`,
              error
            );
            throw error;
          }
        })
      );

      // Extract team_members details including name and email
      const Team_membersDetails = teamMemberIds.map((team_member_id) => {
        const team_members = allTeamMembers.find(
          (u) => u.team_member_id === team_member_id
        );

        // Check if any team member has a designation of "Owner", "Manager", or "Lead"
        if (
          !team_members ||
          ["Owner", "Manager", "Lead"].includes(team_members.member_designation)
        ) {
          console.error("Unauthorized to delete Lead, Manager, or Owner.");
          setNotificationMessage(
            "You don't have access to delete Lead, Manager, or Owner."
          );
          setOpenSnackbar(true);
          throw new Error("Unauthorized to delete Lead, Manager, or Owner."); // Throw an error to stop further processing
        }

        console.log("ss", team_members);
        return userNames;
      });
      console.log("detail", Team_membersDetails);

      // Set the selected team members for bulk delete and show the bulk delete modal
      setselectedTeam_membersForBulkDelete(teamMemberIds);
      setselectedTeam_membersForDelete(Team_membersDetails);
      setShowBulkDeleteModal(true);
    } catch (error) {
      console.error("Error handling bulk delete click:", error);
      setNotificationMessage(
        "An error occurred while processing your request: " + error.message
      ); // Provide more detailed error message
      setOpenSnackbar(true);
    }
  };

  const handleBulkDeleteConfirm = async () => {
    // console.log(selectedTeam_membersForBulkDelete)
    try {
      if (
        !selectedTeam_membersForBulkDelete ||
        !Array.isArray(selectedTeam_membersForBulkDelete)
      ) {
        console.error(
          "Invalid or empty params:",
          selectedTeam_membersForBulkDelete
        );
        return;
      } else {
        await handleBulkDelete(selectedTeam_membersForBulkDelete);
        // Close modal and refresh team_members list
        setShowBulkDeleteModal(false);
        fetchData();
      }
    } catch (error) {
      console.error("Error deleting team_members in bulk:", error.message);
    }
  };

  const handleDeleteteam_membersConfirm = async () => {
    try {
      await Promise.all(
        selectedTeam_membersForDelete.map((team_members) =>
          handleDeleteteam_members(team_members.team_member_id)
        )
      );
      setselectedTeam_membersForDelete([]);
      setshowDeleteTeam_membersModal(false);
      fetchData(); // Refresh team_members list after deletion
    } catch (error) {
      console.error("Error deleting team_members:", error.message);
    }
  };

  const handleBulkDelete = async (selected_team_members) => {
    set_selected_team_members(selected_team_members);
    try {
      // Iterate over selected team_members and delete each one
      for (let team_membersId of selected_team_members) {
        await handleDeleteteam_members(team_membersId);
      }
      console.log("Bulk delete successful");
      // After bulk deletion, refresh the team_members list
      fetchData();
    } catch (error) {
      console.error("Error deleting team_members in bulk:", error.message);
    }
  };

  const handleDeleteteam_members = async (team_membersId) => {
    try {
      const response = await fetch(
        `${getFullApiUrl(
          DELETE_TEAM_MEMBER
        )}?team_id=${teamId}&team_member_id=${team_membersId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        const data = await response.json();
        alert(data.error);

        throw new Error("Failed to delete team_members");
      }

      console.log("team_members deleted successfully:", team_membersId);
      // Refresh team_members list after deletion
      fetchData();
    } catch (error) {
      console.error("Error deleting team_members:", error.message);
    }
  };
  // Edit..

  const handleEditteam_members = (team_members) => {
    if (!isOwnerOrManagerOrLead) {
      setNotificationMessage("You don't have access to edit.");
      setOpenSnackbar(true);

      return;
    }
    setselectedTeam_members({
      ...selectedTeam_members,
      team_member_id: team_members.team_member_id,
      credit_points_week_limit: team_members.credit_points_week_limit,
      member_designation: team_members.member_designation,
    });

    setShowEditteam_membersModal(true);
  };

  const handleEditteam_membersData = async () => {
    try {
      const response = await fetch(getFullApiUrl(UPDATE_TEAM_MEMBER), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(selectedTeam_members),
      });

      if (!response.ok) {
        throw new Error("Failed to edit team_members");
      }

      console.log("team_members edited successfully");
      setShowEditteam_membersModal(false);
      // Refresh team_members list after editing
      fetchData();
    } catch (error) {
      console.error("Error editing team_members:", error.message);
    }
  };
  const handleAddCreditLimitToTeamMember = (team_members) => {
    if (!isOwnerOrManagerOrLead) {
      setNotificationMessage("You don't have access to edit.");
      setOpenSnackbar(true);
      return;
    }
    set_selected_team_members_to_add_credit_limit({
      ...selected_team_members_to_add_credit_limit,
      user_id: team_members.team_member_id,
    });

    setShowAddCoinsModal(true);
  };

  const handleAddCreditLimitToTeamMembersData = async () => {
    try {
      const response = await fetch(
        getFullApiUrl(UPDATE_CREDIT_POINTS_WEEK_LIMIT),
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(selected_team_members_to_add_credit_limit),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to edit team_members");
      }

      console.log("team_members edited successfully");
      setShowAddCoinsModal(false);
      set_selected_team_members_to_add_credit_limit({
        team_id: teamId,
        user_id: "",
        credits: "",
      });
      // Refresh team_members list after editing

      fetchData();
    } catch (error) {
      console.error("Error editing team_members:", error.message);
    }
  };

  //model open

  const [showAddteam_membersForm, setShowAddteam_membersForm] =
    React.useState(false);
  const [showEditteam_membersModal, setShowEditteam_membersModal] =
    React.useState(false);
  const [showAddCoinsModal, setShowAddCoinsModal] = React.useState(false);
  const [showBulkDeleteModal, setShowBulkDeleteModal] = React.useState(false);
  const handleAddOpen = () => {
    if (!isOwnerOrManagerOrLead) {
      setNotificationMessage("You don't have access to add.");
      setOpenSnackbar(true);
      return;
    }
    fetchUserListToSearch()
    setShowAddteam_membersForm(true);
  };
  const handleAddClose = () => {
    setSelectedUser(null);
    set_new_team_member_data({
      team_id: teamId,
      team_member_id: "",
      member_designation: "",
      credit_points_week_limit: "",
      used_credit_points_this: "",
    });
    setShowAddteam_membersForm(false);
  };
  const handleEditOpen = () => setShowEditteam_membersModal(true);
  const handleEditClose = () => {
    setShowEditteam_membersModal(false);
  };
  const handleBulkDeleteOpen = () => setShowBulkDeleteModal(true);
  const handleBulkDeleteClose = () => setShowBulkDeleteModal(false);
  const handleAddCoinModalClose = () => {
    setShowAddCoinsModal(false);
    set_selected_team_members_to_add_credit_limit(0);
  };

  const handleDeleteteam_membersModalClose = () => {
    setshowDeleteTeam_membersModal(false);
  };

  const [user_data, set_user_data] = useState({
    user_id: "",
    user_name: "",
    email_id: "",
    first_name: "",
    last_name: "",
  });
  const [selectedUser, setSelectedUser] = useState(null);
  const [searchTerm, setSearchTerm] = useState(""); // State to store the search term
  const [searchResults, setSearchResults] = useState([]);

  const handleNewUserSelect = (user) => {
    // Copy user_id from selectedUser to newworkspaceData
    set_new_team_member_data({
      ...new_team_member_data,
      team_member_id: user.team_member_id,
    });
    // Set the selected user
    setSelectedUser(user);
  };

  const handleSearchInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  

  const handleUserSelect = (user) => {
    if (user) {
      set_new_team_member_data({
        ...new_team_member_data,
        team_member_id: user.user_id,
      });
      // Set the selected user
      setSelectedUser(user);
    }
  };

  // const handleSnackbarOpen = (message) => {
  //   setSnackbarMessage(message);
  //   setSnackbarOpen(true);
  // };
  const [teamData, setTeamData] = useState({
    team_name: "",
  });

  const getTeamDetails = async (teamId) => {
    try {
      const response = await fetch(`${getFullApiUrl(GET_TEAM_DETAILS)}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          team_id: teamId,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to fetch team details");
      }

      const data = await response.json();
      setTeamData(data);
    } catch (error) {
      console.error(error.message);
    }
  };

  const filteredWorkspaces = Array.isArray(team_workspace_list)
    ? team_workspace_list.filter((workspace) =>
        workspace.workspace_name
          .toLowerCase()
          .includes(searchTermWorkspace.toLowerCase())
      )
    : [];



    useEffect(() => {
      if (!searchTerm) {
        setSearchResults(user_data);
        return;
      }
    
      const searchTermLower = searchTerm.toLowerCase();
      const filteredUsers = user_data.filter(
        (user) =>
          user.user_name.toLowerCase().includes(searchTermLower) ||
          user.email_id.toLowerCase().includes(searchTermLower) ||
          (user.first_name &&
            user.first_name.toLowerCase().includes(searchTermLower)) ||
          (user.last_name &&
            user.last_name.toLowerCase().includes(searchTermLower))
      );
      setSearchResults(filteredUsers);
    }, [searchTerm, user_data]);
    
    
    useEffect(() => {
      fetchDataAndCheckAccess();
      fetchData();
    }, []);
    
    const fetchData = async () => {
      await getTeamDetails(teamId);
      // const users = await fetchUserList();
      await fetchTeamMemberDetails();
      await fetchUserListToSearch();
      await fetchTeamworkspaceList(teamId);
      await fetch_my_team_members_list(teamId);
    };

  return (
    <div className="page_Section">
      <div className="leftSection_withMenu">
        <LeftSectionWithMenu />
      </div>
      <div className="rightSection_withMenu">
        <div className="headerSection_withMenu">
          <Header onToggleTheme={toggleTheme} />
        </div>
        <div className="bodySection">
          <Breadcrumbs
            id="Breadcrumbs"
            separator={<NavigateNext />}
            aria-label="breadcrumb"
          >
            <div className="breadcrumbs-link">
              <Link
                component={RouterLink}
                to="/"
                color="inherit"
                underline="hover"
              >
                Home
              </Link>
            </div>
            <div className="breadcrumbs-link">
              <Link
                component={RouterLink}
                to="/my_teams"
                color="inherit"
                underline="hover"
              >
                My Teams
              </Link>
            </div>
            <div className="breadcrumbs-link">
              <Link
                component={RouterLink}
                to={`/my_team/${teamId}`}
                color="inherit"
                underline="hover"
              >
                {teamData.team_name}
              </Link>
            </div>
            <div className="breadcrumbs-link">
                {teamData.team_name} Members
            </div>
          </Breadcrumbs>

          <div className="admin_page_heading">
            All Team Members of {teamData.team_name}
          </div>
          {/* <div className="member_of_workspace_heading">
          <Typography variant="h6">All Team Members of {teamData.team_name}</Typography>
            
          </div> */}
          <div>
            {errorFetchMassage ? (
              <div className="alert_massage_container">
                <Alert severity="error">{errorFetchMassage}</Alert>
              </div>
            ) : (
              <TableWithPagination
              file_name={`${teamData.team_name}_member_data`}
                data={team_members_data}
                key={team_members_data.length}
                fields={[
                  {
                    type: "id",
                    field: "team_memebr_id",
                    headerName: "",
                    minWidth: 1,
                    width: 1,
                    maxWidth: 240,
                  },
                  {
                    type: "field",
                    field: "user_name",
                    search_type: "text_search",
                    headerName: "User Name",
                    minWidth: 235,
                    width: 240,
                    maxWidth: 250,
                  },
                  {
                    type: "field",
                    field: "full_name",
                    search_type: "text_search",
                    headerName: "Full Name",
                    minWidth: 235,
                    width: 240,
                    maxWidth: 250,
                  },
                  {
                    type: "field",
                    field: "member_designation",
                    search_type: "designation_search",
                    headerName: "Designation",
                    minWidth: 235,
                    width: 240,
                    maxWidth: 250,
                  },
                  {
                    type: "field",
                    field: "credit_points_week_limit",
                    search_type: "number_search",
                    headerName: "Credit Points Week Limit",
                    minWidth: 270,
                    width: 300,
                    maxWidth: 290,
                  },
                  // {
                  //   type: "field",
                  //   field: "used_credit_points_this_week",
                  //   headerName: "Used Credit Points This Week",
                  //   minWidth: 280,
                  //   width: 300,
                  //   maxWidth: 310,
                  // },
                  {
                    type: "action",
                    field: "actions",
                    headerName: "Actions",
                    minWidth: 120,
                    width: 120,
                    maxWidth: 200,
                    renderCell: (params) => {
                      return (
                        <div className="action_icons_">
                          <Tooltip title="Edit">
                            <IconButton
                              onClick={() => {
                                handleEditteam_members(params);
                              }}
                            >
                              <LiaEdit className="table_action_icon" />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Add Credits">
                            <IconButton
                              onClick={() => {
                                handleAddCreditLimitToTeamMember(params);
                              }}
                            >
                              <ImCoinDollar className="table_action_icon" />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Delete">
                            <IconButton
                              onClick={() => {
                                handleIndividualDeleteClick(
                                  params,
                                  isOwnerOrManagerOrLead
                                );
                              }}
                            >
                              <DeleteOutlineIcon className="table_action_icon" />
                            </IconButton>
                          </Tooltip>
                        </div>
                      );
                    },
                  },
                ]}
                bulk_actions={[
                  {
                    action: "add",
                    renderCell: (params) => {
                      return (
                        <Tooltip title="Add New Team_members">
                          <div
                            onClick={() => {
                              handleAddOpen();
                            }}
                            className={`bulk_classify_button download_icons 
                          }`}
                          >
                            <RiAddLine />
                          </div>
                        </Tooltip>
                      );
                    },
                  },
                  {
                    action: "delete_multiple",
                    renderCell: (params) => {
                      return (
                        <Tooltip title="Delete Selected Team_members">
                          <div
                            onClick={() => {
                              handleBulkTeam_membersDeleteClick(params);
                            }}
                            className={`bulk_delete_button download_icons `}
                          >
                            <MdOutlineDeleteSweep fontSize={30} />
                          </div>
                        </Tooltip>
                      );
                    },
                  },
                ]}
                tableDisplay={"Skeleton"}
              />
            )}

            <Snackbar
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              open={openSnackbar}
              autoHideDuration={6000}
              onClose={handleSnackbarClose}
              message={notificationMessage}
            />
            {/* Add team_members.... */}

            <Modal
              open={showAddteam_membersForm}
              onClose={handleAddClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box id="model_box">
                <div className="add-user-form">
                  <div className="modal_header">Add New Team Member</div>

                  <Box
                    component="form"
                    sx={{
                      "& > :not(style)": {
                        m: 2,
                        width: "80%",
                        marginLeft: "10%",
                      },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <Autocomplete
                      id="search_user"
                      options={searchResults}
                      getOptionLabel={(option) =>
                        `${option.first_name} ${option.last_name} (${option.user_name}) - ${option.email_id}`
                      }
                      onChange={(event, newValue) => handleUserSelect(newValue)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Search User"
                          variant="standard"
                          onChange={handleSearchInputChange}
                          className="modal_form_input"
                        />
                      )}
                    />
                  </Box>
                  {selectedUser && (
                    <div className="modal_text">
                      <Typography>
                        Selected User: {selectedUser.first_name}
                      </Typography>
                    </div>
                  )}

                  <Box
                    component="form"
                    sx={{
                      "& > :not(style)": {
                        m: 2,
                        width: "80%",
                        marginLeft: "10%",
                      },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      id="member_designation"
                      select
                      label="Select Member Designation"
                      name="member_designation"
                      helperText="Please select Designation for Selected Member"
                      value={new_team_member_data.member_designation}
                      onChange={handleDesignationChange}
                    >
                      <MenuItem value="Owner" disabled>
                        {" "}
                        Owner
                      </MenuItem>
                      <MenuItem value="Lead"> Lead</MenuItem>
                      <MenuItem value="Manager"> Manager</MenuItem>
                      <MenuItem value="Member"> Member</MenuItem>
                    </TextField>
                  </Box>

                  {showWorkspaceSelection && (
                    <Box
                      component="form"
                      sx={{
                        "& > :not(style)": {
                          width: "80%",
                          marginLeft: "10%",
                        },
                      }}
                      noValidate
                      autoComplete="off"
                    >
                      <Typography variant="body2" gutterBottom>
                        Please select the workspaces you want to include:
                      </Typography>
                      <FormControlLabel
                        id="workspace_list_formcontroller"
                        control={
                          <Checkbox
                            checked={
                              selectedWorkspaces.length ===
                              team_workspace_list.length
                            }
                            onChange={handleWorkspaceChange}
                            value="all"
                          />
                        }
                        label="Select All Workspaces"
                      />
                      <TextField
                        label="Search Workspace"
                        variant="standard"
                        value={searchTermWorkspace}
                        onChange={(e) => setSearchTermWorkspace(e.target.value)}
                        className="modal_form_input"
                        required
                      />

                      {/* Workspace List */}
                      <div className="worksopace_list_of_modal_form">
                        {filteredWorkspaces.map((workspace) => (
                          <FormControlLabel
                            key={workspace.workspace_id}
                            control={
                              <Checkbox
                                checked={selectedWorkspaces.includes(
                                  workspace.workspace_id
                                )}
                                onChange={handleWorkspaceChange}
                                value={workspace.workspace_id}
                              />
                            }
                            label={workspace.workspace_name}
                          />
                        ))}
                      </div>
                    </Box>
                  )}

                  <Box
                    component="form"
                    sx={{
                      "& > :not(style)": {
                        m: 2,
                        width: "80%",
                        marginLeft: "10%",
                      },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      type="number"
                      id="credit_points_week_limit"
                      label="Credit Points Week Limit Asign For Selected Member"
                      variant="standard"
                      name="credit_points_week_limit"
                      value={new_team_member_data.credit_points_week_limit}
                      onChange={handleNewInputChange}
                      className="modal_form_input"
                      onInput={(e) => {
                        e.target.value = Math.max(
                          0,
                          parseInt(e.target.value)
                        ).toString();
                      }}
                      min={0}
                    />
                  </Box>

                  <button
                    onClick={
                      showWorkspaceSelection
                        ? handleAddTeamMembersWithWorkspacesSubmit
                        : handleAddTeam_membersSubmit
                    }
                    className="add-user-btn"
                  >
                    Add Team Member
                  </button>
                </div>
              </Box>
            </Modal>

            {/* update.... */}

            <Modal
              open={showEditteam_membersModal}
              onClose={handleEditClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box id="model_box">
                <div className="add-user-form">
                  <div className="modal_header">
                    Update Selected Team Members
                  </div>

                  <Box
                    component="form"
                    sx={{
                      "& > :not(style)": {
                        m: 2,
                        width: "80%",
                        marginLeft: "10%",
                      },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      id="member_designation"
                      select
                      label="Select Member Designation"
                      name="member_designation"
                      helperText="Please select your Member Designation"
                      value={
                        selectedTeam_members
                          ? selectedTeam_members.member_designation
                          : ""
                      } // Make sure the value matches the selected role_name
                      onChange={handleInputChange}
                    >
                      <MenuItem value="Owner" disabled>
                        {" "}
                        Owner
                      </MenuItem>
                      <MenuItem value="Lead"> Lead</MenuItem>
                      <MenuItem value="Manager"> Manager</MenuItem>
                      <MenuItem value="Member"> Member</MenuItem>
                    </TextField>
                  </Box>
                  <Box
                    component="form"
                    sx={{
                      "& > :not(style)": {
                        m: 2,
                        width: "80%",
                        marginLeft: "10%",
                      },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      type="number"
                      id="credit_points_week_limit"
                      label="Credit Points Week Limit"
                      variant="standard"
                      name="credit_points_week_limit"
                      value={
                        selectedTeam_members
                          ? selectedTeam_members.credit_points_week_limit
                          : ""
                      }
                      onChange={handleInputChange}
                      className="modal_form_input"
                      onInput={(e) => {
                        e.target.value = Math.max(
                          0,
                          parseInt(e.target.value)
                        ).toString();
                      }}
                      min={0}
                    />
                  </Box>

                  {/* <Box
                    component="form"
                    sx={{
                      "& > :not(style)": {
                        m: 2,
                        width: "80%",
                        marginLeft: "10%",
                      },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      type="number"
                      id="used_credit_points_this_week"
                      label="Used Credit Points This Week"
                      variant="standard"
                      name="used_credit_points_this_week"
                      value={
                        selectedTeam_members
                          ? selectedTeam_members.used_credit_points_this_week
                          : ""
                      }
                      onChange={handleInputChange}
                      className="modal_form_input"
                    />
                  </Box> */}

                  <button
                    onClick={handleEditteam_membersData}
                    className="add-user-btn"
                  >
                    Update Team Member
                  </button>
                </div>
              </Box>

              {/* delete... */}
            </Modal>

            <Modal
              open={showAddCoinsModal}
              onClose={handleAddCoinModalClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box id="model_box">
                <div className="add-user-form">
                  <div className="modal_header">
                    Add Credit Week Limit To the selected Team Member
                  </div>

                  <Box
                    component="form"
                    sx={{
                      "& > :not(style)": {
                        m: 2,
                        width: "80%",
                        marginLeft: "10%",
                      },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      type="number"
                      id="credits"
                      label="Add Credits for Week Limit"
                      variant="standard"
                      name="credits"
                      value={
                        selected_team_members_to_add_credit_limit
                          ? selected_team_members_to_add_credit_limit.credits
                          : ""
                      }
                      onChange={handleInputChangeToAddCredits}
                      className="modal_form_input"
                      onInput={(e) => {
                        e.target.value = Math.max(
                          0,
                          parseInt(e.target.value)
                        ).toString();
                      }}
                      min={0}
                    />
                  </Box>

                  <button
                    onClick={handleAddCreditLimitToTeamMembersData}
                    className="add-user-btn"
                  >
                    Add Credits
                  </button>
                </div>
              </Box>

              {/* delete... */}
            </Modal>
            {/* delete... */}

            <Modal
              open={showDeleteTeam_membersModal}
              onClose={handleDeleteteam_membersModalClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box id="model_box">
                <div className="delete-user-form">
                  <div className="modal_text">Delete Team_members(s)</div>
                  {selectedTeam_membersForDelete.map((team_members) => (
                    <div key={team_members.team_member_id}>
                      <div className="modal_text">
                        Team_members: {team_members.team_member_id} (
                        {team_members.team_members_description})
                      </div>
                    </div>
                  ))}
                  <div className="modal_text">
                    Are you sure you want to delete selected team_members(s)?
                  </div>
                  <div className="form-group">
                    <Button
                      onClick={handleDeleteteam_membersConfirm}
                      color="error"
                    >
                      <MdDelete className="formIcons" />
                    </Button>
                    <Button onClick={handleDeleteteam_membersModalClose}>
                      <IoMdCloseCircle className="formIcons" />
                    </Button>
                  </div>
                </div>
              </Box>
            </Modal>

            {/* Bulk delete.... */}

            <Modal
              open={showBulkDeleteModal}
              onClose={handleBulkDeleteClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box id="model_box">
                <div className="delete-user-form">
                  <div className="modal_text">Delete Team_members(s)</div>

                  {selectedTeam_membersForDelete.map((team_members) => (
                    <div key={team_members}>
                      <div className="modal_text">
                        Team_members : {team_members}{" "}
                      </div>
                    </div>
                  ))}
                  <div className="modal_text">
                    Are you sure you want to delete selected team_members(s)?
                  </div>
                  <div className="form-group">
                    <Button onClick={handleBulkDeleteConfirm} color="error">
                      <MdDelete className="formIcons" />
                    </Button>
                    <Button onClick={handleBulkDeleteClose}>
                      <IoMdCloseCircle className="formIcons" />
                    </Button>
                  </div>
                </div>
              </Box>
            </Modal>
            <StatusModalComponent
              isOpen={modalState.isOpen}
              type={modalState.type}
              message={modalState.message}
              onClose={() => setModalState({ isOpen: false, type: "", message: "" })}
            />
            
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyTeamMembers;
