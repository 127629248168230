import React, { useState, useEffect } from "react";
import {
    Box,
    Button,
    Checkbox,
    Container,
    Divider,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    Grid,
    InputLabel,
    MenuItem,
    Radio,
    IconButton,
    Tooltip,
    RadioGroup,
    Select,
    Slider,
    Typography,
    Breadcrumbs,
    Link,
    Tabs,
    Tab,
    CircularProgress,
    Modal,
} from "@mui/material";
import { FaArrowLeft } from "react-icons/fa6";
import { FaRegEdit } from "react-icons/fa";
import { FaExternalLinkAlt } from "react-icons/fa";

import {
    CHECK_USER_CAMPAIGN_ACCESS,
    FILTER_USER_UPLOAD_DATA_FOR_SCREENSHOT,
    GET_INDIVIDUAL_CAMPAIGN_DETAIL,
    GET_RECENT_IN_CHARGE_OF_CAMPAIGN,
    GET_TARGET_AND_PROSPECT_SCREENSHOT_DATA,
    FILTER_AND_GET_TARGET_AND_PROSPECT_SCREENSHOT_DATA,
    getFullApiUrl,
    SAVE_TARGET_AND_PROSPECT_SCREENSHOT_DATA,
    GET_FIND_LINK_INSERT_FORM_DATA,
    SAVE_TOP_MATCH_SCREENSHOT_DATA,
} from "../../../utils/apiPath";
import LeftSectionWithMenu from "../../common/Header/LeftSectionWithMenu/LeftSectionWithMenu";
import { NavigateNext } from "@mui/icons-material";
import { useNavigate, useParams, Link as RouterLink } from "react-router-dom";
import Header from "../../common/Header/Header";
import { FaRegCircleQuestion } from "react-icons/fa6";
import FloatingMenuBar from "../../components/FloatingMenuBar/FloatingMenuBar";
import CustomButton from "../../components/CustomButton/CustomButton";
import './FinalizeOppurtunityPage.css';
import TableWithPagination from "../../../utils/TableWithPagination";
import { IoIosAddCircleOutline } from "react-icons/io";
import { MdCampaign } from "react-icons/md";
import { GrNext, GrPrevious } from "react-icons/gr";
import CustomButtonForStatus from "../../components/CustomButtonForStatus/CustomButtonForStatus";
import SettingsIcon from '@mui/icons-material/Settings';
import StatusModalComponent from "../../../components/StatusModalComponent/StatusModalComponent";




const FinalizeOppurtunityPage = ({ toggleTheme }) => {
    const { campaignId } = useParams();

    const [currentDomainIndex, setCurrentDomainIndex] = useState(0);
    const [loading_data, set_loading_data] = useState(false)
    const [opportunitiesCount, setOpportunitiesCount] = useState(0);
    const [yesAutoCount, setYesAutoCount] = useState(0);
    const [yesConfirmedCount, setYesConfirmedCount] = useState(0);
    const [noAutoCount, setNoAutoCount] = useState(0);
    const [noConfirmedCount, setNoConfirmedCount] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const [selectedRows, setSelectedRows] = useState([]);
    const [bulkColumn, setBulkColumn] = useState("");
    const [bulkField, setBulkField] = useState("");
    const [modalState, setModalState] = useState({
        isOpen: false,
        type: "",
        message: "",
    });

    const navigate = useNavigate();
    const goto = (page) => {
        navigate(page); // Navigate to the specified page
    };
    useEffect(() => {
        getCampaignDetails();
        getRecentInChangeOfCampaignList();
        getScreenShotDataWithFilter()
    }, []);
    const [screenshot_data, set_screenshot_data] = useState()
    const [group_data, set_group_data] = useState([])
    const [currentDomain, setCurrentDomain] = useState(null); // Tracks current domain
    const [data_to_review, set_data_to_review] = useState([]); // Data for TableWithPagination
    const [opportunitiesData, setOpportunitiesData] = useState([]);
    const [opportunitiesDatacount, setOpportunitiesDatacount] = useState([]);
    const [data_to_review_filtered , set_data_to_review_filtered ] = useState([]); // For table display
    const [isFilterApplied, setIsFilterApplied] = useState(false);
    const [activeFilter, setActiveFilter] = useState("opportunities");

    const [current_params, set_current_params] = useState()
    // useEffect(() => {
    //     if (currentDomain && group_data[currentDomain]) {
    //         set_data_to_review(group_data[currentDomain]);
    //     }
    //     console.log("currentDomain ", currentDomain)
    //     console.log("data_to_review ", group_data[currentDomain])
    // }, [currentDomain, group_data]);
    const [campaignData, setCampaignData] = useState({
        team_id: "",
        workspace_id: "",
        team_name: "",
        workspace_name: "",
        campaign_name: "",
    });


    const [get_recent_in_charge_data, set_get_recent_in_charge_data] = useState(
        []
    );



    const getCampaignDetails = async () => {
        try {
            const response = await fetch(
                `${getFullApiUrl(GET_INDIVIDUAL_CAMPAIGN_DETAIL)}`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        campaign_id: campaignId,
                    }),
                }
            );

            if (!response.ok) {
                throw new Error("Failed to fetch campaign details");
            }

            const data = await response.json();
            setCampaignData(data);
        } catch (error) {
            console.error(error.message);
        }
    };

    const getRecentInChangeOfCampaignList = async () => {
        try {
            const response = await fetch(
                `${getFullApiUrl(GET_RECENT_IN_CHARGE_OF_CAMPAIGN)}`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        campaign_id: campaignId,
                    }),
                }
            );

            if (!response.ok) {
                throw new Error("Failed to fetch campaign details");
            }

            const data = await response.json();
            set_get_recent_in_charge_data(data);
        } catch (error) {
            console.error(error.message);
        }
    };

    const getFormDataByCampaignId = async () => {
        try {
            const response = await fetch(`${getFullApiUrl(GET_FIND_LINK_INSERT_FORM_DATA)}`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ campaign_id: campaignId }),
            });

            if (!response.ok) throw new Error("Failed to fetch form data");

            const data = await response.json();


            return { domainOpportunities: data.domainOpportunities, alternativeLinks: data.alternativeLinks, target_keyword_matching_logic: data.target_keyword_matching_logic };
        } catch (error) {
            console.error(error.message);

        }
    };


    const getScreenShotDataWithFilter = async () => {
        try {
            set_loading_data(false);
            const response = await fetch(
                `${getFullApiUrl(GET_TARGET_AND_PROSPECT_SCREENSHOT_DATA)}`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        campaign_id: campaignId,
                    }),
                }
            );

            if (!response.ok) {
                throw new Error("Failed to fetch campaign details");
            }

            const data = await response.json();
            console.log("data ", data)
            const { domainOpportunities, alternativeLinks, target_keyword_matching_logic } = await getFormDataByCampaignId()
            set_screenshot_data(data);

            let totalDomainOpportunities = parseInt(parseInt(domainOpportunities) + parseInt(alternativeLinks))
            setOpportunitiesCount(totalDomainOpportunities)
            console.log("domainOpportunities ", (parseInt(domainOpportunities)))

            // Call the filterDataByBlogDomain function
            const filteredData = groupDataByDomainCombination(data, parseInt(domainOpportunities), target_keyword_matching_logic);
            console.log("filteredData ", filteredData);
            console.log("filteredData length", filteredData.length);
            setOpportunitiesCount(filteredData.length)
            setOpportunitiesDatacount(filteredData.length)
            setOpportunitiesData(filteredData);
            set_data_to_review(filteredData)
            set_data_to_review_filtered (filteredData)


            set_loading_data(true);

        } catch (error) {
            console.error(error.message);
        }
    };

    useEffect(() => {
        setOpportunitiesCount(data_to_review.length);

        const yesAuto = data_to_review.filter(
            (item) => item.status === "Yes(Auto)"
        ).length;
        const yesConfirmed = data_to_review.filter(
            (item) => item.status === "Yes(Confirmed)"
        ).length;
        const noAuto = data_to_review.filter(
            (item) => item.status === "No(Auto)"
        ).length;
        const noConfirmed = data_to_review.filter(
            (item) => item.status === "No(Confirmed)"
        ).length;

        setYesAutoCount(yesAuto);
        setYesConfirmedCount(yesConfirmed);
        setNoAutoCount(noAuto);
        setNoConfirmedCount(noConfirmed);

    }, [data_to_review]);

    const groupDataByDomainCombination = (data, totalDomainOpportunities, target_keyword_matching_logic) => {
        const groupedData = {};
    
        console.log("target_keyword_matching_logic ", target_keyword_matching_logic);
    
        // Group data by blog domain
        data.forEach((item) => {
            if (!groupedData[item.blog_domain]) {
                groupedData[item.blog_domain] = [];
            }
            groupedData[item.blog_domain].push(item);
        });
        console.log("groupedData ", groupedData)
    
        let mergedData = [];
    
        // Process each group
        Object.entries(groupedData).forEach(([domain, items]) => {
            // Separate items with status "Yes (Confirmed)" and others
            const confirmedItems = items.filter(item => item.status === "Yes(Confirmed)");
            const nonConfirmedItems = items.filter(item => item.status !== "Yes(Confirmed)");
    
            // Sort non-confirmed items based on the target_keyword_matching_logic
            const sortedItems = nonConfirmedItems.sort((a, b) => {
                if (target_keyword_matching_logic === "preferable") {
                    // Priority on keyword occurrences
                    const keywordCountA = a.keywords.reduce((acc, keyword) => acc + Object.values(keyword)[1], 0);
                    const keywordCountB = b.keywords.reduce((acc, keyword) => acc + Object.values(keyword)[1], 0);
    
                    // Sort only by keyword occurrences (descending)
                    return keywordCountB - keywordCountA;
                } else {
                    // Default sorting: similarity score and keyword occurrences
                    const scoreA = parseInt(a.similarity_score.replace("%", ""), 10);
                    const scoreB = parseInt(b.similarity_score.replace("%", ""), 10);
    
                    const keywordCountA = a.keywords.reduce((acc, keyword) => acc + Object.values(keyword)[1], 0);
                    const keywordCountB = b.keywords.reduce((acc, keyword) => acc + Object.values(keyword)[1], 0);
    
                    // First sort by similarity_score (descending)
                    if (scoreA !== scoreB) return scoreB - scoreA;
    
                    // Then sort by keyword occurrences (descending)
                    return keywordCountB - keywordCountA;
                }
            });
    
            // Combine confirmed items and sorted non-confirmed items
            const combinedItems = [...confirmedItems, ...sortedItems];
            console.log("combinedItems ",combinedItems)
    
            // Slice the top matches based on totalDomainOpportunities
            const topMatches = combinedItems.slice(0, totalDomainOpportunities);
            console.log("topMatches ",topMatches)
            // Merge the top matches into the final array (using push to modify mergedData directly)
            mergedData.push(...topMatches);
        });
    
        // Sort merged data by similarity_score (descending)
        mergedData.sort((a, b) => {
            const scoreA = parseInt(a.similarity_score.replace("%", ""), 10);
            const scoreB = parseInt(b.similarity_score.replace("%", ""), 10);
            return scoreB - scoreA;
        });
    
        // Return the merged data in the desired format
        return mergedData;
    };
    




    const saveTopMatchScreenshotData = async (data) => {
        try {
            setModalState({
                isOpen: true,
                type: "loading",
                message: "Filtering to get finalized screenshot data",
            });
            const response = await fetch(`${getFullApiUrl(SAVE_TOP_MATCH_SCREENSHOT_DATA)}`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    campaign_id: campaignId,
                    screenshot_data: data,
                }),
            });

            if (!response.ok) throw new Error("Failed to save form data");
            localStorage.removeItem('selectedOpportunityData');
            setModalState({
                isOpen: true,
                type: "success",
                message: "Screenshot data filtered successfully",
            });

            setTimeout(() => {
                setModalState({ isOpen: false, type: "", message: "" });
            }, 1000);
        } catch (error) {
            console.error(error.message);
        }
    };




    const [pageTypes, setPageTypes] = React.useState({});

    const [activeTab, setActiveTab] = useState(0);


    const handlePageTypeChange = (event) => {
        setPageTypes({ ...pageTypes, [event.target.name]: event.target.checked });
    };
    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };
    const [similarityScore, setSimilarityScore] = React.useState([50, 90]);
    const [activeIndex, setActiveIndex] = React.useState(1); // Set default filter_active button

    const buttons = [
        { range: [33, 70], label: 'Relevant' },
        { range: [50, 90], label: 'Similar' },
        { range: [70, 100], label: 'Same' },
        { range: [0, 100], label: 'All' }
    ];

    // Handle slider change and update activeIndex based on the slider value range
    const handleSliderChange = (event, newValue) => {
        setSimilarityScore(newValue);
        const newActiveIndex = buttons.findIndex(
            (button) => newValue[0] >= button.range[0] && newValue[1] <= button.range[1]
        );
        if (newActiveIndex !== -1) {
            setActiveIndex(newActiveIndex);
        }
    };

    // Handle button click to update slider value and set the activeIndex
    const handleButtonClick = (index) => {
        setActiveIndex(index);
        setSimilarityScore(buttons[index].range);
    };

    const handleGoBackClick = () => {
        navigate(`/my_campaign/${campaignId}/find_link_insert`); // Adjust the path to match your routing configuration
    };
    const handleGoToQiuckVerifyPage = async (data, params) => {
        set_current_params(params)
        if (!params || params.length === 0 || !Array.isArray(params)) {
            console.error("Invalid or empty params:", params);
            setIsModalOpen(true); // Open the modal if params is empty
            return;
        }

        await saveTopMatchScreenshotData(data)

        const filteredData = data.filter(item => params.includes(item.id));
        navigate(`/my_campaign/${campaignId}/quick_varify_screenshot`, {
            state: {
                finalizeOppurtunityPageData: filteredData
            }
        });
    };
    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleOpenCampaignSection = (screenshot_data) => {
        const prospect_url = screenshot_data.prospect_url;
        // Navigate to the MyTeam page
        return `${prospect_url}`;
    };

    const save_screenshot_data_function = async (updatedCampaignData) => {
        try {
            const response = await fetch(`${getFullApiUrl(SAVE_TARGET_AND_PROSPECT_SCREENSHOT_DATA)}`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    campaign_id: campaignId,
                    screenshot_data: updatedCampaignData,
                }),
            });

            if (!response.ok) throw new Error("Failed to save form data");


        } catch (error) {
            console.error(error.message);
        }
    };

    const formatDateAndTime = () => {
        const now = new Date();

        const day = String(now.getDate()).padStart(2, '0'); // Get day and pad with 0 if needed
        const month = String(now.getMonth() + 1).padStart(2, '0'); // Get month and pad with 0
        const year = now.getFullYear(); // Get full year

        const hours = now.getHours() % 12 || 12; // Convert to 12-hour format
        const minutes = String(now.getMinutes()).padStart(2, '0'); // Get minutes and pad with 0
        const seconds = String(now.getSeconds()).padStart(2, '0'); // Get seconds and pad with 0
        const ampm = now.getHours() >= 12 ? 'PM' : 'AM'; // Determine AM/PM

        return `${day}-${month}-${year} ${hours}:${minutes}:${seconds} ${ampm}`;
    };


    const handleStatusUpdate = async (data, newStatus) => {
        const id = data.id;
        const currentStatus = data.status;

        // If the current status is the same as the new status, do nothing
        if (currentStatus === newStatus) {
            return;
        }

        const now = new Date();
        const formattedDateTime = formatDateAndTime(now);

        // Update the status in the `screenshot_data` array
        const updatedScreenshotData = screenshot_data.map((item) =>
            item.id === id
                ? { ...item, status: newStatus, last_updated: formattedDateTime } // Update the status for the matching item
                : item // Keep other items unchanged
        );

        // Update the `screenshot_data` state
        set_screenshot_data(updatedScreenshotData);

        // Filter the updated data for `data_to_review`
        const updatedFilteredTableData = updatedScreenshotData.filter((item) =>
            data_to_review.some((filteredItem) => filteredItem.id === item.id)
        );

        console.log("data ", data)
        console.log("updatedScreenshotData ", updatedScreenshotData)
        console.log("updatedFilteredTableData ", updatedFilteredTableData)

        // Update the `data_to_review` state
        set_data_to_review(updatedFilteredTableData);
        set_data_to_review_filtered (updatedFilteredTableData);

        // Save the updated `screenshot_data` to the backend
        await save_screenshot_data_function(updatedScreenshotData);
    };

    const isWithinRange = (date, rangeInDays) => {
        const parsedDate = parseDate(date);

        if (!parsedDate) {
            console.error(`Invalid date format: ${date}`);
            return false; // Return false if the date format is invalid
        }

        const currentDate = new Date();

        if (rangeInDays === 1) {
            // For "Today", check if the date matches today's date exactly (ignoring time)
            return isSameDay(parsedDate, currentDate);
        }

        const startDate = new Date();
        startDate.setDate(currentDate.getDate() - rangeInDays); // Subtract days from current date

        return parsedDate >= startDate && parsedDate <= currentDate;
    };

    // Helper function to parse date string (assuming the format is "DD-MM-YYYY hh:mm:ss AM/PM")
    const parseDate = (dateString) => {
        const parts = dateString.match(/^(\d{2})-(\d{2})-(\d{4}) (\d{1,2}):(\d{2}):(\d{2}) (\w{2})$/);

        if (!parts) return null;

        const [_, day, month, year, hours, minutes, seconds, period] = parts;

        let hour = parseInt(hours, 10);
        if (period === 'PM' && hour < 12) hour += 12;
        if (period === 'AM' && hour === 12) hour = 0;

        return new Date(year, month - 1, day, hour, minutes, seconds);
    };


    // Helper function to compare if two dates are the same day (ignores time)
    const isSameDay = (date1, date2) => {
        return date1.getDate() === date2.getDate() &&
            date1.getMonth() === date2.getMonth() &&
            date1.getFullYear() === date2.getFullYear();
    };






    const handleFilterData = (type) => {
        console.log("Current Filter Type: ", type);
        setActiveFilter(type);
        setIsFilterApplied(true);
    
        if (!data_to_review || data_to_review.length === 0) {
            console.log("No data available.");
            set_data_to_review_filtered([]);
            return;
        }
    
        let filteredData = [];
        
        if (type === "Today") {
            filteredData = data_to_review.filter((item) => isWithinRange(item.last_updated, 1));
        } else if (type === "3 Days") {
            filteredData = data_to_review.filter((item) => isWithinRange(item.last_updated, 3));
        } else if (type === "Week") {
            filteredData = data_to_review.filter((item) => isWithinRange(item.last_updated, 7));
        } else if (type === "Month") {
            filteredData = data_to_review.filter((item) => isWithinRange(item.last_updated, 30));
        } else if (type === "opportunities") {
            console.log("Showing all opportunities.");
            filteredData = data_to_review;
        } else if (type === "yes") {
            filteredData = data_to_review.filter((item) =>
                item.status === "Yes(Auto)" || item.status === "Yes(Confirmed)"
            );
        } else if (type === "yesAuto") {
            filteredData = data_to_review.filter((item) => item.status === "Yes(Auto)");
        } else if (type === "yesConfirmed") {
            filteredData = data_to_review.filter((item) => item.status === "Yes(Confirmed)");
        } else if (type === "no") {
            filteredData = data_to_review.filter((item) =>
                item.status === "No(Auto)" || item.status === "No(Confirmed)"
            );
        } else if (type === "noAuto") {
            filteredData = data_to_review.filter((item) => item.status === "No(Auto)");
        } else if (type === "noConfirmed") {
            filteredData = data_to_review.filter((item) => item.status === "No(Confirmed)");
        }
    
        if (filteredData.length === 0) {
            console.log("No matching data found.");
            set_data_to_review_filtered([]);
        } else {
            set_data_to_review_filtered([...filteredData]); // Ensure new reference
        }
    
        console.log("Filtered Data: ", filteredData);
    };
    








    useEffect(() => {

        const currentDomainData = group_data[currentDomainIndex]?.data || [];

        // Update opportunities count for the current domain
        setOpportunitiesCount(currentDomainData.length);

        // Calculate counts for the current domain
        const yesAuto = currentDomainData.filter(
            (item) => item.status === "Yes(Auto)"
        ).length;
        const yesConfirmed = currentDomainData.filter(
            (item) => item.status === "Yes(Confirmed)"
        ).length;
        const noAuto = currentDomainData.filter(
            (item) => item.status === "No(Auto)"
        ).length;
        const noConfirmed = currentDomainData.filter(
            (item) => item.status === "No(Confirmed)"
        ).length;

        setYesAutoCount(yesAuto);
        setYesConfirmedCount(yesConfirmed);
        setNoAutoCount(noAuto);
        setNoConfirmedCount(noConfirmed);
    }, [group_data, currentDomainIndex]);

    const handleBulkEditApply = (params) => () => {
        set_current_params(params)

        if (!bulkColumn || !bulkField || params.length === 0) {
            alert("Please select a column, field, and rows to update.");
            return;
        }

        const now = new Date();
        const formattedDateTime = formatDateAndTime(now);

        // Update the selected rows in the dataset
        const updatedScreenshotData = screenshot_data.map((item) =>
            params.includes(item.id) ? { ...item, [bulkColumn]: bulkField, last_updated: formattedDateTime } : item
        );

        set_screenshot_data(updatedScreenshotData);

        // Sync filtered table data
        const updatedFilteredTableData = updatedScreenshotData.filter((item) =>
            data_to_review.some((filteredItem) => filteredItem.id === item.id)
        );

        set_data_to_review(updatedFilteredTableData);
        set_data_to_review_filtered (updatedFilteredTableData);
        console.log("updatedFilteredTableData ", updatedFilteredTableData)
        console.log("updatedScreenshotData ", updatedScreenshotData)

        save_screenshot_data_function(updatedScreenshotData);
    };



    const handleGoToPreviousDomain = () => {
        const domains = Object.keys(group_data);
        const currentIndex = domains.indexOf(currentDomain);
        if (currentIndex > 0) {
            const previousDomain = domains[currentIndex - 1];
            setCurrentDomain(previousDomain);
            setCurrentDomainIndex(currentIndex - 1);

        }
    };

    const handleGoToNextDomain = () => {
        const domains = Object.keys(group_data);
        const currentIndex = domains.indexOf(currentDomain);
        if (currentIndex < domains.length - 1) {
            const nextDomain = domains[currentIndex + 1];
            setCurrentDomainIndex(currentIndex + 1);
            setCurrentDomain(nextDomain);
        }
    };

    const rangeData = {
        "0-10": "0-10",
        "11-20": "11-20",
        "21-30": "21-30",
        "31-40": "31-40",
        "41-50": "41-50",
        "51-60": "51-60",
        "61-70": "61-70",
        "71-100": "71-100",
      };
      

    return (
        <div className="page_Section">
            <div className="leftSection_withMenu">
                <LeftSectionWithMenu />
            </div>
            <div className="rightSection_withMenu">
                <div className="headerSection_withMenu">
                    <Header onToggleTheme={toggleTheme} />
                </div>
                <div className="bodySection">
                    <Breadcrumbs
                        id="Breadcrumbs"
                        separator={<NavigateNext />}
                        aria-label="breadcrumb"
                    >
                        <div className="breadcrumb_text">
                            <Link
                                component={RouterLink}
                                to="/"
                                color="inherit"
                                underline="hover"
                            >
                                Home
                            </Link>
                        </div>
                        <div className="breadcrumb_text">
                            <Link
                                component={RouterLink}
                                to={`/my_teams`}
                                color="inherit"
                                underline="hover"
                            >
                                My Teams
                            </Link>
                        </div>
                        <div className="breadcrumb_text">
                            <Link
                                component={RouterLink}
                                to={`/my_team/${campaignData.team_id}`}
                                color="inherit"
                                underline="hover"
                            >
                                Details of Team  ( {campaignData.team_name} )
                            </Link>
                        </div>
                        <div className="breadcrumb_text">
                            <Link
                                component={RouterLink}
                                to={`/my_team_workspaces/${campaignData.team_id}`}
                                color="inherit"
                                underline="hover"
                            >
                                Workspaces of Team ( {campaignData.team_name} )
                            </Link>
                        </div>
                        <div className="breadcrumb_text">
                            <Link
                                component={RouterLink}
                                to={`/my_workspace/${campaignData.workspace_id}`}
                                color="inherit"
                                underline="hover"
                            >
                                Details of Workspace ({campaignData.workspace_name})
                            </Link>
                        </div>
                        <div className="breadcrumb_text">
                            <Link
                                component={RouterLink}
                                to={`/my_workspace_campaign/${campaignData.workspace_id}`}
                                color="inherit"
                                underline="hover"
                            >
                                Campaigns of Workspace  ({campaignData.workspace_name} )
                            </Link>
                        </div>
                        <div className="breadcrumb_text"> Details of Campaign ({campaignData.campaign_name})</div>
                        <div className="breadcrumb_text">Finalize Opportunities</div>

                    </Breadcrumbs>
                    <FloatingMenuBar />
                    {
                        loading_data ? (
                            <>
                                <div className="find_link_page_contaainer">
                                    <Tabs
                                        value={activeTab}
                                        onChange={handleTabChange}
                                        aria-label="tabs"
                                    >
                                        <Tab label="Finalize on Existing Data" />
                                        <Tab label="Finalize Uploaded Data" />
                                    </Tabs>

                                    {activeTab === 0 && (
                                        <div className="tab_content_container">
                                            <div className="finalize_opurtunity_container">
                                                <div className="header_and_go_back_button_container">
                                                    {/* <div className="go_back_button" onClick={handleGoBackClick}>
                                                        <FaArrowLeft />
                                                    </div>*/}
                                                    <div className="header_container">
                                                        <div className="header">Finalize Opportunities</div>
                                                        <div className="sub_header">
                                                            The system has automatically evaluated all opportunities. You may optionally confirm , edit or change any entry below .
                                                        </div>
                                                    </div>
                                                    <div className="settings_button_container">
                                                        <Tooltip title="Return to Update Form for Link Opportunities"  >
                                                            <button className="settings_button" onClick={handleGoBackClick}>
                                                                <SettingsIcon className="settings_icon" />
                                                                Settings
                                                            </button>
                                                        </Tooltip>
                                                    </div>
                                                </div>

                                                <div className="dashboard_container">
                                                    <div className="dashboard_data_container">
                                                        <div className={`oppurtunity_section_container `}>
                                                            <div className={`oppurtunity_section_container_with_filter ${activeFilter === "opportunities" ? "filter_active" : ""}`} onClick={() => handleFilterData("opportunities")}>
                                                                <div className="oppurtunity_section_label">Opportunities</div>
                                                                <div className="oppurtunity_section_count" >{opportunitiesCount}</div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className="dashboard_data_container">
                                                        <div className="yes_no_count_container">
                                                            <div className={`yes_no_count_number_container ${activeFilter === "yes" ? "filter_active" : ""}`} onClick={() => handleFilterData("yes")}>
                                                                <div>Yes</div>
                                                                <div className="yes_no_count_number_size yes_count" >
                                                                    {yesAutoCount + yesConfirmedCount}
                                                                </div>
                                                            </div>
                                                            <div className="section_details">
                                                                <div className={`yes_no_count_details_container  ${activeFilter === "yesAuto" ? "filter_active" : ""}`} onClick={() => handleFilterData("yesAuto")}>
                                                                    <div>Yes (Auto)</div>
                                                                    <div className="count_value">{yesAutoCount}</div>
                                                                </div>
                                                                <div className={`yes_no_count_details_container ${activeFilter === "yesConfirmed" ? "filter_active" : ""}`} onClick={() => handleFilterData("yesConfirmed")}>
                                                                    <div>Yes (Confirmed)</div>
                                                                    <div className="count_value">{yesConfirmedCount}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="dashboard_data_container">
                                                        <div className="yes_no_count_container">
                                                            <div className={`yes_no_count_number_container ${activeFilter === "no" ? "filter_active" : ""}`} onClick={() => handleFilterData("no")}>
                                                                <div>No</div>
                                                                <div className={`yes_no_count_number_size no_count `} >
                                                                    {noAutoCount + noConfirmedCount}
                                                                </div>
                                                            </div>
                                                            <div className="section_details">


                                                                <div className={`yes_no_count_details_container ${activeFilter === "noAuto" ? "filter_active" : ""}`} onClick={() => handleFilterData("noAuto")}>
                                                                    <div>No (Auto)</div>
                                                                    <div className="count_value">{noAutoCount}</div>
                                                                </div>
                                                                <div className={`yes_no_count_details_container ${activeFilter === "noConfirmed" ? "filter_active" : ""}`} onClick={() => handleFilterData("noConfirmed")}>
                                                                    <div>No (Confirmed)</div>
                                                                    <div className="count_value">{noConfirmedCount}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="dashboard_data_container">
                                                        <div className="newly_confirm_with_select_section">
                                                            <div className="newly_confirm_section">
                                                                <div className="newly_confirm_section_label">Newly Confirmed</div>
                                                                <div className="newly_confirm_section_count">0</div>
                                                            </div>
                                                            <div className="select_section">
                                                                <Select
                                                                    defaultValue="Today"
                                                                    className="select_box"
                                                                    onChange={(event) => handleFilterData(event.target.value)}
                                                                >
                                                                    <MenuItem value="Today">Today</MenuItem>
                                                                    <MenuItem value="3 Days">3 Days</MenuItem>
                                                                    <MenuItem value="Week">Week</MenuItem>
                                                                    <MenuItem value="Month">Month</MenuItem>
                                                                </Select>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>

                                                <div className="finalize_oppurtunity_table_container">
                                                    <TableWithPagination
                                                        key={data_to_review_filtered.length}
                                                        data={data_to_review_filtered  }
                                                        file_name={"finalize_opportunities"}
                                                        fields={[
                                                            {
                                                                type: "field",
                                                                field: "status",
                                                                headerName: "status",
                                                                search_type: "text_search",
                                                                minWidth: 120,
                                                                width: 140,
                                                                maxWidth: 1500,
                                                            },
                                                            {
                                                                type: "action",
                                                                field: "actions",
                                                                headerName: "Change/Confirm",
                                                                width: 170,
                                                                renderCell: (params) => {
                                                                    // Check the current status to disable buttons accordingly
                                                                    const currentStatus = params.status;

                                                                    return (
                                                                        <div className="action_icons_">
                                                                            <div>&nbsp;&nbsp;&nbsp;&nbsp;</div>
                                                                            <div className="custom_button_container_in_finalize_table">
                                                                                <CustomButtonForStatus
                                                                                    title="Yes"
                                                                                    type="secondary"
                                                                                    status={currentStatus}
                                                                                    onClick={() => handleStatusUpdate(params, "Yes(Confirmed)")}
                                                                                // disabled={isYesDisabled} // Disable if status is "Yes"
                                                                                />
                                                                                <CustomButtonForStatus
                                                                                    title="No"
                                                                                    type="primary"
                                                                                    status={currentStatus}
                                                                                    onClick={() => handleStatusUpdate(params, "No(Confirmed)")}
                                                                                // disabled={isNoDisabled} // Disable if status is "No"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                },
                                                            },

                                                            {
                                                                type: "field",
                                                                field: "client_url",
                                                                headerName: "Target Url",
                                                                search_type: "text_search",
                                                                minWidth: 220,
                                                                width: 280,
                                                                maxWidth: 1500,
                                                            },
                                                            {
                                                                type: "field",
                                                                field: "blog_url",
                                                                headerName: "Prospect Url",
                                                                search_type: "text_search",
                                                                minWidth: 220,
                                                                width: 280,
                                                                maxWidth: 1500,
                                                            },
                                                            {
                                                                type: "field",
                                                                field: "page_type",
                                                                headerName: "Page Type",
                                                                search_type: "text_search",
                                                                minWidth: 150,
                                                                width: 170,
                                                                maxWidth: 1500,
                                                            },
                                                            {
                                                                type: "field",
                                                                field: "similarity_score",
                                                                headerName: "Similarity Score",
                                                                search_type: "number_search",
                                                                minWidth: 150,
                                                                width: 200,
                                                                maxWidth: 1500,
                                                            },
                                                            {
                                                                type: "field",
                                                                field: "alternatives",
                                                                headerName: "alternatives",
                                                                search_type: "number_search",
                                                                minWidth: 150,
                                                                width: 200,
                                                                maxWidth: 1500,
                                                            },

                                                            {
                                                                type: "action",
                                                                field: "campaign_action",
                                                                headerName: "",
                                                                width: 30,
                                                                renderCell: (params) => {
                                                                    return (
                                                                        <div className="action_icons_">
                                                                            {/* <div>&nbsp;</div> */}
                                                                            <Tooltip title="Open Campaign">
                                                                                <IconButton>
                                                                                    <RouterLink
                                                                                    // to={handleOpenCampaignSection(params)}
                                                                                    >
                                                                                        <FaExternalLinkAlt className="table_action_icon" />
                                                                                    </RouterLink>
                                                                                </IconButton>
                                                                            </Tooltip>


                                                                        </div>
                                                                    );
                                                                },
                                                            },

                                                            {
                                                                type: "id",
                                                                field: "id",
                                                                headerName: "",
                                                                minWidth: 1,
                                                                width: 1,
                                                                maxWidth: 380,
                                                            },
                                                        ]}
                                                        bulk_actions={[
                                                            {
                                                                action: "edit",
                                                                renderCell: (params) => {
                                                                    return (
                                                                        <div className="finalize_page_bulk_action_container">
                                                                            <div className="bulk-edit-container">
                                                                                <div>
                                                                                    <div className="form_heading">Column</div>
                                                                                    <div className="custom-select-container">
                                                                                        <select
                                                                                            className="custom-select"
                                                                                            value={bulkColumn}
                                                                                            onChange={(e) => setBulkColumn(e.target.value)}
                                                                                        >
                                                                                            <option value="">Select Column</option>
                                                                                            <option value="status">Status</option>
                                                                                        </select>
                                                                                    </div>
                                                                                </div>

                                                                                <div>
                                                                                    <div className="form_heading">Field</div>
                                                                                    <div className="custom-select-container">
                                                                                        <select
                                                                                            className="custom-select"
                                                                                            value={bulkField}
                                                                                            onChange={(e) => setBulkField(e.target.value)}
                                                                                            disabled={bulkColumn !== "status"} // Disable if "Status" is not selected
                                                                                        >
                                                                                            <option value="">Select Field</option>
                                                                                            {bulkColumn === "status" && (
                                                                                                <>
                                                                                                    <option value="Yes(Confirmed)">Yes(Confirmed)</option>
                                                                                                    <option value="No(Confirmed)">No(Confirmed)</option>
                                                                                                </>
                                                                                            )}
                                                                                        </select>
                                                                                    </div>
                                                                                </div>

                                                                                <button className="bulk-edit-apply" onClick={handleBulkEditApply(params)}>
                                                                                    APPLY
                                                                                </button>
                                                                            </div>
                                                                            <div style={{
                                                                                display: "flex",
                                                                                alignItems: "center"
                                                                            }}>

                                                                                <button
                                                                                    className="bulk-edit-apply"
                                                                                    onClick={() => handleGoToQiuckVerifyPage( data_to_review_filtered  , params)}
                                                                                >
                                                                                    Quick Verify <FaExternalLinkAlt />
                                                                                </button>


                                                                                {/* <CustomButton title={`  `} type="secondary" />  */}
                                                                            </div>


                                                                        </div>

                                                                    );
                                                                },
                                                            },

                                                        ]}
                                                        tableDisplay={"Table"}
                                                        rangeData={rangeData} 
                                                        params = {current_params}
                                                    />
                                                </div>
                                            </div>

                                        </div>

                                    )}

                                    {activeTab === 1 && (
                                        <div className="tab_content_container">

                                            <Typography variant="h5" mb={2}>
                                                Finalize Uploaded Data
                                            </Typography>
                                            {/* Content for the second tab will be added here later */}
                                        </div>
                                    )}
                                </div>
                            </>
                        ) :

                            (
                                <>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            height: '100vh',  // Full screen height
                                        }}
                                    >
                                        <CircularProgress />
                                    </Box>
                                </>
                            )
                    }
                    <Modal
                        open={isModalOpen}
                        onClose={handleCloseModal}
                        aria-labelledby="modal-title"
                        aria-describedby="modal-description"
                    >
                        <div className="modal-container">
                            <div className="modal-content">
                                <div id="modal-title" className="modal-title">
                                 Selection Required ⚠️
                                </div>
                                <div id="modal-description" className="modal-description">
                                    Please select the required data before proceeding to the Quick
                                    Verify page.
                                </div>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleCloseModal}
                                    className="modal-button"
                                >
                                    Understood
                                </Button>
                            </div>
                        </div>
                    </Modal>

                    <StatusModalComponent
                        isOpen={modalState.isOpen}
                        type={modalState.type}
                        message={modalState.message}
                        onClose={() => setModalState({ isOpen: false, type: "", message: "" })}
                    />
                </div>
            </div>
        </div>
    );
};

export default FinalizeOppurtunityPage;
