import AdminSideBar from "../common/AdminSideBar/AdminSideBar";

import React, { useState, useEffect } from "react";
import TableWithPagination from "../../../utils/TableWithPagination/TableWithPagination";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import {
  getFullApiUrl,
  ADD_NEW_WORKSPACE,
  GET_ALL_WORKSPACE,
  DELETE_WORKSPACE,
  UPDATE_WORKSPACE,
  UPDATE_USER_OF_WORKSPACE,
  GET_ALL_USERS,
  GET_WORKSPACE_MEMBER_BY_USER,
  ADD_NEW_WORKSPACE_MEMBER_BY_USER,
  GET_ALL_TEAM,
} from "../../../utils/apiPath"
import { RiAddLine, RiDeleteBack2Fill } from "react-icons/ri";
import { MdEdit, MdDelete, MdOutlineDeleteSweep } from "react-icons/md";
import Modal from "@mui/material/Modal";
import MenuItem from "@mui/material/MenuItem";


import {
  Box,
  Typography,
  TextField,
  Button,
  useTheme,
  Tooltip,
  IconButton,
  Autocomplete,
  Breadcrumbs,
  Link as MuiLink
} from "@mui/material";
import { IoMdCloseCircle, IoIosAddCircleOutline } from "react-icons/io";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditNoteIcon from "@mui/icons-material/";
import { LiaEdit } from "react-icons/lia";
import { useNavigate, useParams,Link } from "react-router-dom";
import { Admin, NavigateNext } from "@mui/icons-material";

import { IoMdOpen } from "react-icons/io";
import { MdWorkOutline } from "react-icons/md";
import My_Workspace from "../My_Workspace/My_Workspace";
import Header from "../../common/Header/Header";

const Workspace = ({ toggleTheme }) => {
  let { workspaceId } = useParams();

  const theme = useTheme();
  const user_id = localStorage.getItem("user_id");
  const [workspace_data, set_workspace_data] = useState([]);
  const [selectedworkspacesForDelete, setselectedworkspacesForDelete] =
    useState([]);
  const [selected_workspace, set_selected_workspace] = useState([]); // State to store selected workspaces for deletion
  const [showDeleteworkspaceModal, setshowDeleteworkspaceModal] =
    useState(false);
  const [selectedworkspacesForBulkDelete, setselectedworkspacesForBulkDelete] =
    useState([]);

  // State to store the selected workspace for deletion
  const [user_data, set_user_data] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [searchTerm, setSearchTerm] = useState(""); // State to store the search term
  const [searchResults, setSearchResults] = useState([]);
  const [selectedworkspace, setselectedworkspace] = useState({
    workspace_id: "",
    workspace_name: "",
    workspace_description:"",
    start_date: "",
    deadline: "",
    status: "",
    user_id: "",
    team_id: "",
  });
  const [newworkspaceData, setnewworkspaceData] = useState({
    workspace_name: "",
    workspace_description:"",
    start_date: "",
    deadline: "",
    status: "",
    user_id: localStorage.getItem("user_id"),
    team_id: "",
  });

  const [new_user_for_workspace, set_new_user_for_workspace] = useState({
    workspace_id: "",
    user_id: "",
  });
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setselectedworkspace({ ...selectedworkspace, [name]: value });
  };
  const handleNewInputChange = (e) => {
    const { name, value } = e.target;
    setnewworkspaceData({ ...newworkspaceData, [name]: value });
  };
  const handleNewInputChangeForUser = (e) => {
    const { name, value } = e.target;
    set_new_user_for_workspace({ ...new_user_for_workspace, [name]: value });
  };
  // const fetchData = async () => {
  //   const teams = await fetchTeamList();
  //   await fetchworkspaceList( teams);
  // };
  // useEffect(() => {
  //   fetchData();
  // }, [1000]);
  const fetchData = async () => {
    const teams = await fetchTeamList();
    // const users = await fetchUserList();
    await fetchworkspaceList( teams);
  };

  useEffect(() => {
    fetchData();
  }, [1000]);

  const handleAddworkspaceSubmit = async () => {
    try {
      const response = await fetch(
        getFullApiUrl(ADD_NEW_WORKSPACE)
          
        ,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(newworkspaceData),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to add new team");
      }

      // Clear the form data after successful submission
      setnewworkspaceData({
        // Ensure it's set before using it
        workspace_name: "",
     
        start_date: "",
        deadline: "",
        status: "",
        user_id: "",
        team_id: "",
      });
      setShowAddworkspaceForm(false);

      fetchData(); // Assuming fetchData is defined somewhere

      console.log("New Team added successfully");
    } catch (error) {
      console.error("Error adding new Team:", error.message);
    }
  };
  const fetchTeamList = async () => {
    try {
      const response = await fetch(getFullApiUrl(GET_ALL_TEAM));
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const teamData = await response.json();

      set_team_data(teamData);
      return teamData;
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // const fetchworkspaceList = async ( teams) => {
  //   try {
  //     const response = await fetch(
  //       getFullApiUrl(GET_ALL_WORKSPACE)
  //     );
  //     if (!response.ok) {
  //       throw new Error("Network response was not ok");
  //     }
  //     const myWorkspaceListData = await response.json();
  //     const updatedWorkspaceListData = await myWorkspaceListData.map(
  //       (workspaceRow) => {
  //         const teamWorkspace = teams.find(
  //           (t) => t.team_id === workspaceRow.team_id
  //         );
  //         if (teamWorkspace) {
  //           workspaceRow.team_name = teamWorkspace.team_name;
  //         }
  //         return workspaceRow;
  //       }
  //     );
  //     set_workspace_data(myWorkspaceListData);
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  // };



  const fetchworkspaceList = async ( teams) => {
    try {
      const response = await fetch(getFullApiUrl(GET_ALL_WORKSPACE));
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const workspaceListData = await response.json();
      const updatedWorkspaceListData = await workspaceListData.map(
        (workspaceRow) => {
          const teamWorkspace = teams.find(
            (t) => t.team_id === workspaceRow.team_id
          );
          if (teamWorkspace) {
            workspaceRow.team_name = teamWorkspace.team_name;
          }
          return workspaceRow;
        }
      );

      /*
			const updatedUserData = await usersListData.map(userRow => {
			const userSubscription = users.find(sub => sub.user_id === userRow.user_id);
			if (userSubscription) {
			userRow.user_name = userSubscription.user_name;
			}
			return userRow;
			});

			*/
      set_workspace_data(workspaceListData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleIndividualDeleteClick = (workspace) => {
    setselectedworkspacesForDelete([workspace]); // Select only the clicked workspace
    setshowDeleteworkspaceModal(true);
  };

  const handleBulkworkspaceDeleteClick = async (params) => {
    if (!params || params.length === 0 || !Array.isArray(params)) {
      console.error("Invalid or empty params:", params);
      return;
    }

    try {
      const response = await fetch(getFullApiUrl(GET_ALL_WORKSPACE));
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const allworkspaces = await response.json();

      const workspaceDetails = params.map((workspaceId) => {
        const workspaces = allworkspaces.find(
          (u) => u.workspace_id === workspaceId
        );
        console.log("ss", workspaces);
        return workspaces
          ? `${workspaces.workspace_name} (${workspaces.description})`
          : "";
      });
      console.log("detail", workspaceDetails);

      setselectedworkspacesForBulkDelete(params);
      setselectedworkspacesForDelete(workspaceDetails);
      setShowBulkDeleteModal(true);
    } catch (error) {
      console.error("Error fetching workspace data:", error);
    }
  };

  const handleBulkDeleteConfirm = async () => {
    // console.log(selectedworkspacesForBulkDelete)
    try {
      if (
        !selectedworkspacesForBulkDelete ||
        !Array.isArray(selectedworkspacesForBulkDelete)
      ) {
        console.error(
          "Invalid or empty params:",
          selectedworkspacesForBulkDelete
        );
        return;
      } else {
        await handleBulkDelete(selectedworkspacesForBulkDelete);
        // Close modal and refresh workspace list
        setShowBulkDeleteModal(false);
        fetchData();
      }
    } catch (error) {
      console.error("Error deleting workspaces in bulk:", error.message);
    }
  };
  const handleDeleteworkspaceConfirm = async () => {
    try {
      await Promise.all(
        selectedworkspacesForDelete.map((workspace) =>
          handleDeleteworkspace(workspace.workspace_id)
        )
      );
      setselectedworkspacesForDelete([]);
      setshowDeleteworkspaceModal(false);
      fetchData(); // Refresh workspace list after deletion
    } catch (error) {
      console.error("Error deleting workspaces:", error.message);
    }
  };
  const handleBulkDelete = async (selected_workspace) => {
    set_selected_workspace(selected_workspace);
    try {
      // Iterate over selected workspaces and delete each one
      for (let workspaceId of selected_workspace) {
        await handleDeleteworkspace(workspaceId);
      }
      console.log("Bulk delete successful");
      // After bulk deletion, refresh the workspace list
      fetchData();
    } catch (error) {
      console.error("Error deleting workspaces in bulk:", error.message);
    }
  };

  const handleDeleteworkspace = async (workspaceId) => {
    console.log("delete click");
    console.log(workspaceId);
    try {
      const response = await fetch(getFullApiUrl(DELETE_WORKSPACE), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ workspace_id: workspaceId }),
      });

      if (!response.ok) {
        throw new Error("Failed to delete workspace");
      }

      console.log("workspace deleted successfully:", workspaceId);
      // Refresh workspace list after deletion
      fetchData();
    } catch (error) {
      console.error("Error deleting workspace:", error.message);
    }
  };
  const handleEditworkspace = (workspace) => {
    setselectedworkspace(workspace);
    setShowEditworkspaceModal(true);
  };
  const handleEditUser = (workspace) => {
    set_new_user_for_workspace({
      workspace_id: workspace.workspace_id,
      user_id: workspace.user_id,
    });
    setShowUserSearchModal(true);
  };
  const handleUserSearchCancel = () => {
    setSelectedUser(null);
  };

  const handleEditworkspaceData = async () => {
    try {
      const response = await fetch(getFullApiUrl(UPDATE_WORKSPACE), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(selectedworkspace),
      });

      if (!response.ok) {
        throw new Error("Failed to edit workspace");
      }

      console.log("workspace edited successfully");
      setSelectedUser(null);

      setShowEditworkspaceModal(false);
      // Refresh workspace list after editing
      fetchData();
    } catch (error) {
      console.error("Error editing workspace:", error.message);
    }
  };
  const [searchQuery, setSearchQuery] = useState("");

  const fetchUserList = async () => {
    try {
      const response = await fetch(getFullApiUrl(GET_ALL_USERS));
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      set_user_data(data);
      return data
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSearchInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    if (!searchTerm) {
      setSearchResults([]);
      return;
    }

    const filteredUsers = user_data.filter((user) =>
      user.user_name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setSearchResults(filteredUsers);
  }, [searchTerm, user_data]);

  const handleEditUserForWorkspace = async () => {
    try {
      const response = await fetch(getFullApiUrl(UPDATE_USER_OF_WORKSPACE), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          workspace_id: new_user_for_workspace.workspace_id,
          user_id: new_user_for_workspace.user_id,
        }),
      });
      console.log(new_user_for_workspace);

      if (!response.ok) {
        throw new Error("Failed to update user for the workspace");
      }

      // console.log("User for the workspace updated successfully");
      setShowUserSearchModal(false);
      // Refresh workspace list after updating user for the workspace
      fetchData();
    } catch (error) {
      console.error("Error updating user for the workspace:", error.message);
    }
  };
  //model open

  const [showAddworkspaceForm, setShowAddworkspaceForm] = React.useState(false);
  const [showEditworkspaceModal, setShowEditworkspaceModal] =
    React.useState(false);
  const [showBulkDeleteModal, setShowBulkDeleteModal] = React.useState(false);
  const [showUserSeachModal, setShowUserSearchModal] = React.useState(false);
  const handleAddOpen = () => setShowAddworkspaceForm(true);
  const handleAddClose = () => setShowAddworkspaceForm(false);
  const handleEditOpen = () => setShowEditworkspaceModal(true);
  const handleEditClose = () => setShowEditworkspaceModal(false);
  const handleBulkDeleteOpen = () => setShowBulkDeleteModal(true);
  const handleBulkDeleteClose = () => setShowBulkDeleteModal(false);
  const handleUserSearchClose = () => {
    setShowUserSearchModal(false);
    setSelectedUser(null);
  };

  const setPlaceholderColor = () => {
    const styleTag = document.createElement("style");
    styleTag.innerHTML = `
      input::placeholder,textarea::placeholder {
        color: ${theme.palette.text.primary};
      }
    `;
    document.head.appendChild(styleTag);
  };

  setPlaceholderColor();
  const handleUserSelect = (user) => {
    // Copy user_id from selectedUser to new_user_for_workspace
    set_new_user_for_workspace({
      ...new_user_for_workspace,
      user_id: user.user_id,
    });
    // Set the selected user
    setSelectedUser(user);
  };
  const handleNewUserSelect = (user) => {
    // Copy user_id from selectedUser to newworkspaceData
    setnewworkspaceData({
      ...newworkspaceData,
      user_id: user.user_id,
    });
    // Set the selected user
    setSelectedUser(user);
  };

  const handleDeleteworkspaceModalClose = () => {
    setshowDeleteworkspaceModal(false);
  };
  const [workspaceID, setWorkspaceID] = useState(null);
  const [teamID, setTeamID] = useState(null)
  const navigate = useNavigate();

  const handleOpenTeamSection = (workspace) => {
    const workspace_id = workspace.workspace_id;
    const team_id = workspace.team_id;
    // Set the teamId state
    setWorkspaceID(workspace_id);
    setTeamID(team_id)
    console.log(workspaceID);
    console.log(teamID);


    // Navigate to the MyTeam page
    navigate(`/admin/my_workspace/${workspace_id}`);
  };

  const [selectedTeam, setSelectedTeam] = useState(null);
  const [team_data, set_team_data] = useState([]);
  const [searchTermteam, setSearchTermteam] = useState("");
  const [searchTeamResults, setSearchTeamResults] = useState([]);
  const handleSearchTeamInputChange = (e) => {
    setSearchTermteam(e.target.value);
  };
  const handleTeamSelect = (team) => {
    // Copy user_id from selectedUser to new_user_for_workspace
    setnewworkspaceData({
      ...newworkspaceData,
      team_id: team.team_id,
    });
    // Set the selected user
    setSelectedTeam(team);
  };

  return (
    <div className="page_Section">
    <div className="leftSection_withMenu">
      <AdminSideBar />
    </div>
    <div className="rightSection_withMenu">
    <div className="headerSection_withMenu">
          <Header onToggleTheme={toggleTheme} />
        </div>
        <div className="bodySection">
        <Breadcrumbs id="Breadcrumbs" separator={<NavigateNext />} aria-label="breadcrumb">
            <MuiLink component={Link} to="/admin" color="inherit" underline="hover">
              Admin
            </MuiLink>
            <Typography color="textPrimary"> Workspace</Typography>
          </Breadcrumbs>
          <div className="admin_page_heading">Workspace Management</div>
          <div>{workspaceId}</div>
          <TableWithPagination
            key={workspace_data.length}
            data={workspace_data}
            fields={[
              {   
                type: "id",
                field: "workspace_id",
                headerName: "ID",
                minWidth: 1,
                width: 1,
                maxWidth: 360,
              },
              {
                type: "action",
                field: "actions",
                headerName: "Actions",
                width: 135,
                renderCell: (params) => {
                  return (
                    <div className="action_icons_">
                      <Tooltip title="Edit">
                        <IconButton onClick={() => handleEditworkspace(params)}>
                          <LiaEdit className="table_action_icon" />
                        </IconButton>
                      </Tooltip>

                      <Tooltip title="Delete">
                        <IconButton
                          onClick={() => handleIndividualDeleteClick(params)}
                        >
                          <DeleteOutlineIcon className="table_action_icon" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Open Campaign">
                        <IconButton
                          onClick={() => handleOpenTeamSection(params)}
                        >
                          <IoMdOpen className="table_action_icon" />
                        </IconButton>
                      </Tooltip>
                    </div>
                  );
                },
              },
              {
                type: "field",
                field: "workspace_name",
                headerName: "Workspace Name",
                minWidth: 250,
                width: 280,
                maxWidth: 1800,
                search_type: "text_search",
              },
              {
                type: "field",
                field: "owner_name",
                headerName: "Owner Name",
                search_type: "text_search",
                minWidth: 250,
                width: 300,
                maxWidth: 1800,
              },
              {
                type: "field",
                field: "team_name",
                headerName: "Team Name",
                search_type: "text_search",
                minWidth: 250,
                width: 280,
                maxWidth: 1800,
              },
            
              {
                type: "field",
                field: "workspace_description",
                search_type: "text_search",
                headerName: "Workspace Descriptuon",
                minWidth: 250,
                width: 400,
                maxWidth: 1800,
              },
              {
                type: "field",
                field: "start_date",
                headerName: "Start Date",
                search_type: "text_search",
                minWidth: 250,
                width: 300,
                maxWidth: 1800,
              },
              {
                type: "field",
                field: "deadline",
                headerName: "Deadline",
                search_type: "text_search",
                minWidth: 250,
                width: 300,
                maxWidth: 1800,
              },
              {
                type: "field",
                field: "status",
                headerName: "status",
                search_type: "text_search",
                minWidth: 250,
                width: 300,
                maxWidth: 1800,
              },

             
            ]}
            bulk_actions={[
              {
                action: "add",
                renderCell: (params) => {
                  return (
                    <Tooltip title="Add Workspace">
                      <div
                        className="bulk_classify_button download_icons"
                        onClick={handleAddOpen}
                      >
                        <IoIosAddCircleOutline />
                      </div>
                    </Tooltip>
                  );
                },
              },
              {
                action: "delete_multiple",
                renderCell: (params) => {
                  return (
                    <Tooltip title="Delete Selected Workspaces">
                      <div
                        onClick={() => handleBulkworkspaceDeleteClick(params)}
                        className="bulk_delete_button download_icons"
                      >
                        <MdOutlineDeleteSweep fontSize={30}/>
                      </div>
                    </Tooltip>
                  );
                },
              },
            ]}
          />
          {workspaceID && <My_Workspace workspaceID={workspaceID} />}
          <Modal
            open={showAddworkspaceForm}
            onClose={handleAddClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box id="model_box">
              <div className="add-user-form">
                <div className="modal_header">Add New workspace Name</div>
                <Box
                  component="form"
                  sx={{
                    "& > :not(style)": {
                      m: 2,
                      width: "80%",
                      marginLeft: "10%",
                    },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <TextField
                    id="workspace_name"
                    label="workspace Name"
                    variant="standard"
                    name="workspace_name"
                    value={newworkspaceData.workspace_name}
                    onChange={handleNewInputChange}
                    className="modal_form_input"
                  />
                </Box>
                <Box
                  component="form"
                  sx={{
                    "& > :not(style)": {
                      m: 2,
                      width: "80%",
                      marginLeft: "10%",
                    },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <TextField
                    id="workspace_description"
                    label="Workspace Description"
                    variant="standard"
                    name="workspace_description"
                    value={newworkspaceData.workspace_description}
                    onChange={handleNewInputChange}
                    className="modal_form_input"
                    multiline
                    rows={3}
                  />
                </Box>

                <Box
                  component="form"
                  sx={{
                    "& > :not(style)": {
                      m: 2,
                      width: "80%",
                      marginLeft: "10%",
                    },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <TextField
                    type="date"
                    id="start_date"
                    helperText="Please select Start Date"
                    
                    variant="standard"
                    name="start_date"
                    value={newworkspaceData.start_date}
                    onChange={handleNewInputChange}
                    className="modal_form_input"
                  />
                 
                </Box>
                <Box
                  component="form"
                  sx={{
                    "& > :not(style)": {
                      m: 2,
                      width: "80%",
                      marginLeft: "10%",
                    },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <TextField
                    id="deadline"
                    type="date"
                    helperText="Please select  Deadline Date"
                    variant="standard"
                    name="deadline"
                    value={newworkspaceData.deadline}
                    onChange={handleNewInputChange}
                    className="modal_form_input"
                  />
                </Box>
                <Box
                  component="form"
                  sx={{
                    "& > :not(style)": {
                      m: 2,
                      width: "80%",
                      marginLeft: "10%",
                    },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <TextField
                    id="status"
                    select
                    label="Status"
                   
                    name="status"
                    helperText="Please select your Status"
                    value={newworkspaceData.status}
                    onChange={handleNewInputChange}
                    className="modal_form_input"
                  >   <MenuItem value="Not Started"> Not Started</MenuItem>
                      <MenuItem value="Progress"> Progress</MenuItem>
                      <MenuItem value="Done"> Done</MenuItem>
                     
                  </TextField>

                </Box>
                <Box
                  component="form"
                  sx={{
                    "& > :not(style)": {
                      m: 2,
                      width: "80%",
                      marginLeft: "10%",
                    },
                  }}
                  noValidate
                  autoComplete="off"
                 
                >
                   <Autocomplete
                    id="search_user"
                    options={searchTeamResults}
                    getOptionLabel={(option) =>
                      `${option.team_name}`
                    }
                    onChange={(event, newValue) => handleTeamSelect(newValue)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Search Team"
                        variant="standard"
                        onChange={handleSearchTeamInputChange}
                        className="modal_form_input"
                      />
                    )}
                  />
                </Box>
                {selectedTeam && (
                  <div className="modal_text">
                    <Typography>
                      Selected Team: 
                      {selectedTeam.team_name}
                    </Typography>

                    <TextField
                      id="team_id"
                      variant="standard"
                      name="team_id"
                      value={newworkspaceData.team_id}
                      disabled={!newworkspaceData.team_id}
                      className="modal_form_input"
                    />
                    
                  </div>
                )}

                <button
                  onClick={handleAddworkspaceSubmit}
                  className="add-user-btn"
                >
                  Add workspace{" "}
                </button>
              </div>
            </Box>
          </Modal>

          <Modal
            open={showEditworkspaceModal}
            onClose={handleEditClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box id="model_box">
              <div className="add-user-form">
                <div className="modal_header">Update workspace</div>
                <Box
                  component="form"
                  sx={{
                    "& > :not(style)": {
                      m: 2,
                      width: "80%",
                      marginLeft: "10%",
                    },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <TextField
                    id="workspace_name"
                    label="workspace Name"
                    variant="standard"
                    name="workspace_name"
                    value={
                      selectedworkspace ? selectedworkspace.workspace_name : ""
                    }
                    onChange={handleInputChange}
                    className="modal_form_input"
                  />
                </Box>
                <Box
                  component="form"
                  sx={{
                    "& > :not(style)": {
                      m: 2,
                      width: "80%",
                      marginLeft: "10%",
                    },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <TextField
                    id="workspace_description"
                    label="Workspace Description "
                    variant="standard"
                    name="workspace_description"
                    value={
                      selectedworkspace ? selectedworkspace.workspace_description : ""
                    }
                    onChange={handleInputChange}
                    className="modal_form_input"
                  />
                </Box>
                

                <Box
                  component="form"
                  sx={{
                    "& > :not(style)": {
                      m: 2,
                      width: "80%",
                      marginLeft: "10%",
                    },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <TextField
                    type="date"
                    id="start_date"
                    helperText="Please select Start Date"
                    variant="standard"
                    name="start_date"
                    value={
                      selectedworkspace ? selectedworkspace.start_date : ""
                    }
                    onChange={handleInputChange}
                    className="modal_form_input"
                  />
                </Box>
                <Box
                  component="form"
                  sx={{
                    "& > :not(style)": {
                      m: 2,
                      width: "80%",
                      marginLeft: "10%",
                    },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <TextField
                    id="deadline"
                    type="date"
                    helperText="Please select Deadline Date"
                    variant="standard"
                    name="deadline"
                    value={selectedworkspace ? selectedworkspace.deadline : ""}
                    onChange={handleInputChange}
                    className="modal_form_input"
                  />
                </Box>
                
                <Box
                  component="form"
                  sx={{
                    "& > :not(style)": {
                      m: 2,
                      width: "80%",
                      marginLeft: "10%",
                    },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <TextField
                    id="status"
                    select
                    label="Status"
                    variant="standard"
                    name="status"
                    value={selectedworkspace ? selectedworkspace.status : ""}
                    onChange={handleInputChange}
                    className="modal_form_input"
                  >
                      <MenuItem value=" Not Started"> Not Started</MenuItem>
                      <MenuItem value="Progress"> Progress</MenuItem>
                      <MenuItem value="Done"> Done</MenuItem>
                  </TextField>
                </Box>
                
                <button
                  onClick={handleEditworkspaceData}
                  className="add-user-btn"
                >
                  Update workspace
                </button>
              </div>
            </Box>
          </Modal>

          <Modal
            open={showDeleteworkspaceModal}
            onClose={handleDeleteworkspaceModalClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box id="model_box">
              <div className="delete-user-form">
                <div className="modal_header">Delete workspace(s)</div>
                {selectedworkspacesForDelete.map((workspace) => (
                  <div key={workspace.workspace_id}>
                    <div className="modal_text">
                      workspace: {workspace.workspace_name} (
                      {workspace.duration})
                    </div>
                  </div>
                ))}
                <div className="modal_text">
                  Are you sure you want to delete selected workspace(s)?
                </div>
                <div className="form-group">
                  <Button onClick={handleDeleteworkspaceConfirm} color="error">
                    <MdDelete className="formIcons" />
                  </Button>
                  <Button onClick={handleDeleteworkspaceModalClose}>
                    <IoMdCloseCircle className="formIcons" />
                  </Button>
                </div>
              </div>
            </Box>
          </Modal>
          {/*Bulk Delete Workspaces Model */}
          <Modal
            open={showBulkDeleteModal}
            onClose={handleBulkDeleteClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box id="model_box">
              <div className="delete-user-form">
                <div className="modal_header">Delete workspace(s)</div>

                {selectedworkspacesForDelete.map((workspace) => (
                  <div key={workspace}>
                    <div className="modal_text">
                      workspace Name: {workspace}{" "}
                    </div>
                  </div>
                ))}
                <div className="modal_text">
                  Are you sure you want to delete selected workspace(s)?
                </div>
                <div className="form-group">
                  <Button onClick={handleBulkDeleteConfirm} color="error">
                    <MdDelete className="formIcons" />
                  </Button>
                  <Button onClick={handleBulkDeleteClose}>
                    <IoMdCloseCircle className="formIcons" />
                  </Button>
                </div>
              </div>
            </Box>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default Workspace;
