import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Checkbox,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,

  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Slider,
  Typography,
  Breadcrumbs,
  Link,
  Tabs,
  Tab,
  Tooltip,

} from "@mui/material";
import {
  CHECK_USER_CAMPAIGN_ACCESS,
  GET_FIND_LINK_INSERT_FORM_DATA,
  GET_INDIVIDUAL_CAMPAIGN_DETAIL,
  GET_RECENT_IN_CHARGE_OF_CAMPAIGN,
  getFullApiUrl,
  SAVE_FIND_LINK_INSERT_FORM_DATA,
  GET_UNIQUE_PAGE_TYPE
} from "../../../utils/apiPath";
import LeftSectionWithMenu from "../../common/Header/LeftSectionWithMenu/LeftSectionWithMenu";
import { NavigateNext } from "@mui/icons-material";
import { useNavigate, useParams, Link as RouterLink } from "react-router-dom";
import Header from "../../common/Header/Header";
import "./FindLinkInsertPage.css";
import { FaRegCircleQuestion } from "react-icons/fa6";
import FloatingMenuBar from "../../components/FloatingMenuBar/FloatingMenuBar";
import CustomButton from "../../components/CustomButton/CustomButton";
import { FaArrowLeft } from "react-icons/fa6";
import InfoIcon from '@mui/icons-material/Info';
import StatusModalComponent from "../../../components/StatusModalComponent/StatusModalComponent";
import { FaArrowRight } from "react-icons/fa";
import UTurnLeftIcon from '@mui/icons-material/UTurnLeft';

const FindLinkInsertPage = ({ toggleTheme }) => {
  const { campaignId } = useParams();
  const [pageTypes, setPageTypes] = useState({});
  const default_form_data = {
    ignorePageType: "no",
    ignoreSimilarityScore: "no",
    similarityScore: [0, 100],
    domainOpportunities: "1",
    alternativeLinks: "4",
    target_keyword_matching_logic: "preferable",
    pageTypeHandling: "include",
    pageTypes: pageTypes,

  }
  const [formData, setFormData] = useState(default_form_data);
  const [selectAll, setSelectAll] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  
  useEffect(() => {
    fetchedData()
  }, []);
  
  



  const fetchedData = async () =>{
    await getCampaignDetails();
    const uniquePageType = await fetchUniquePageTypes();
    await getFormDataByCampaignId(uniquePageType);
  }
  const fetchUniquePageTypes = async () => {
    try {
      const response = await fetch(`${getFullApiUrl(GET_UNIQUE_PAGE_TYPE)}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          campaign_id: campaignId, // Replace with your actual campaign_id
        }),
      });
  
      if (response.ok) {
        const data = await response.json();
        if (data.unique_page_types) {
          // Map the fetched unique page types to set them in default_form_data
          const updatedPageTypes = data.unique_page_types.reduce((acc, pageType) => {
            acc[pageType] = false; // Set initial state to false (or you can set based on any condition)
            return acc;
          }, {});
  
          setPageTypes(updatedPageTypes);
          return updatedPageTypes;
        }
      } else {
        console.error('Error fetching unique page types:', response.statusText);
      }
    } catch (error) {
      console.error("Error fetching unique page types:", error);
    }
  };
  
  
  const getFormDataByCampaignId = async (pageTypes) => {
    try {
        const storedData = localStorage.getItem('selectedOpportunityData');
        let fetchedData;

        if (storedData) {
            fetchedData = JSON.parse(storedData);
            console.log("Data fetched from localStorage");
        } else {
            const response = await fetch(`${getFullApiUrl(GET_FIND_LINK_INSERT_FORM_DATA)}`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ campaign_id: campaignId }),
            });

            if (!response.ok) throw new Error("Failed to fetch form data");
            fetchedData = await response.json();
            console.log("Data fetched from API");
        }

        if (fetchedData) {
            // Define the required pageTypes
            const requiredPageTypes = {
                "Blog Post": true,
                "Advice Listicle Blog Page": true,
                "Recommendation Listicle Blog Page": true,
                "Blog Roll Page": false,
                "Blog Category Page": false,
                "Blog Tag Page": false,
                "Blog Author Page": false,
                "Resource Page": true,
                "Comparison Blog Page": true,
                "Glossary Blog Page": true,
                "Wiki Page": false,
                "Homepage": false,
                "About Us Page": false,
                "Contact Page": false,
                "Privacy Policy Page": false,
                "Terms of Service Page": false,
                "Ecommerce Product Page": false,
                "Ecommerce Category Page": false,
                "Landing Page": false,
                "Service Page": false,
                "Pricing Page": false,
                "Case Study Page": false,
                "Testimonials/Reviews Page": false,
                "Portfolio Page": false,
                "News Page": false,
                "Press Release Page": false,
                "Events Page": false,
                "Forum Page": false,
                "Job Listing Page": false,
                "Directory Listing Page": false,
                "Social Media Post": false,
                "Profile Page": false,
                "Video Page": false,
                "PDF Page": false
            };

            // Ensure all required pageTypes are present
            const updatedPageTypes = { ...requiredPageTypes, ...pageTypes };
            
            // Merge with fetchedData.pageTypes
            for (const key of Object.keys(fetchedData.pageTypes || {})) {
                updatedPageTypes[key] = fetchedData.pageTypes[key];
            }

            // console.log("updatedPageTypes after reconciliation", updatedPageTypes);

            setFormData({
                ...fetchedData,
                pageTypes: updatedPageTypes,
            });

            setPageTypes(updatedPageTypes);

            // Update ignoreSimilarityScore state
            const ignoreSimilarity = fetchedData.ignoreSimilarityScore === "yes";
            setIgnoreSimilarityScore(ignoreSimilarity);

            // Determine the active index for buttons
            const activeBtnIndex = buttons.findIndex(
                (button) =>
                    button.range[0] === fetchedData.similarityScore[0] &&
                    button.range[1] === fetchedData.similarityScore[1]
            );

            setActiveIndex(activeBtnIndex !== -1 ? activeBtnIndex : null);
        } else {
            setFormData(default_form_data);
            console.log("No data from API, using default form data");
        }
    } catch (error) {
        console.error(error.message);
        setFormData(default_form_data);
        console.log("Error encountered, using default form data");
    }
};

  
  
  
  const getFormDataByCampaignId_2 = async () => {
    try {
      const storedData = localStorage.getItem('selectedOpportunityData');
      let fetchedData;
  
      if (storedData) {
        fetchedData = JSON.parse(storedData);
        console.log("Data fetched from localStorage");
      } else {
        const response = await fetch(`${getFullApiUrl(GET_FIND_LINK_INSERT_FORM_DATA)}`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ campaign_id: campaignId }),
        });
  
        if (!response.ok) throw new Error("Failed to fetch form data");
        fetchedData = await response.json();
        console.log("Data fetched from API");
      }
  
      if (fetchedData) {
        // Filter formData.pageTypes to only include unique_page_types
        const updatedPageTypes = Object.keys(pageTypes).reduce((acc, pageType) => {
          if (fetchedData.pageTypes && pageType in fetchedData.pageTypes) {
            acc[pageType] = fetchedData.pageTypes[pageType];
          } else {
            acc[pageType] = false;
          }
          return acc;
        }, {});
  
        setFormData({
          ...fetchedData,
          pageTypes: updatedPageTypes, // Update the pageTypes in formData
        });
  
        setPageTypes(updatedPageTypes); // Ensure pageTypes state is also updated
  
        // Update ignoreSimilarityScore state
        const ignoreSimilarity = fetchedData.ignoreSimilarityScore === "yes";
        setIgnoreSimilarityScore(ignoreSimilarity);
  
        // Determine the active index for buttons
        const activeBtnIndex = buttons.findIndex(
          (button) =>
            button.range[0] === fetchedData.similarityScore[0] &&
            button.range[1] === fetchedData.similarityScore[1]
        );
  
        setActiveIndex(activeBtnIndex !== -1 ? activeBtnIndex : null); // Set index or null
      } else {
        setFormData(default_form_data);
        console.log("No data from API, using default form data");
      }
    } catch (error) {
      console.error(error.message);
      setFormData(default_form_data);
      console.log("Error encountered, using default form data");
    }
  };
  
  


  const navigate = useNavigate();
  const goto = (page) => {
    navigate(page); // Navigate to the specified page
  };

  
  const [campaignData, setCampaignData] = useState({
    team_id: "",
    workspace_id: "",
    team_name: "",
    workspace_name: "",
    campaign_name: "",
  });


  useEffect(() => {
    const handleBeforeUnload = () => {
      sessionStorage.clear();
    };

    // Listen for page unload (refresh/close)
    window.addEventListener('beforeunload', handleBeforeUnload);

    // Cleanup listener on component unmount
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);





  const getCampaignDetails = async () => {
    try {
      const response = await fetch(
        `${getFullApiUrl(GET_INDIVIDUAL_CAMPAIGN_DETAIL)}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            campaign_id: campaignId,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch campaign details");
      }

      const data = await response.json();
      setCampaignData(data);
    } catch (error) {
      console.error(error.message);
    }
  };
  const getFormDataByCampaignId_1 = async () => {
    try {
      // Check if data exists in localStorage under the key 'selectedOpportunityData'
      const storedData = localStorage.getItem('selectedOpportunityData');
      if (storedData) {
        // Parse the stored data and update formData
        const parsedData = JSON.parse(storedData);
        setFormData(parsedData);
        console.log("Data fetched from localStorage");
        // Immediately delete the data from localStorage
      } else {
        // If no data in localStorage, proceed to fetch data from the API
        const response = await fetch(`${getFullApiUrl(GET_FIND_LINK_INSERT_FORM_DATA)}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ campaign_id: campaignId }),
        });

        if (!response.ok) throw new Error("Failed to fetch form data");

        const data = await response.json();
        if (data) {
          // If data is fetched, update formData
          setFormData(data);
          console.log("Data fetched from API");
        } else {
          // If no data is fetched, set formData to default_form_data
          setFormData(default_form_data);
          console.log("No data from API, using default form data");
        }
      }
    } catch (error) {
      console.error(error.message);
      // In case of any error, set formData to default_form_data
      setFormData(default_form_data);
      console.log("Error encountered, using default form data");
    }
  };


  
  

  const [modalState, setModalState] = useState({
    isOpen: false,
    type: "",
    message: "",
  });


  const saveFormDataByCampaignId = async () => {
    try {
      setModalState({
        isOpen: true,
        type: "loading",
        message: "Filtering to get finalized screenshot data",
      });
      const response = await fetch(`${getFullApiUrl(SAVE_FIND_LINK_INSERT_FORM_DATA)}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          campaign_id: campaignId,
          form_data: formData,
        }),
      });

      if (!response.ok) throw new Error("Failed to save form data");
      localStorage.removeItem('selectedOpportunityData');
      setModalState({
        isOpen: true,
        type: "success",
        message: "Screenshot data filtered successfully",
      });

      setTimeout(() => {
        setModalState({ isOpen: false, type: "", message: "" });
      }, 1000);
      navigate(`/my_campaign/${campaignId}/finalize_opportunities`);
    } catch (error) {
      console.error(error.message);
    }
  };

  const [domainOpportunities, setDomainOpportunities] = useState("1"); // Default to 1
  const [alternativeLinks, setAlternativeLinks] = useState("4"); // Default to 4

  // List of options for domain opportunities and alternative links
  const domainOptions = [
    { value: "1", label: "1 (Default)" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "5" },
    { value: "6", label: "6" },
    { value: "7", label: "7" },
    { value: "8", label: "8" },
    { value: "9", label: "9" },
    { value: "10", label: "10" },
    { value: "All", label: "All (Create an opportunity for every URL)" }
  ];

  const alternativeLinkOptions = [
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4 (Default)" },
    { value: "5", label: "5" },
    { value: "6", label: "6" },
    { value: "7", label: "7" },
    { value: "8", label: "8" },
    { value: "9", label: "9" },
    { value: "10", label: "10" },
    { value: "All", label: "All (Show all available link options)" }
  ];


  const [targetKeyword, setTargetKeyword] = React.useState("yes");

  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };
  const [similarityScore, setSimilarityScore] = React.useState([50, 90]);

  const [isIgnoreSimilarityScore, setIgnoreSimilarityScore] = React.useState(false);
  const [ignorePageType, setIgnorePageType] = useState(false)
  const buttons = [
    { range: [0, 20], label: 'Very Low' },       // New: Very low relevance
    { range: [21, 40], label: 'Low' },           // New: Low relevance
    { range: [41, 60], label: 'Moderately Low' },// New: Moderately low relevance
    { range: [30, 75], label: 'Relevant' },      // Updated: Relevant
    { range: [81, 90], label: 'Similar' },       // Updated: Similar
    { range: [91, 100], label: 'Same' },         // Updated: Same
    { range: [0, 100], label: 'All' }            // Preserved: All
  ];

  const [activeIndex, setActiveIndex] = useState(
    // Find the active button index based on the initial similarity score
    buttons.findIndex(
      (button) => formData.similarityScore[0] >= button.range[0] && formData.similarityScore[1] <= button.range[1]
    )
  );


  // Handle slider change and update activeIndex based on the slider value range

  const handleSliderChange = (event, newValue) => {
    setFormData((prevData) => ({
      ...prevData,
      similarityScore: newValue
    }));

    // Update the active button based on the slider value
    const newActiveIndex = buttons.findIndex(
      (button) => newValue[0] >= button.range[0] && newValue[1] <= button.range[1]
    );
    if (newActiveIndex !== -1) {
      setActiveIndex(newActiveIndex);
    }
  };
  // Handle button click to update slider value and set the activeIndex
  const handleButtonClick = (index) => {
    setActiveIndex(index);
    setFormData((prevData) => ({
      ...prevData,
      similarityScore: buttons[index].range
    }));
  };


  const handleCheckboxChangeForIgnoreSimilarityCheck = (event) => {
    const isChecked = event.target.checked;
    setIgnoreSimilarityScore(isChecked);
    setFormData((prevData) => ({
      ...prevData,
      ignoreSimilarityScore: isChecked ? "yes" : "no",
    }));
  };


  const handleCheckboxChangeForIngnorePageTypeCheck = (event) => {
    const isChecked = event.target.checked;
    setIgnorePageType(isChecked);
    setFormData((prevData) => ({
      ...prevData,
      ignorePageType: isChecked ? "yes" : "no",
    }));

    sessionStorage.setItem("ignorePageType", JSON.stringify(isChecked));
  };

  useEffect(() => {
    const savedState = JSON.parse(sessionStorage.getItem("ignorePageType"));
    if (savedState !== null) {
      setIgnorePageType(savedState);
      setFormData((prevData) => ({
        ...prevData,
        ignorePageType: savedState ? "yes" : "no",
      }));
    }
  }, []);

  const handleGoBackClick = () => {
    navigate(`/my_campaign/${campaignId}/screenshot`); // Adjust the path to match your routing configuration
  };

  const handleGoToFinalizeOppurtunityPage = () => {
    navigate(`/my_campaign/${campaignId}/finalize_opportunities`); // Adjust the path to match your routing configuration
  };
  const pageTypesList = [
    "About Us Page",
    "Blog Page",
    "Contact Us Page",
    "FAQ Page",
    "Home Page",
    "Landing Page",
    "News Page",
    "Privacy Policy Page",
    "Service Page",
    "Terms of Service Page",
    "Testimonials/Reviews Page",
    "Product Page",
    "Ecommerce Product Page",
    "Sales Page",
    "Forum Page",
    "Job Listing Page",
    "Category Page",
    "Tag Page",
  ];

  // const [pageTypes, setPageTypes] = useState(
  //   pageTypesList.reduce((acc, type) => {
  //     acc[type] = false;
  //     return acc;
  //   }, {})
  // );



  // const handleSliderChange = (event, newValue) => {
  //   setFormData((prevData) => ({
  //     ...prevData,
  //     similarityScore: newValue
  //   }));
  // };

  const handleDomainOpportunitiesChange = (event) => {
    const value = event.target.value;
    setFormData((prevData) => ({
      ...prevData,
      domainOpportunities: value,
      alternativeLinks: value === "1" ? "4" : "0"
    }));
  };

  const handleAlternativeLinksChange = (event) => {
    const value = event.target.value;
    setFormData((prevData) => ({
      ...prevData,
      alternativeLinks: value
    }));
  };

  const handleTargetKeywordChange = (event) => {
    const newValue = event.target.value;
    setFormData((prevData) => ({
      ...prevData,
      target_keyword_matching_logic: newValue
    }));
  };

  const handlePageTypeHandlingChange = (event) => {
    const newValue = event.target.value;
    setFormData((prevData) => ({
      ...prevData,
      pageTypeHandling: newValue,
    }));
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value.toLowerCase());
  };

  const filteredPageTypes = Object.keys(pageTypes).filter((pageType) =>
    pageType.toLowerCase().includes(searchQuery)
  );

  // Update page types selection
  const handlePageTypeChange = (event) => {
    const { name, checked } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      pageTypes: {
        ...prevData.pageTypes,
        [name]: checked,
      },
    }));
  };

  useEffect(() => {
    if (Object.keys(pageTypes).length > 0) {
      setFormData((prevData) => ({
        ...prevData,
        pageTypes: pageTypes, // Set formData.pageTypes to the fetched pageTypes
      }));
    }
  }, [pageTypes]); 

  // Handle "Select All" logic
  const handleSelectAllChange = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);

    const updatedPageTypes = Object.keys(formData.pageTypes).reduce((acc, type) => {
      acc[type] = newSelectAll;
      return acc;
    }, {});

    setFormData((prevData) => ({
      ...prevData,
      pageTypes: updatedPageTypes,
    }));
  };

  useEffect(() => {
    const allSelected = Object.values(formData.pageTypes).every((value) => value === true);
    setSelectAll(allSelected);
  }, [formData.pageTypes]);
  // console.log("Updated form formData.pageTypes:", formData.pageTypes);

  // useEffect(() => {
  //   console.log("Updated form data:", formData);
  // }, [formData]);

  const handleReturnClick = () => {
    console.log('handleReturnClick');
  }

  return (
    <div className="page_Section">
      <div className="leftSection_withMenu">
        <LeftSectionWithMenu />
      </div>
      <div className="rightSection_withMenu">
        <div className="headerSection_withMenu">
          <Header onToggleTheme={toggleTheme} />
        </div>
        <div className="bodySection">
          <Breadcrumbs
            id="Breadcrumbs"
            separator={<NavigateNext />}
            aria-label="breadcrumb"
          >
            <div className="breadcrumb_text">
              <Link
                component={RouterLink}
                to="/"
                color="inherit"
                underline="hover"
              >
                Home
              </Link>
            </div>
            <div className="breadcrumb_text">
              <Link
                component={RouterLink}
                to={`/my_teams`}
                color="inherit"
                underline="hover"
              >
                My Teams
              </Link>
            </div>
            <div className="breadcrumb_text">
              <Link
                component={RouterLink}
                to={`/my_team/${campaignData.team_id}`}
                color="inherit"
                underline="hover"
              >
                Details of Team  ( {campaignData.team_name} )
              </Link>
            </div>
            <div className="breadcrumb_text">
              <Link
                component={RouterLink}
                to={`/my_team_workspaces/${campaignData.team_id}`}
                color="inherit"
                underline="hover"
              >
                Workspaces of Team ( {campaignData.team_name} )
              </Link>
            </div>
            <div className="breadcrumb_text">
              <Link
                component={RouterLink}
                to={`/my_workspace/${campaignData.workspace_id}`}
                color="inherit"
                underline="hover"
              >
                Details of Workspace ({campaignData.workspace_name})
              </Link>
            </div>
            <div className="breadcrumb_text">
              <Link
                component={RouterLink}
                to={`/my_workspace_campaign/${campaignData.workspace_id}`}
                color="inherit"
                underline="hover"
              >
                Campaigns of Workspace  ({campaignData.workspace_name} )
              </Link>
            </div>
            <div className="breadcrumb_text"> Details of Campaign ({campaignData.campaign_name})</div>
            <div className="breadcrumb_text">Find Link Insert Opportunities</div>

          </Breadcrumbs>
          <FloatingMenuBar />


          <div className="find_link_page_contaainer">
            <Tabs
              value={activeTab}
              onChange={handleTabChange}
              aria-label="tabs"
            >
              <Tab label="Finalize on Existing Data" />
              <Tab label="Finalize Uploaded Data" />
            </Tabs>

            {activeTab === 0 && (
              <div className="tab_content_container">
                <div className="header_with_buttons_container">
                  <div className="header_and_go_back_button_container">
                   <div className="go_back_button" onClick={handleGoBackClick}>
                      <FaArrowLeft />
                    </div>
                    <div className="header_container">
                      <div className="header">Find Link Insert Opportunities</div>
                      <div className="sub_header">
                        Matches your target URLs with relevant links inserts
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="return_button" onClick={handleGoToFinalizeOppurtunityPage}>
                      <Tooltip title="Back to Opportunity Selection">
                        <div className="return_button_content">
                          <span>Return</span>
                          <UTurnLeftIcon style={{ transform: "rotate(90deg)", position: "absolute" }} />
                        </div>
                      </Tooltip>
                    </div>
                    <div className="button_container">
                      <CustomButton title="RESET" type="primary" onClick={() => setFormData(default_form_data)} />
                      <CustomButton title="PROCEED" type="secondary" onClick={saveFormDataByCampaignId} />
                    </div>
                  </div>
                </div>

                {/* Adjust Settings Section */}
                <div className="main_tab_content_container">
                  <div className="settings_and_keywords_filter_container">
                    <div className="settings_section">
                      <div className="header">Adjust Settings</div>
                      <div className="form_container">
                        <div className="form_item">
                          <div className="form_heading">
                            Number of opportunities per domain:
                          </div>
                          <FormControl
                            fullWidth
                            variant="outlined"
                            className="form_label_container"
                          >
                            <Select
                              value={formData.domainOpportunities}
                              onChange={handleDomainOpportunitiesChange}
                              label="Number of opportunities per domain"
                            >
                              {domainOptions.map((option, index) => (
                                <MenuItem key={index} value={option.value}>
                                  {option.label}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div>

                        {/* Conditional rendering of the second Select box */}
                        <div className="form_item">
                          <div className="form_heading">
                            Number of alternative links per opportunity:
                          </div>
                          <FormControl
                            fullWidth
                            variant="outlined"
                            className="form_label_container"
                          >
                            <Select
                              value={formData.alternativeLinks}
                              onChange={handleAlternativeLinksChange}
                              label="Number of alternative links per opportunity"
                              disabled={formData.domainOpportunities !== "1"} // Disable the Select box if domainOpportunities is not "1"
                            >
                              {alternativeLinkOptions.map((option, index) => (
                                <MenuItem key={index} value={option.value}>
                                  {option.label}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div>
                      </div>
                    </div>

                    <div className="keyword_filter">
                      <div className="header">
                        Must the Prospect Page Contain Your Exact Target
                        Keyword?
                      </div>
                      {/* Target Keyword Radio Group */}
                      <FormControl component="fieldset">
                        <RadioGroup
                          value={formData.target_keyword_matching_logic}
                          onChange={handleTargetKeywordChange}
                          row
                          sx={{
                            flexDirection: "column",
                          }}
                        >
                          <FormControlLabel
                            value="exact_match"
                            control={<Radio />}
                            label={
                              <div className="checkbox_text">
                                <div>Yes</div>
                                <div><FaRegCircleQuestion /></div>
                              </div>
                            }
                          />
                          <FormControlLabel
                            value="preferable"
                            control={<Radio />}
                            label={
                              <div className="checkbox_text">
                                <div>No, but Prefer Pages that Do</div>
                                <div><FaRegCircleQuestion /></div>
                              </div>
                            }
                          />
                          <FormControlLabel
                            value="doesnt_metter"
                            control={<Radio />}
                            label={
                              <div className="checkbox_text">
                                <div>Don’t Factor This In When Finding Opportunities</div>
                                <div><FaRegCircleQuestion /></div>
                              </div>
                            }
                          />
                        </RadioGroup>
                      </FormControl>
                    </div>
                  </div>

                  <Divider sx={{ bgcolor: "var(--background-default)" }} />

                  <div className="page-type-config">
                    <div className="page_type_confiq_conainer">
                      <div className="form_container">
                        <div className="header_container">
                          <div className="header">Page Type Configuration</div>
                          <div className="sub_header">
                            To customize your selection, uncheck any page types
                            you wish to include,
                          </div>
                          <div className="sub_header">
                            If you want to include URLs that do not have any
                            data, make sure to select the [No Data] option
                          </div>
                        </div>
                        <div className="page_type_check_box_container">
                          <Checkbox
                            checked={ignorePageType}
                            onChange={handleCheckboxChangeForIngnorePageTypeCheck}
                            color="primary"
                          />
                          <div className="opportunity_match_check_box_text">Ignore Page Type</div>
                          <Tooltip title="If checked, page type adjustments will be ignored.">
                            <FaRegCircleQuestion style={{ marginLeft: '8px' }} />
                          </Tooltip>
                        </div>
                        <div className="handle_url_and_search_bar_container">
                          <FormControl component="fieldset" style={{ marginTop: "1rem" }}>
                            <FormLabel component="legend">Handling URLs with No Page Type</FormLabel>
                            <RadioGroup
                              row
                              value={formData.pageTypeHandling}
                              onChange={handlePageTypeHandlingChange}
                              sx={{ flexDirection: "column" }}
                            >
                              <FormControlLabel
                                value="include"
                                control={<Radio />}
                                label="Include URLs with no page type"
                              />
                              <FormControlLabel
                                value="exclude"
                                control={<Radio />}
                                label="Exclude URLs with no page type"
                              />
                            </RadioGroup>
                          </FormControl>
                          <div className="search_bar_content">
                            <div className="search_bar_container">
                              <span className="search_icon">🔍</span>
                              <input
                                type="text"
                                className="search_input"
                                placeholder="Search page type"
                                value={searchQuery}
                                onChange={handleSearchChange}
                              />
                            </div>
                          </div>
                        </div>



                        <div className="check_box_container">
                          <FormGroup row className="form_group_grid">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={selectAll}
                                  onChange={handleSelectAllChange}
                                  name="selectAll"
                                />
                              }
                              label="Select All"
                            />
                            {filteredPageTypes.map((pageType, index) => (
                              <FormControlLabel
                                key={index}
                                control={
                                  <Checkbox
                                    checked={formData.pageTypes[pageType] || false}
                                    onChange={handlePageTypeChange}
                                    name={pageType}
                                  />
                                }
                                label={pageType}
                              />
                            ))}
                          </FormGroup>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div className="opportunity_match">
                    <div className="header_container">
                      <div className="header">Refine Your Opportunity Match Criteria</div>
                      <div className="sub_header">
                        Adjust the similarity score to find pages that match your target.
                      </div>
                    </div>

                    <div className="opportunity_match_check_box_container">
                      <Checkbox
                        checked={isIgnoreSimilarityScore}
                        onChange={handleCheckboxChangeForIgnoreSimilarityCheck}
                        color="primary"

                      />
                      <div className="opportunity_match_check_box_text">Ignore Opportunity Match</div>
                      <Tooltip title="If checked, similarity score adjustments will be ignored.">
                        <FaRegCircleQuestion style={{ marginLeft: '8px' }} />
                      </Tooltip>
                    </div>

                    <div className="slider_container">
                      <div className="heading">Preset Similarity Score</div>
                      <div className="filter-buttons">
                        {buttons.map((button, index) => (
                          <button
                            key={index}
                            className={`filter-button ${index === activeIndex ? 'active' : ''}`}
                            onClick={() => handleButtonClick(index)}
                            
                          >
                            <div>{button.range.join(' - ')}</div>
                            <div>{button.label}</div>
                          </button>
                        ))}
                      </div>
                      <Slider
                        value={formData.similarityScore}
                        onChange={handleSliderChange}
                        valueLabelDisplay="on"
                        min={0}
                        max={100}
                        style={{ color: "#90CAF9" }}
                        // disabled={isIgnoreSimilarityScore} // Disable slider when checkbox is checked
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}

            {activeTab === 1 && (
              <div>
                <Typography variant="h5" mb={2}>
                  Finalize Uploaded Data
                </Typography>
              </div>
            )}

            <StatusModalComponent
              isOpen={modalState.isOpen}
              type={modalState.type}
              message={modalState.message}
              onClose={() => setModalState({ isOpen: false, type: "", message: "" })}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FindLinkInsertPage;
