import React, { useEffect, useRef, useState } from "react";
import "./QuickVarifyScreenshotPage.css";
import {
    CircularProgress,
    Breadcrumbs,
    Link,
    Typography,
    Tooltip,
    Select,
    MenuItem,
    Divider,
    Snackbar,
    FormControl,
    Card,
    CardContent
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import Header from "../../common/Header/Header";
import LeftSectionWithMenu from "../../common/Header/LeftSectionWithMenu/LeftSectionWithMenu";
import FloatingMenuBar from "../../components/FloatingMenuBar/FloatingMenuBar";
import { NavigateNext } from "@mui/icons-material";
import { useParams, Link as RouterLink, useNavigate, useLocation } from "react-router-dom";
import {
    FILTER_USER_UPLOAD_DATA_FOR_SCREENSHOT,
    GET_INDIVIDUAL_CAMPAIGN_DETAIL,
    GET_SCREENSHOT,
    GET_TARGET_AND_PROSPECT_SCREENSHOT_DATA,
    GET_PROXY,
    TAKE_SCREENSHOT_AND_UPDATE_SCREENSHOT_DATA,
    getFullApiUrl,
    GET_FIND_LINK_INSERT_FORM_DATA,
    SAVE_TARGET_AND_PROSPECT_SCREENSHOT_DATA,
    GET_TOP_MATCH_SCREENSHOT_DATA,
    SAVE_TOP_MATCH_SCREENSHOT_DATA
} from "../../../utils/apiPath";
import { FaArrowLeft, FaCheckCircle } from "react-icons/fa";
import { AiOutlineInfoCircle } from "react-icons/ai";
import CustomButton from "../../components/CustomButton/CustomButton";
import { HiOutlineChevronLeft, HiOutlineChevronRight } from "react-icons/hi2";
import img from '../../../assets/images/AITool&SoftwareDevelopment.jpg'
import img1 from '../../../assets/website_demo/img_1.png'
import img2 from '../../../assets/website_demo/img_2.png'
import img3 from '../../../assets/website_demo/img_3.png'
import KeywordsSearch from "./KeywordsSearch/KeywordsSearch";
import { MdDoNotDisturbAlt } from "react-icons/md";
const QuickVarifyScreenshotPage = ({ toggleTheme }) => {

    const location = useLocation();
    const { finalizeOppurtunityPageData } = location.state || {};



    const [campaignData, setCampaignData] = useState({
        team_id: "",
        workspace_id: "",
        team_name: "",
        workspace_name: "",
        campaign_name: "",
    });
    const navigate = useNavigate();
    const [isScreenshotGet, setIsScreenshotGet] = useState(false)
    const [screenshotData, setScreenshotData] = useState([]);

    const [selectedItem, setSelectedItem] = useState(
        {
            blog_url: "",
            client_url: "",
            status: ""
        }
    );

    const [iframeSrc, setIframeSrc] = useState('');
    const [loading, setLoading] = useState(true);
    const [loadingScreenshot, setLoadingScreenshot] = useState(true);

    const itemsPerPage = 10;
    const { campaignId } = useParams();
    const [selectedProspect, setSelectedProspect] = useState('');
    const [selectedTarget, setSelectedTarget] = useState('');

    const [showKeywordsSearch, setShowKeywordsSearch] = useState(false);
    const [fetched_screenshot, set_fetched_screenshot] = useState()
    const [is_anchor_tag_visible, set_is_anchor_tag_visible] = useState(false)
    const [anchor_tag_url, set_anchor_tag_url] = useState("")

    const [currentPage, setCurrentPage] = useState(1);
    const [groupedData, setGroupedData] = useState({});
    const [totalPages, setTotalPages] = useState(0);
    const [topMatches, setTopMatches] = useState(null);
    const [currentPageTopMatch, setCurrentPageTopMatch] = useState(null);
    const [uniqueClientUrls, setUniqueClientUrls] = useState([]);
    const [selectedClientUrl, setSelectedClientUrl] = useState("");
    const currentKey = Object.keys(groupedData)[currentPage - 1];
    const [currentItems, setCurrentItems] = useState(groupedData[currentKey] || []);
    const [isYesClicked, setIsYesClicked] = useState(false);
    const [isNoClicked, setIsNoClicked] = useState(false);
    const [form_data, set_form_data] = useState()
    const [number_of_opportunity, set_number_of_opportunity] = useState(1)
    const [number_of_alternative_links, set_number_of_alternative_links] = useState(1)
    const [yesConfirmedData, setYesConfirmedData] = useState([]);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [status_update_check, set_status_update_check] = useState(false)
    const [otherItems, setOtherItems] = useState([]);
    const [allTopMatchData, setAllTopMatchData] = useState()
    const [isDataAvailable, setIsDataAvailable] = useState(false)
    const getFormDataByCampaignId = async () => {
        try {
            const response = await fetch(`${getFullApiUrl(GET_FIND_LINK_INSERT_FORM_DATA)}`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ campaign_id: campaignId }),
            });

            if (!response.ok) throw new Error("Failed to fetch form data");

            const data = await response.json();
            const opportunities = parseInt(data.domainOpportunities)
            const alternativeLinks = parseInt(data.alternativeLinks)
            set_number_of_opportunity(opportunities);
            set_number_of_alternative_links(alternativeLinks)
            return { domainOpportunities: data.domainOpportunities, alternativeLinks: data.alternativeLinks };
        } catch (error) {
            console.error(error.message);
            set_number_of_opportunity(1)
        }
    };
    const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));


    const handleSelectItem = async (item) => {
        setSelectedItem(item);

        // Determine the highlight class based on the item's status
        if (item.status === "Yes(Confirmed)") {
            setIsYesClicked(true);
            setIsNoClicked(false);
        } else if (item.status === "No(Confirmed)") {
            setIsYesClicked(false);
            setIsNoClicked(true);
        } else {
            setIsYesClicked(false);
            setIsNoClicked(false);
        }
    };



    const saveTopMatchScreenshotData = async (data) => {
        try {

            const response = await fetch(`${getFullApiUrl(SAVE_TOP_MATCH_SCREENSHOT_DATA)}`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    campaign_id: campaignId,
                    screenshot_data: data,
                }),
            });

            if (!response.ok) throw new Error("Failed to save form data");

        } catch (error) {
            console.error(error.message);
        }
    };

    const save_screenshot_data_function = async (screenshotData) => {
        try {
            const response = await fetch(`${getFullApiUrl(SAVE_TARGET_AND_PROSPECT_SCREENSHOT_DATA)}`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    campaign_id: campaignId,
                    screenshot_data: screenshotData,
                }),
            });

            if (!response.ok) throw new Error("Failed to save form data");


        } catch (error) {
            console.error(error.message);
        }
    };
    const handleYesClick = async () => {
    console.log("handleYesClick triggered");

    if (!selectedItem) {
        console.warn("No selectedItem, exiting function");
        return;
    }

    set_status_update_check(true);
    console.log("Status update check set to true");

    const currentTopMatchIndex = currentPage - 1;
    const topMatch = finalizeOppurtunityPageData[currentTopMatchIndex];

    console.log("Current Page:", currentPage);
    console.log("Top Match (before update):", topMatch);
    console.log("Selected Item (before update):", selectedItem);

    let updatedSelectedItem = { ...selectedItem, status: "Yes(Confirmed)" };
    let updatedTopMatch = { ...topMatch };

    if (topMatch.id !== selectedItem.id) {
        updatedTopMatch.status = "No(Confirmed)";
    }

    console.log("Updated Selected Item:", updatedSelectedItem);
    console.log("Updated Top Match:", updatedTopMatch);

    const selectedItemBlogDomain = selectedItem.blog_domain;
    console.log("Selected Item Blog Domain:", selectedItemBlogDomain);

    const updatedScreenshotData = screenshotData.map(item => {
       
        if (item.id === selectedItem.id) {
            console.log(`Updating selectedItem ${item.id} to Yes(Confirmed)`);
            return { ...item, status: "Yes(Confirmed)" };
        }
        if (item.blog_domain === selectedItemBlogDomain && item.id !== selectedItem.id) {
            console.log(`Updating ${item.id} in same blog_domain to No(Confirmed)`);
            return { ...item, status: "No(Confirmed)" };
        }
        return item;
    });

    console.log("Updated Screenshot Data:", updatedScreenshotData);

    const updatedTopMatchData = allTopMatchData.map(item =>
        item.id === topMatch.id ? updatedSelectedItem : item
    );
    console.log("Updated Top Match Data:", updatedTopMatchData);

    const updatedFinalizeOppurtunityPageData = finalizeOppurtunityPageData.map(item =>
        item.id === topMatch.id ? updatedSelectedItem : item
    );
    console.log("Updated Finalize Opportunity Page Data:", updatedFinalizeOppurtunityPageData);

    setTopMatches(updatedSelectedItem);
    setCurrentPageTopMatch(updatedSelectedItem);
    setSelectedItem(updatedSelectedItem);
    setScreenshotData(updatedScreenshotData);
    setAllTopMatchData(updatedTopMatchData);

    setYesConfirmedData(prev => {
        const exists = prev.find(item => item.id === updatedSelectedItem.id);
        if (!exists) console.log("Adding to YesConfirmedData:", updatedSelectedItem);
        return exists ? prev : [...prev, updatedSelectedItem];
    });

    saveTopMatchScreenshotData(updatedTopMatchData);
    save_screenshot_data_function(updatedScreenshotData);

    console.log("Calling determineTopMatches...");
    await determineTopMatches(
        updatedScreenshotData,
        updatedFinalizeOppurtunityPageData,
        updatedTopMatchData,
        number_of_opportunity,
        number_of_alternative_links,
        currentPage
    );

    console.log("determineTopMatches completed");
    setIsYesClicked(true);
    setIsNoClicked(false);
    console.log("Yes clicked set to true, No clicked set to false");
};






    const handleNoClick = async () => {
        if (!selectedItem) return;

        set_status_update_check(true);

        const currentTopMatchIndex = currentPage - 1;
        const topMatch = finalizeOppurtunityPageData[currentTopMatchIndex];

        console.log("currentPageData ", currentPage);
        console.log("currentItems ", currentItems);

        let updatedSelectedItem = { ...selectedItem, status: "No(Confirmed)" };
        let updatedTopMatch = { ...topMatch };

        // If topMatch and selectedItem are the same, update the status in topMatch
        if (topMatch.id === selectedItem.id) {
            updatedTopMatch = updatedSelectedItem;
        }

        // Update screenshotData
        const updatedScreenshotData = screenshotData.map(item =>
            item.id === selectedItem.id ? updatedSelectedItem : item
        );

        // Check if selectedItem exists in allTopMatchData or finalizeOppurtunityPageData
        const isInAllTopMatchData = allTopMatchData.some(item => item.id === selectedItem.id);
        const isInFinalizeOppurtunityPageData = finalizeOppurtunityPageData.some(item => item.id === selectedItem.id);

        // Update allTopMatchData if selectedItem is found
        const updatedTopMatchData = isInAllTopMatchData
            ? allTopMatchData.map(item => item.id === selectedItem.id ? updatedSelectedItem : item)
            : allTopMatchData;

        // Update finalizeOppurtunityPageData if selectedItem is found
        const updatedFinalizeOppurtunityPageData = isInFinalizeOppurtunityPageData
            ? finalizeOppurtunityPageData.map(item => item.id === selectedItem.id ? updatedSelectedItem : item)
            : finalizeOppurtunityPageData;

        console.log("updatedSelectedItem ", updatedSelectedItem);
        console.log("updatedScreenshotData ", updatedScreenshotData);

        setTopMatches(updatedSelectedItem);
        setCurrentPageTopMatch(updatedSelectedItem);
        setSelectedItem(updatedSelectedItem);

        setScreenshotData(updatedScreenshotData);
        setAllTopMatchData(updatedTopMatchData);
        // setFinalizeOppurtunityPageData(updatedFinalizeOppurtunityPageData);

        setYesConfirmedData(prev => {
            const exists = prev.find(item => item.id === updatedSelectedItem.id);
            return exists ? prev : [...prev, updatedSelectedItem];
        });

        // Conditionally call saveTopMatchScreenshotData only if allTopMatchData was updated
        if (isInAllTopMatchData) {
            saveTopMatchScreenshotData(updatedTopMatchData);
        }

        save_screenshot_data_function(updatedScreenshotData);
        await determineTopMatches(
            updatedScreenshotData,
            updatedFinalizeOppurtunityPageData,
            updatedTopMatchData,
            number_of_opportunity,
            number_of_alternative_links,
            currentPage
        );

        // Update button states
        setIsYesClicked(false);
        setIsNoClicked(true);
    };






    const handleFindOwnMatches = () => {
        setShowKeywordsSearch(true);
    };
    const iframeRef = useRef(null);

    // State to manage iframe navigation history
    const [iframeHistory, setIframeHistory] = useState([]);
    const [currentUrlIndex, setCurrentUrlIndex] = useState(-1);



    const groupDataByDomainCombination_old = async (data) => {
        const groupedData = {};
        let topMatchItem = null;

        // Group data by blog domain and determine topMatches for each domain
        data.forEach((item) => {
            if (!groupedData[item.blog_domain]) groupedData[item.blog_domain] = [];
            groupedData[item.blog_domain].push(item);
        });

        // Sorting logic: First by similarity_score, then by keyword match
        const sortedGroupedData = {};
        for (const [domain, items] of Object.entries(groupedData)) {
            // Sort items by similarity_score and then by keyword match
            const sortedItems = items.sort((a, b) => {
                const scoreA = parseInt(a.similarity_score.replace("%", ""), 10);
                const scoreB = parseInt(b.similarity_score.replace("%", ""), 10);

                // Check if keyword match exists (any keyword occurrence > 0)
                const hasKeywordMatchA = a.keywords.some((keyword) => Object.values(keyword)[1] > 0);
                const hasKeywordMatchB = b.keywords.some((keyword) => Object.values(keyword)[1] > 0);

                // Prioritize by similarity_score first, then by keyword match
                if (scoreA !== scoreB) {
                    return scoreB - scoreA; // Higher score first
                } else {
                    return hasKeywordMatchB ? 1 : -1; // Prefer items with keyword match
                }
            });

            sortedGroupedData[domain] = sortedItems;
        }

        // Return the sorted grouped data
        return sortedGroupedData;
    };

    const groupDataByDomainCombination = async (data) => {
        const groupedData = {};
        let topMatchItem = null;

        // Group data by blog domain and determine topMatches for each domain
        data.forEach((item) => {
            if (!groupedData[item.blog_domain]) groupedData[item.blog_domain] = [];
            groupedData[item.blog_domain].push(item);
        });

        // Sorting logic: First by similarity_score, then by keyword match
        const sortedGroupedData = {};
        for (const [domain, items] of Object.entries(groupedData)) {
            // Sort items by similarity_score and then by keyword match
            const sortedItems = items.sort((a, b) => {
                const scoreA = parseInt(a.similarity_score.replace("%", ""), 10);
                const scoreB = parseInt(b.similarity_score.replace("%", ""), 10);

                // Check if keyword match exists (any keyword occurrence > 0)
                const hasKeywordMatchA = a.keywords.some((keyword) => Object.values(keyword)[1] > 0);
                const hasKeywordMatchB = b.keywords.some((keyword) => Object.values(keyword)[1] > 0);

                // Prioritize by similarity_score first, then by keyword match
                if (scoreA !== scoreB) {
                    return scoreB - scoreA; // Higher score first
                } else {
                    return hasKeywordMatchB ? 1 : -1; // Prefer items with keyword match
                }
            });

            sortedGroupedData[domain] = sortedItems;
        }

        // Return the sorted grouped data
        return sortedGroupedData;
    };

    const [currentTopMatchIndex, setCurrentTopMatchIndex] = useState(1)

    const findAlternativeLinks = (allScreenshotData, topMatchData, currentBlogDomain, numberOfAlternativeLinks) => {
        console.log("allScreenshotData data loaded in findAlternativeLinks ", allScreenshotData)
        console.log("topMatchData data loaded in findAlternativeLinks ", topMatchData)
        console.log("currentBlogDomain data loaded in findAlternativeLinks ", currentBlogDomain)
        console.log("numberOfAlternativeLinks data loaded in findAlternativeLinks ", numberOfAlternativeLinks)

        // Separate all_screenshot_data by blog_domain
        const domainFilteredItems = allScreenshotData.filter(
            (item) => item.blog_domain === currentBlogDomain && !topMatchData.some((topItem) => topItem.id === item.id)
        );

        console.log("Filtered items for domain", currentBlogDomain, domainFilteredItems);

        // Sort remaining items by similarity_score and keyword occurrence
        const getTotalKeywordOccurrence = (keywords) =>
            keywords.reduce((total, keyword) => total + keyword.occurrence, 0);

        const sortedAlternatives = domainFilteredItems
            .sort((a, b) => {
                const scoreA = parseInt(a.similarity_score.replace("%", ""), 10);
                const scoreB = parseInt(b.similarity_score.replace("%", ""), 10);
                const totalOccurrenceA = getTotalKeywordOccurrence(a.keywords);
                const totalOccurrenceB = getTotalKeywordOccurrence(b.keywords);

                if (scoreA !== scoreB) return scoreB - scoreA; // Higher similarity score first
                return totalOccurrenceB - totalOccurrenceA; // Higher total occurrence first
            })
            .slice(0, numberOfAlternativeLinks);

        console.log("Sorted alternative links:", sortedAlternatives);
        return sortedAlternatives;
    };

    const determineTopMatches = async (
        allScreenshotData,
        finalizeOppurtunityPageData,
        allTopMatchData,
        numberOfOpportunity,
        numberOfAlternativeLinks,
        currentPageIndex
    ) => {
        if (allScreenshotData.length === 0) {
            console.log("Input data is empty. Exiting function.");
            return;
        }

        let topMatches = [];
        let otherItems = {};

        // Determine the current topMatch based on currentPageIndex
        const currentTopMatchIndex = currentPageIndex - 1;
        const topMatch = finalizeOppurtunityPageData[currentTopMatchIndex];

        console.log("Current Page Index:", currentPageIndex);
        console.log("Current Top Match:", topMatch);

        if (topMatch) {
            topMatches.push(topMatch);
            console.log("Added Top Match:", topMatch);
        } else {
            console.log("No top match found for the current page index.");
        }

        if (numberOfAlternativeLinks > 0 && topMatch) {
            const currentBlogDomain = topMatch.blog_domain;
            console.log("Fetching alternatives for blog domain:", currentBlogDomain);

            const alternatives = findAlternativeLinks(
                allScreenshotData,
                allTopMatchData,
                currentBlogDomain,
                numberOfAlternativeLinks
            );

            if (alternatives.length > 0) {
                otherItems[currentBlogDomain] = alternatives;
                console.log("Assigned alternatives for domain", currentBlogDomain, alternatives);
            } else {
                console.log("No alternatives found for domain", currentBlogDomain);
            }
        }

        console.log("Final Top Matches:", topMatches);
        console.log("Final Other Items:", otherItems);

        setTopMatches(topMatches);
        if (numberOfAlternativeLinks > 0) setOtherItems(otherItems);

        if (topMatches.length > 0) {
            const selectedItem = topMatches[0];
            console.log("Selected Item:", selectedItem);

            setCurrentPageTopMatch(selectedItem);
            setSelectedItem(selectedItem);
            handleSelectItem(selectedItem);
        } else {
            console.log("No top matches available to set as the selected item.");
        }
    };






    const getTopMatchScreenshotData = async () => {

        try {

            const response = await fetch(
                `${getFullApiUrl(GET_TOP_MATCH_SCREENSHOT_DATA)}`,
                {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({ campaign_id: campaignId }),
                }
            );

            if (!response.ok) throw new Error("Failed to fetch screenshot data");

            const data = await response.json();


            return data;

        } catch (error) {
            console.error(error.message);
        } finally {
            setLoading(false);
        }
    };


    const getScreenShotDataWithFilter = async (domainOpportunities, alternativeLinks) => {
        try {
            setIsDataAvailable(true);

            const topMatchData = await getTopMatchScreenshotData();
            console.log("topMatchData ", topMatchData);
            console.log("finalizeOppurtunityPageData ", finalizeOppurtunityPageData);

            // Check if finalizeOppurtunityPageData is null or empty before proceeding
            if (!finalizeOppurtunityPageData || finalizeOppurtunityPageData.length < 1) {
                setLoading(false);
                setIsDataAvailable(false);
                return;
            }

            // Proceed with filtering
            console.log("Filtered Data: ", topMatchData);
            console.log("Length of Filtered Data: ", finalizeOppurtunityPageData.length);

            setLoading(true);
            setIsYesClicked(false);

            // Fetch data from the API
            const response = await fetch(
                `${getFullApiUrl(GET_TARGET_AND_PROSPECT_SCREENSHOT_DATA)}`,
                {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({ campaign_id: campaignId }),
                }
            );

            if (!response.ok) throw new Error("Failed to fetch screenshot data");

            const screenshotData = await response.json();
            console.log("screenshotData ", screenshotData);

            // Update finalizeOppurtunityPageData based on topMatchData
            finalizeOppurtunityPageData.forEach(item => {
                const matchingTopMatch = topMatchData.find(topMatch => topMatch.id === item.id);
                if (matchingTopMatch && item.status !== matchingTopMatch.status) {
                    console.warn(`Status mismatch found for item ID ${item.id}: Updating from ${item.status} to ${matchingTopMatch.status}`);
                    item.status = matchingTopMatch.status; // Update the status
                }
            });

            // Save data and update state
            setScreenshotData(screenshotData);
            setGroupedData(finalizeOppurtunityPageData);
            setTotalPages(finalizeOppurtunityPageData.length);
            setCurrentPage(1);

            setAllTopMatchData(topMatchData);
            await determineTopMatches(screenshotData, finalizeOppurtunityPageData, topMatchData, domainOpportunities, alternativeLinks, 1);
        } catch (error) {
            console.error(error.message);
        } finally {
            setLoading(false);
        }
    };




    const goToNextPage = async () => {
        if (currentPage < totalPages) {
            const newPage = currentPage + 1;
            setCurrentPage(newPage);
            await determineTopMatches(screenshotData, finalizeOppurtunityPageData, allTopMatchData, number_of_opportunity, number_of_alternative_links, newPage)




        } else {
            setSnackbarMessage("You are already on the last page.");
            setSnackbarOpen(true);

            console.log("You are already on the last page.");
        }
    };


    // Adjust previous page logic similarly
    const goToPreviousPage = async () => {
        if (currentPage > 1) {
            const newPage = currentPage - 1;
            setCurrentPage(newPage);
            setCurrentTopMatchIndex(newPage)

            determineTopMatches(screenshotData, finalizeOppurtunityPageData, allTopMatchData, number_of_opportunity, number_of_alternative_links, newPage)

        } else {
            console.log("You are already on the first page.");
            setSnackbarMessage("You are already on the first page.");
            setSnackbarOpen(true);
        }
    };

    const goToPreviousScreenshotData = () => {
        // Get the current page's data
        const currentKey = Object.keys(groupedData)[currentPage - 1];
        const currentPageData = groupedData[currentKey];

        if (currentPageData) {
            const currentIndex = currentPageData.indexOf(selectedItem);
            if (currentIndex > 0) {
                const previousItem = currentPageData[currentIndex - 1];
                setSelectedItem(previousItem); // Update selected item
                setTopMatches(topMatches); // Keep topMatches unchanged
            } else {
                console.warn("Already at the first item on this page.");
            }
        } else {
            console.warn("No data available for the current page.");
        }
    };

    const goToNextScreenshotData = () => {
        // Get the current page's data
        const currentKey = Object.keys(groupedData)[currentPage - 1];
        const currentPageData = groupedData[currentKey];

        if (currentPageData) {
            const currentIndex = currentPageData.indexOf(selectedItem);
            if (currentIndex < currentPageData.length - 1) {
                const nextItem = currentPageData[currentIndex + 1];
                setSelectedItem(nextItem); // Update selected item
                setTopMatches(topMatches); // Keep topMatches unchanged
            } else {
                console.warn("Already at the last item on this page.");
            }
        } else {
            console.warn("No data available for the current page.");
        }
    };






    useEffect(() => {
        getCampaignDetails();
        fetchData()

    }, []);
    const fetchData = async () => {
        const { domainOpportunities, alternativeLinks } = await getFormDataByCampaignId()
        await getScreenShotDataWithFilter(domainOpportunities, alternativeLinks);
    }




    const getCampaignDetails = async () => {
        try {
            const response = await fetch(
                `${getFullApiUrl(GET_INDIVIDUAL_CAMPAIGN_DETAIL)}`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        campaign_id: campaignId,
                    }),
                }
            );

            if (!response.ok) {
                throw new Error("Failed to fetch campaign details");
            }

            const data = await response.json();
            setCampaignData(data);
        } catch (error) {
            console.error(error.message);
        }
    };




    const closeSnackbar = () => {
        setSnackbarOpen(false);
    };


    const handleGoBackClick = () => {
        navigate(`/my_campaign/${campaignId}/finalize_opportunities`); // Adjust the path to match your routing configuration
    };

    const getBarColorClass = (score) => {
        const scoreValue = parseInt(score.replace("%", ""), 10);
        if (scoreValue > 66) {
            return "similarity_green";
        } else if (scoreValue >= 25 && scoreValue <= 66) {
            return "similarity_orange";
        } else {
            return "similarity_red";
        }
    };

    const renderSimilarityBar = (score) => {
        const scoreValue = parseInt(score.replace("%", ""), 10);
        const barColorClass = getBarColorClass(score);

        return (
            <div className="similarity_bar_container">
                <div
                    className={`similarity_bar ${barColorClass}`}
                    style={{ width: `${scoreValue}%` }}
                ></div>
            </div>
        );
    };
    const getTextColorClass = (score) => {
        const scoreValue = parseInt(score.replace("%", ""), 10);
        if (scoreValue > 66) {
            return "text_green";
        } else if (scoreValue >= 25 && scoreValue <= 66) {
            return "text_orange";
        } else {
            return "text_red";
        }
    };


    const fetchScreenshot = async (url) => {
        try {
            setLoadingScreenshot(true);  // Show loading spinner
            const response = await fetch(
                `${getFullApiUrl(GET_SCREENSHOT)}`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        url: url,
                    }),
                }
            );

            if (!response.ok) {
                throw new Error("Failed to fetch the screenshot");
            }

            const data = await response.json(); // Assuming the image URL is in the response
            set_fetched_screenshot(data.screenshot)
            return data.screenshot; // Assuming the image URL is returned in this key
        } catch (error) {
            console.error('Error fetching screenshot:', error);
            // alert('Failed to load the screenshot image');
            return null;
        } finally {
            setLoadingScreenshot(false);  // Hide loading spinner
        }
    };

    const loadScreenshot = async () => {
        const screenshot = await fetchScreenshot(selectedItem.blog_url);
        set_fetched_screenshot(screenshot)
    };


    const handleIframeError1 = () => {
        // If there's an error loading the iframe, use the proxy URL as fallback
        console.log('Iframe error detected, switching to proxy URL');
        setIframeSrc(`${getFullApiUrl(GET_PROXY)}?url=${encodeURIComponent(selectedItem.blog_url)}`);
    };
    useEffect(() => {
        if (selectedItem.blog_url) {
            console.log("New selectedItem detected:", selectedItem.blog_url);
            setIframeHistory([selectedItem.blog_url]);
            setIframeSrc(selectedItem.blog_url);
            setCurrentUrlIndex(0);
        }
    }, [selectedItem.blog_url]);


    const handleIframeLoad = () => {
        console.log("Iframe attempted to load. ", iframeSrc);
        handleIframeError("unknown_error");

    };

    const handleIframeError = async (errorType = "unknown") => {
        try {
            console.warn(`Iframe error detected (${errorType}). Attempting to fetch a screenshot...`);

            // Reset the display states
            set_is_anchor_tag_visible(false);
            setIframeSrc(null);
            set_fetched_screenshot(null);

            // Fetch the screenshot
            const screenshot = await fetchScreenshot(selectedItem.blog_url);

            if (screenshot) {
                console.log("Screenshot fetched successfully.");
                set_fetched_screenshot(screenshot);
            } else {
                console.error("Failed to fetch screenshot. Showing link as fallback.");
                set_anchor_tag_url(selectedItem.blog_url);
                set_is_anchor_tag_visible(true); // Show anchor tag
            }
        } catch (error) {
            console.error("Error during fallback handling:", error);
            set_anchor_tag_url(selectedItem.blog_url);
            set_is_anchor_tag_visible(true); // Show anchor tag
        }
    };


    const handleSelectItemForBlogUrl = (url, currentSelectedItem) => {
        const selectedDomain = currentSelectedItem?.blog_domain;

        // Find the selected item from currentPageTopMatch or otherItems
        const matchedItem =
            currentPageTopMatch?.client_url === url
                ? currentPageTopMatch
                : otherItems?.[selectedDomain]?.find(item => item.client_url === url);

        if (matchedItem) {
            setSelectedItem(matchedItem);

            // Determine the highlight class based on the item's status
            if (matchedItem.status === "Yes(Confirmed)") {
                setIsYesClicked(true);
                setIsNoClicked(false);
            } else if (matchedItem.status === "No(Confirmed)") {
                setIsYesClicked(false);
                setIsNoClicked(true);
            } else {
                setIsYesClicked(false);
                setIsNoClicked(false);
            }
        }
    };



    return (
        <div className="page_Section">
            <div className="leftSection_withMenu">
                <LeftSectionWithMenu />
            </div>
            <div className="rightSection_withMenu">
                <div className="headerSection_withMenu">
                    <Header onToggleTheme={toggleTheme} />
                </div>
                <div className="bodySection">
                    <Breadcrumbs
                        id="Breadcrumbs"
                        separator={<NavigateNext />}
                        aria-label="breadcrumb"
                    >
                        <div className="breadcrumb_text">
                            <Link
                                component={RouterLink}
                                to="/"
                                color="inherit"
                                underline="hover"
                            >
                                Home
                            </Link>
                        </div>
                        <div className="breadcrumb_text">
                            <Link
                                component={RouterLink}
                                to={`/my_teams`}
                                color="inherit"
                                underline="hover"
                            >
                                My Teams
                            </Link>
                        </div>
                        <div className="breadcrumb_text">
                            <Link
                                component={RouterLink}
                                to={`/my_team/${campaignData.team_id}`}
                                color="inherit"
                                underline="hover"
                            >
                                Details of Team  ( {campaignData.team_name} )
                            </Link>
                        </div>
                        <div className="breadcrumb_text">
                            <Link
                                component={RouterLink}
                                to={`/my_team_workspaces/${campaignData.team_id}`}
                                color="inherit"
                                underline="hover"
                            >
                                Workspaces of Team ( {campaignData.team_name} )
                            </Link>
                        </div>
                        <div className="breadcrumb_text">
                            <Link
                                component={RouterLink}
                                to={`/my_workspace/${campaignData.workspace_id}`}
                                color="inherit"
                                underline="hover"
                            >
                                Details of Workspace ({campaignData.workspace_name})
                            </Link>
                        </div>
                        <div className="breadcrumb_text">
                            <Link
                                component={RouterLink}
                                to={`/my_workspace_campaign/${campaignData.workspace_id}`}
                                color="inherit"
                                underline="hover"
                            >
                                Campaigns of Workspace  ({campaignData.workspace_name} )
                            </Link>
                        </div>
                        <div className="breadcrumb_text"> Details of Campaign ({campaignData.campaign_name})</div>
                        <div className="breadcrumb_text"> Quick Verify Screenshot Page</div>

                    </Breadcrumbs>
                    <FloatingMenuBar />

                    {loading ? (
                        <div className="loading_spinner">
                            <CircularProgress />
                        </div>
                    ) : (

                        isDataAvailable ? (
                            <>
                                <div className="quickvarify_screenshot_page">
                                    <div className="quickvarify_main_content_container">
                                        <div className="header">
                                            <div className="go_back_button" onClick={handleGoBackClick}>
                                                <FaArrowLeft />
                                            </div>
                                            <div className="header_input_text_with_button_container">
                                                <div className="header_input_text">
                                                    <div>
                                                        Opportunities Status :
                                                    </div>
                                                    <div className="status_container">
                                                        {selectedItem?.status ? selectedItem.status : "Yes (Auto)"}
                                                    </div>
                                                </div>
                                                <div className="yes_no_button_container">
                                                    <button className={`priamry ${isYesClicked ? 'selected' : ''}`}
                                                        onClick={handleYesClick}

                                                        disabled={isYesClicked}
                                                    >YES (F)</button>
                                                    <button className={`secondary ${isNoClicked ? 'selected' : ''}`} onClick={handleNoClick}>NO (C)</button>
                                                </div>
                                            </div>
                                            <div className="pagination">
                                                <div className="pagination_icon_container" onClick={goToPreviousPage} disabled={currentPage === 1}>
                                                    <HiOutlineChevronLeft />
                                                </div>
                                                <div>{currentPage} / {totalPages}</div>
                                                <div className="pagination_icon_container" onClick={goToNextPage} disabled={currentPage === totalPages}>
                                                    <HiOutlineChevronRight />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="navigation_and_select_box_container">
                                            <div className="navigation_pagination">
                                                <div onClick={goToPreviousScreenshotData} >
                                                    <HiOutlineChevronLeft />
                                                </div>
                                                <div onClick={goToNextScreenshotData} >
                                                    <HiOutlineChevronRight />
                                                </div>
                                            </div>
                                            <div className="input_container">
                                                <div className="input_content_container">
                                                    <div>Prospect URL</div>
                                                    <div className="input_content_url_container">
                                                        {selectedItem?.blog_url ? (
                                                            <a
                                                                href={selectedItem.blog_url}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                                style={{ textDecoration: 'none', color: 'inherit' }}
                                                            >
                                                                {selectedItem.blog_url}
                                                            </a>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className="input_content_container">
                                                    <div>Target URL</div>
                                                    <div className="input_select_content_url_container">
                                                        <FormControl fullWidth variant="outlined">
                                                            <Select
                                                                value={selectedItem?.client_url || ""}
                                                                onChange={(e) => handleSelectItemForBlogUrl(e.target.value, selectedItem)}
                                                                displayEmpty
                                                                style={{ width: "100%", height: '45px' }}
                                                            >
                                                                <MenuItem value="" disabled>
                                                                    Select a URL
                                                                </MenuItem>
                                                                {Array.from(
                                                                    new Set(
                                                                        [
                                                                            ...(currentPageTopMatch?.client_url ? [currentPageTopMatch.client_url] : []),
                                                                            ...(otherItems?.[selectedItem?.blog_domain]?.map(item => item.client_url) || [])
                                                                        ].filter(Boolean)
                                                                    )
                                                                ).map((url, index) => (
                                                                    <MenuItem key={index} value={url}>
                                                                        {url}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="screenshot_image_container">
                                            <div className="screenshot_image">
                                                {iframeSrc ? (
                                                    <iframe
                                                        src={iframeSrc}
                                                        ref={iframeRef}
                                                        className="webpage_iframe"
                                                        title="Webpage Iframe"
                                                        style={{ border: "none", width: "100%", height: "100%" }}
                                                        sandbox="allow-scripts allow-same-origin allow-forms allow-popups"
                                                        onLoad={handleIframeLoad}

                                                    />
                                                ) : loadingScreenshot ? (
                                                    <div className="loading_screenshot_container">
                                                        <CircularProgress color="primary" size={60} />
                                                        <div className="loading_screenshot_message">
                                                            Please wait while we load the screenshot...
                                                        </div>
                                                    </div>

                                                ) : fetched_screenshot ? (
                                                    <img
                                                        src={fetched_screenshot}
                                                        alt="Screenshot of the webpage"
                                                        className="webpage_screenshot"
                                                    />
                                                ) : is_anchor_tag_visible ? (
                                                    <a
                                                        href={anchor_tag_url}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        className="webpage_link"
                                                    >
                                                        Open webpage in a new tab
                                                    </a>
                                                ) : null}
                                            </div>



                                        </div>
                                    </div>
                                    <div className="content_container">
                                        {showKeywordsSearch ? (
                                            <KeywordsSearch
                                                selectedItem={selectedItem}
                                                screenshotData={screenshotData}
                                                goBack={() => setShowKeywordsSearch(false)}
                                            />
                                        ) : (
                                            <div>
                                                <div className="matches_section">
                                                    {topMatches && topMatches.length > 0 ? (
                                                        <div>
                                                            <div className="top_match_section">
                                                                <div className="section_title">Top Match</div>
                                                                {currentPageTopMatch ? (
                                                                    <div
                                                                        key={currentPageTopMatch.client_url} // Using unique property as key
                                                                        className={`match_item ${selectedItem.id === currentPageTopMatch.id ? 'quick_verify_selected_item' : ''}`}
                                                                        onClick={() => handleSelectItem(currentPageTopMatch)}
                                                                    >
                                                                        {currentPageTopMatch.status === "Yes(Confirmed)" && (
                                                                            <div className="url_label_title_confirmed_container">
                                                                                <div>Confirmed</div>
                                                                            </div>
                                                                        )}
                                                                        {currentPageTopMatch.status === "No(Confirmed)" && (
                                                                            <div className="url_label_title_rejected_container">
                                                                                <div>Rejected</div>

                                                                            </div>
                                                                        )}
                                                                        <div className="url_label">
                                                                            Target URL:
                                                                        </div>

                                                                        <div className="url_value">{currentPageTopMatch.client_url}</div>
                                                                        <div className="url_label">Prospect URL:</div>
                                                                        <div className="url_value">{currentPageTopMatch.blog_url}</div>
                                                                        <div className="keywords_content_container">
                                                                            <div className="keywords_container_header">🔍 Keywords</div>
                                                                            <div className="keywords_container">
                                                                                {currentPageTopMatch.keywords &&
                                                                                    currentPageTopMatch.keywords.every(
                                                                                        (keyword) => Object.values(keyword)[1] === 0
                                                                                    ) ? (
                                                                                    <div className="no_keywords_message">❌ No Keyword Matching Found</div>
                                                                                ) : (
                                                                                    currentPageTopMatch.keywords.map(
                                                                                        (keyword, index) =>
                                                                                            Object.values(keyword)[1] > 0 && (
                                                                                                <div key={index} className="match_button">
                                                                                                    {`${Object.values(keyword)[0]} (${Object.values(keyword)[1]})`}
                                                                                                </div>
                                                                                            )
                                                                                    )
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                        <div className="page_type_and_similarity_section_container">
                                                                            <div className="similarity_section">
                                                                                <span className={`similarity_label`}>Similarity:</span>
                                                                                <span
                                                                                    className={`similarity_score ${getTextColorClass(
                                                                                        currentPageTopMatch.similarity_score
                                                                                    )}`}
                                                                                >
                                                                                    {currentPageTopMatch.similarity_score}
                                                                                </span>
                                                                                {renderSimilarityBar(currentPageTopMatch.similarity_score)}
                                                                            </div>
                                                                            <div className="page_type_container">
                                                                                <div className="page_type_label">Page Type:</div>
                                                                                <div className="page_type_value">
                                                                                    {currentPageTopMatch.page_type}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ) : (
                                                                    <p>No matches found for this page.</p>
                                                                )}
                                                            </div>


                                                            {number_of_alternative_links > 0 && (
                                                                <div className="other_matches_section">
                                                                    <div className="section_title">More Options</div>
                                                                    <div className="items_container">
                                                                        {Object.entries(otherItems).map(([domain, items]) => (
                                                                            <div key={domain} className="other_item_container">

                                                                                {items.map((item) => (
                                                                                    <div
                                                                                        key={item.id}
                                                                                        className={`match_item ${selectedItem.id === item.id ? 'quick_verify_selected_item' : ''}`}
                                                                                        onClick={() => handleSelectItem(item)}
                                                                                    >
                                                                                        {item.status === "Yes(Confirmed)" && (
                                                                                            <div className="url_label_title_confirmed_container">
                                                                                                <div>Confirmed</div>
                                                                                                <div>
                                                                                                    {/* <FaCheckCircle style={{ marginLeft: "8px", cursor: "pointer" }} /> */}
                                                                                                </div>
                                                                                            </div>
                                                                                        )}
                                                                                        {item.status === "No(Confirmed)" && (
                                                                                            <div className="url_label_title_rejected_container">
                                                                                                <div>Rejected</div>
                                                                                                {/* <div>
                                                                                                <MdDoNotDisturbAlt style={{ marginLeft: "8px", cursor: "pointer" }} />
                                                                                            </div> */}
                                                                                            </div>
                                                                                        )}

                                                                                        <div className="url_label">
                                                                                            Target URL:

                                                                                        </div>

                                                                                        <div className="url_value">
                                                                                            {item.client_url}
                                                                                        </div>
                                                                                        <div className="url_label">
                                                                                            Prospect URL:
                                                                                        </div>
                                                                                        <div className="url_value">
                                                                                            {item.blog_url}
                                                                                        </div>
                                                                                        <div className="keywords_content_container">
                                                                                            <div className="keywords_container_header">
                                                                                                🔍 Keywords
                                                                                            </div>
                                                                                            <div className="keywords_container">
                                                                                                {item.keywords &&
                                                                                                    item.keywords.every(
                                                                                                        (keyword) =>
                                                                                                            Object.values(
                                                                                                                keyword
                                                                                                            )[1] === 0
                                                                                                    ) ? (
                                                                                                    <div className="no_keywords_message">
                                                                                                        ❌ No Keyword Matching
                                                                                                        Found
                                                                                                    </div>
                                                                                                ) : (
                                                                                                    item.keywords.map(
                                                                                                        (keyword, index) =>
                                                                                                            Object.values(
                                                                                                                keyword
                                                                                                            )[1] > 0 && (
                                                                                                                <div
                                                                                                                    key={index}
                                                                                                                    className="match_button"
                                                                                                                >
                                                                                                                    {`${Object.values(
                                                                                                                        keyword
                                                                                                                    )[0]} (${Object.values(
                                                                                                                        keyword
                                                                                                                    )[1]})`}
                                                                                                                </div>
                                                                                                            )
                                                                                                    )
                                                                                                )}
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="page_type_and_similarity_section_container">
                                                                                            <div className="similarity_section">
                                                                                                <span
                                                                                                    className={`similarity_label`}
                                                                                                >
                                                                                                    Similarity:
                                                                                                </span>
                                                                                                <span
                                                                                                    className={`similarity_score ${getTextColorClass(
                                                                                                        item.similarity_score
                                                                                                    )}`}
                                                                                                >
                                                                                                    {item.similarity_score}
                                                                                                </span>
                                                                                                {renderSimilarityBar(
                                                                                                    item.similarity_score
                                                                                                )}
                                                                                            </div>
                                                                                            <div className="page_type_container">
                                                                                                <div className="page_type_label">
                                                                                                    Page Type:
                                                                                                </div>
                                                                                                <div className="page_type_value">
                                                                                                    {item.page_type}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                ))}
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    ) : (
                                                        <div className="no_match_message">No Top Match Found</div>
                                                    )}
                                                </div>

                                                {number_of_alternative_links > 0 && (
                                                    <div className="find_matches_button" onClick={handleFindOwnMatches}>
                                                        Find Own Matches
                                                    </div>
                                                )}


                                            </div>

                                        )}


                                    </div>
                                    <Snackbar
                                        open={snackbarOpen}
                                        autoHideDuration={3000}
                                        onClose={closeSnackbar}
                                        message={snackbarMessage}
                                    />

                                </div>
                            </>
                        ) : (
                            <>
                                <div
                                >
                                    <CardContent>
                                        {/* Sticker Icon */}
                                        <InfoIcon
                                            sx={{ fontSize: "50px",  marginBottom: "10px" }}
                                        />

                                        {/* Title */}
                                        <Typography
                                            variant="h6"
                                            sx={{ fontWeight: "bold" }}
                                        >
                                            No Data Available
                                        </Typography>

                                        {/* Subtitle */}
                                        <Typography
                                            variant="body2"
                                            sx={{marginTop: "8px" }}
                                        >
                                            We couldn't find any data at the moment.
                                        </Typography>
                                    </CardContent>
                                </div>
                            </>
                        )


                    )}
                </div>
            </div >

        </div >
    );
};

export default QuickVarifyScreenshotPage;
