import AdminSideBar from "../common/AdminSideBar/AdminSideBar";

import React, { useState, useEffect } from "react";
import TableWithPagination from "../../../utils/TableWithPagination/TableWithPagination";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import {
  getFullApiUrl,
  ADD_NEW_WORKSPACE,
  GET_ALL_WORKSPACE_BY_TEAM,
  DELETE_WORKSPACE,
  GET_ALL_WORKSPACE,
  UPDATE_WORKSPACE,
  UPDATE_USER_OF_WORKSPACE,
  GET_ALL_USERS,
  GET_ALL_TEAM,
  UPDATE_TEAM_OF_WORKSPACE,
  TEAM_NAME_FROM_ID,
} from  "../../../utils/apiPath"
import { RiAddLine, RiDeleteBack2Fill } from "react-icons/ri";
import { MdEdit, MdDelete, MdOutlineDeleteSweep } from "react-icons/md";
import Modal from "@mui/material/Modal";
import MenuItem from "@mui/material/MenuItem";

import {
  Box,
  Typography,
  TextField,
  Button,
  useTheme,
  Tooltip,
  IconButton,
  Autocomplete,
  Breadcrumbs,
  Link,
} from "@mui/material";
import { IoMdCloseCircle, IoIosAddCircleOutline } from "react-icons/io";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditNoteIcon from "@mui/icons-material/";
import { LiaEdit } from "react-icons/lia";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import { IoMdOpen } from "react-icons/io";

import {  NavigateNext } from "@mui/icons-material";
import Header from "../../common/Header/Header";

const Team_Workspace = ({ toggleTheme }) => {
  const theme = useTheme();
  const { teamId } = useParams();
  const [workspace_data, set_workspace_data] = useState([]);
  const [selectedworkspacesForDelete, setselectedworkspacesForDelete] =
    useState([]);
  const [selected_workspace, set_selected_workspace] = useState([]); // State to store selected workspaces for deletion
  const [showDeleteworkspaceModal, setshowDeleteworkspaceModal] =
    useState(false);
  const [selectedworkspacesForBulkDelete, setselectedworkspacesForBulkDelete] =
    useState([]);

  // State to store the selected workspace for deletion
  const [user_data, set_user_data] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [searchTerm, setSearchTerm] = useState(""); // State to store the search term
  const [searchResults, setSearchResults] = useState([]);
  const [selectedworkspace, setselectedworkspace] = useState({
    workspace_id: "",
    workspace_name: "",
   
    start_date: "",
    deadline: "",
    status: "",
    user_id: "",
    team_id: teamId,
  });
  const [newworkspaceData, setnewworkspaceData] = useState({
    workspace_name: "",
   
    start_date: "",
    deadline: "",
    status: "",
    user_id: "",
    team_id: teamId,
  });
  const [newworkspaceDataToUpdate, setnewworkspaceDataToUpdate] = useState({
    workspace_name: "",
    
    start_date: "",
    deadline: "",
    status: "",
    user_id: "",
    team_id: teamId,
  });

  const [new_user_for_workspace, set_new_user_for_workspace] = useState({
    workspace_id: "",
    user_id: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setselectedworkspace({ ...selectedworkspace, [name]: value });
  };
  const handleNewInputChange = (e) => {
    const { name, value } = e.target;
    setnewworkspaceData({ ...newworkspaceData, [name]: value });
  };
  const handleNewInputChangeForUser = (e) => {
    const { name, value } = e.target;
    set_new_user_for_workspace({ ...new_user_for_workspace, [name]: value });
  };

  const handleAddworkspaceSubmit = async () => {
    console.log(newworkspaceData);

    try {
      const response = await fetch(getFullApiUrl(ADD_NEW_WORKSPACE), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newworkspaceData),
      });

      if (!response.ok) {
        throw new Error("Failed to add new workspace");
      }
      handleAddClose();

      setnewworkspaceData({
        workspace_name: "",
       
        start_date: "",
        deadline: "",
        status: "",
        user_id: "",
        team_id: teamId,
      });
      fetchData();

      console.log("New workspace added successfully");
    } catch (error) {
      console.error("Error adding new workspace:", error.message);
    }
  };

  const fetchData = async () => {
    const teams = await fetchTeamList();
    const users = await fetchUserList();
    await fetchworkspaceList(teamId, users, teams);
  };

  useEffect(() => {
    fetchteamName();
    fetchData();
  }, [1000]);

  const fetchworkspaceList = async (team_id, users, teams) => {
    try {
      const response = await fetch(
        `${getFullApiUrl(GET_ALL_WORKSPACE_BY_TEAM)}?team_id=${team_id}`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const workspaceListData = await response.json();
      const updatedWorkspaceListData = await workspaceListData.map(
        (workspaceRow) => {
          const userWorkspace = users.find(
            (u) => u.user_id === workspaceRow.user_id
          );
          if (userWorkspace) {
            workspaceRow.user_name = userWorkspace.user_name;
          }
          const teamWorkspace = teams.find(
            (t) => t.team_id === workspaceRow.team_id
          );
          if (teamWorkspace) {
            workspaceRow.team_name = teamWorkspace.team_name;
          }
          return workspaceRow;
        }
      );

      /*
			const updatedUserData = await usersListData.map(userRow => {
			const userSubscription = users.find(sub => sub.user_id === userRow.user_id);
			if (userSubscription) {
			userRow.user_name = userSubscription.user_name;
			}
			return userRow;
			});

			*/
      set_workspace_data(workspaceListData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleIndividualDeleteClick = (workspace) => {
    setselectedworkspacesForDelete([workspace]); // Select only the clicked workspace
    setshowDeleteworkspaceModal(true);
  };

  const handleBulkworkspaceDeleteClick = async (params) => {
    if (!params || params.length === 0 || !Array.isArray(params)) {
      console.error("Invalid or empty params:", params);
      return;
    }

    try {
      const response = await fetch(`${getFullApiUrl(GET_ALL_WORKSPACE)}`);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const allworkspaces = await response.json();

      const workspaceDetails = params.map((workspaceId) => {
        const workspaces = allworkspaces.find(
          (u) => u.workspace_id === workspaceId
        );
        console.log("ss", workspaces);
        return workspaces
          ? `${workspaces.workspace_name} (${workspaces.description})`
          : "";
      });
      console.log("detail", workspaceDetails);

      setselectedworkspacesForBulkDelete(params);
      setselectedworkspacesForDelete(workspaceDetails);
      setShowBulkDeleteModal(true);
    } catch (error) {
      console.error("Error fetching workspace data:", error);
    }
  };

  const handleBulkDeleteConfirm = async () => {
    // console.log(selectedworkspacesForBulkDelete)
    try {
      if (
        !selectedworkspacesForBulkDelete ||
        !Array.isArray(selectedworkspacesForBulkDelete)
      ) {
        console.error(
          "Invalid or empty params:",
          selectedworkspacesForBulkDelete
        );
        return;
      } else {
        await handleBulkDelete(selectedworkspacesForBulkDelete);
        // Close modal and refresh workspace list
        setShowBulkDeleteModal(false);
        fetchData();
      }
    } catch (error) {
      console.error("Error deleting workspaces in bulk:", error.message);
    }
  };
  const handleDeleteworkspaceConfirm = async () => {
    try {
      await Promise.all(
        selectedworkspacesForDelete.map((workspace) =>
          handleDeleteworkspace(workspace.workspace_id)
        )
      );
      setselectedworkspacesForDelete([]);
      setshowDeleteworkspaceModal(false);
      fetchData(); // Refresh workspace list after deletion
    } catch (error) {
      console.error("Error deleting workspaces:", error.message);
    }
  };
  const handleBulkDelete = async (selected_workspace) => {
    set_selected_workspace(selected_workspace);
    try {
      // Iterate over selected workspaces and delete each one
      for (let workspaceId of selected_workspace) {
        await handleDeleteworkspace(workspaceId);
      }
      console.log("Bulk delete successful");
      // After bulk deletion, refresh the workspace list
      fetchData();
    } catch (error) {
      console.error("Error deleting workspaces in bulk:", error.message);
    }
  };

  const handleDeleteworkspace = async (workspaceId) => {
    console.log("delete click");
    console.log(workspaceId);
    try {
      const response = await fetch(getFullApiUrl(DELETE_WORKSPACE), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ workspace_id: workspaceId }),
      });

      if (!response.ok) {
        throw new Error("Failed to delete workspace");
      }

      console.log("workspace deleted successfully:", workspaceId);
      // Refresh workspace list after deletion
      fetchData();
    } catch (error) {
      console.error("Error deleting workspace:", error.message);
    }
  };
  const handleEditworkspace = (workspace) => {
    setselectedworkspace(workspace);
    setShowEditworkspaceModal(true);
  };
  const handleEditUser = (workspace) => {
    set_new_user_for_workspace({
      workspace_id: workspace.workspace_id,
      user_id: workspace.user_id,
    });
    setShowUserSearchModal(true);
  };
  const handleUserSearchCancel = () => {
    setSelectedUser(null);
  };

  const handleEditworkspaceData = async () => {
    try {
      const response = await fetch(getFullApiUrl(UPDATE_WORKSPACE), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(selectedworkspace),
      });

      if (!response.ok) {
        throw new Error("Failed to edit workspace");
      }

      console.log("workspace edited successfully");
      setSelectedUser(null);

      setShowEditworkspaceModal(false);
      // Refresh workspace list after editing
      fetchData();
    } catch (error) {
      console.error("Error editing workspace:", error.message);
    }
  };

  const fetchUserList = async () => {
    try {
      const response = await fetch(getFullApiUrl(GET_ALL_USERS));
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      set_user_data(data);
      return data;
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSearchInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    if (!searchTerm) {
      setSearchResults([]);
      return;
    }

    const filteredUsers = user_data.filter((user) =>
      user.user_name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setSearchResults(filteredUsers);
  }, [searchTerm, user_data]);

  const handleEditUserForWorkspace = async () => {
    try {
      const response = await fetch(getFullApiUrl(UPDATE_USER_OF_WORKSPACE), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          workspace_id: new_user_for_workspace.workspace_id,
          user_id: new_user_for_workspace.user_id,
        }),
      });
      console.log(new_user_for_workspace);

      if (!response.ok) {
        throw new Error("Failed to update user for the workspace");
      }

      // console.log("User for the workspace updated successfully");
      setShowUserSearchModal(false);
      // Refresh workspace list after updating user for the workspace
      fetchData();
    } catch (error) {
      console.error("Error updating user for the workspace:", error.message);
    }
  };
  //model open

  const [showAddworkspaceForm, setShowAddworkspaceForm] = React.useState(false);
  const [showEditworkspaceModal, setShowEditworkspaceModal] =
    React.useState(false);
  const [showBulkDeleteModal, setShowBulkDeleteModal] = React.useState(false);
  const [showUserSeachModal, setShowUserSearchModal] = React.useState(false);
  const handleAddOpen = () => setShowAddworkspaceForm(true);
  const handleAddClose = () => setShowAddworkspaceForm(false);
  const handleEditOpen = () => setShowEditworkspaceModal(true);
  const handleEditClose = () => setShowEditworkspaceModal(false);
  const handleBulkDeleteOpen = () => setShowBulkDeleteModal(true);
  const handleBulkDeleteClose = () => setShowBulkDeleteModal(false);
  const handleUserSearchClose = () => {
    setShowUserSearchModal(false);
    setSelectedUser(null);
  };

  const handleUserSelect = (user) => {
    // Copy user_id from selectedUser to new_user_for_workspace
    set_new_user_for_workspace({
      ...new_user_for_workspace,
      user_id: user.user_id,
    });
    // Set the selected user
    setSelectedUser(user);
  };
  const handleNewUserSelect = (user) => {
    // Copy user_id from selectedUser to newworkspaceData
    setnewworkspaceData({
      ...newworkspaceData,
      user_id: user.user_id,
    });
    // Set the selected user
    setSelectedUser(user);
  };

  const handleDeleteworkspaceModalClose = () => {
    setshowDeleteworkspaceModal(false);
  };

  // search team......

  const [selectedTeam, setSelectedTeam] = useState(null);
  const [selectedTeamToUpdate, setSelectedTeamToUpdate] = useState(null);
  const [team_data, set_team_data] = useState([]);
  const [team_data_for_update, set_team_data_for_update] = useState([]);
  const [searchTermteam, setSearchTermteam] = useState("");
  const [searchTermteamToUpdate, setSearchTermteamToUpdate] = useState("");
  const [searchTeamResults, setSearchTeamResults] = useState([]);
  const [searchTeamResultsToUpdate, setSearchTeamResultsToUpdate] = useState(
    []
  );

  const fetchTeamList = async () => {
    try {
      const response = await fetch(getFullApiUrl(GET_ALL_TEAM));
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const teamData = await response.json();
      set_team_data(teamData);
      set_team_data_for_update(teamData);
      return teamData;
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSearchTeamInputChange = (e) => {
    setSearchTermteam(e.target.value);
  };
  const handleSearchTeamInputChangeToUpdate = (e) => {
    setSearchTermteamToUpdate(e.target.value);
  };

  useEffect(() => {
    if (!searchTermteam) {
      setSearchTeamResults([]);
      return;
    }

    const filteredTeams = team_data.filter((team) =>
      team.team_name.toLowerCase().includes(searchTermteam.toLowerCase())
    );
    setSearchTeamResults(filteredTeams);
  }, [searchTermteam, team_data]);
  useEffect(() => {
    if (!searchTermteamToUpdate) {
      setSearchTeamResultsToUpdate([]);
      return;
    }

    const filteredTeams = team_data_for_update.filter((team) =>
      team.team_name
        .toLowerCase()
        .includes(searchTermteamToUpdate.toLowerCase())
    );
    setSearchTeamResultsToUpdate(filteredTeams);
  }, [searchTermteamToUpdate, team_data_for_update]);

  const handleTeamSelect = (team) => {
    // Copy user_id from selectedUser to new_user_for_workspace
    setnewworkspaceData({
      ...newworkspaceData,
      team_id: team.team_id,
    });
    // Set the selected user
    setSelectedTeam(team);
  };
  const handleteamSelectToUpdate = (team) => {
    // Copy user_id from selectedUser to new_user_for_workspace
    setnewworkspaceDataToUpdate({
      ...newworkspaceDataToUpdate,
      team_id: team.team_id,
    });
    // Set the selected user
    setSelectedTeamToUpdate(team);
  };

  // update team....
  const [showTeamSeachModal, setShowTeamSearchModal] = React.useState(false);

  const handleteamSearchClose = () => {
    setShowTeamSearchModal(false);
    setSelectedTeam(null);
  };
  const [new_team_for_workspace, set_new_team_for_workspace] = useState({
    workspace_id: "",
    team_id: "",
  });
  const [
    new_team_for_workspace_to_update,
    set_new_team_for_workspace_to_update,
  ] = useState({
    workspace_id: "",
    team_id: "",
  });
  const handleEditTeam = (workspace) => {
    set_new_team_for_workspace_to_update({
      workspace_id: workspace.workspace_id,
      team_id: workspace.team_id,
    });
    setShowTeamSearchModal(true);
  };
  const handleteamSelect = (user) => {
    // Copy user_id from selectedUser to new_user_for_workspace
    set_new_team_for_workspace({
      ...new_team_for_workspace,
      team_id: user.team_id,
    });
  };
  const handleTeamSearchCancel = () => {
    setSelectedTeam(null);
  };

  const handleEditTeamForWorkspace = async () => {
    try {
      const response = await fetch(getFullApiUrl(UPDATE_TEAM_OF_WORKSPACE), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          workspace_id: new_team_for_workspace_to_update.workspace_id,
          team_id: new_team_for_workspace_to_update.team_id,
        }),
      });
      console.log(new_team_for_workspace_to_update);

      if (!response.ok) {
        throw new Error("Failed to update user for the workspace");
      }

      // console.log("User for the workspace updated successfully");
      setShowTeamSearchModal(false);
      // Refresh workspace list after updating user for the workspace
      fetchData();
    } catch (error) {
      console.error("Error updating user for the workspace:", error.message);
    }
  };
  // const [workspaceID, setWorkspaceID] = useState(null);
  // const [teamID, setTeamID] = useState(null);
  // const navigate = useNavigate();
  // const handleOpenTeamSection = (workspace) => {
  //   const workspace_id = workspace.workspace_id;
  //   const team_id = workspace.team_id;
  //   // Set the teamId state
  //   setWorkspaceID(workspace_id);
  //   setTeamID(team_id);
  //   console.log(workspaceID);
  //   console.log(teamID);

  //   // Navigate to the MyTeam page
  //   navigate(`/my_workspace/${workspace_id}`);
  // };
  const [team_name, set_team_name] = useState([]);
  const fetchteamName = async () => {
    try {
      const response = await fetch(
        `${getFullApiUrl(TEAM_NAME_FROM_ID)}?team_id=${teamId}`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const teamData = await response.json();
      set_team_name(teamData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <div className="page_Section">
    <div className="leftSection_withMenu">
      <AdminSideBar />
    </div>
    <div className="rightSection_withMenu">
    <div className="headerSection_withMenu">
          <Header onToggleTheme={toggleTheme} />
        </div>
        <div className="bodySection">
          <Breadcrumbs id="Breadcrumbs" separator={<NavigateNext />} aria-label="breadcrumb">
            <Link
              component={RouterLink}
              to="/admin"
              color="inherit"
              underline="hover"
            >
              Admin
            </Link>
            <Link
              component={RouterLink}
              to="/admin/teams"
              color="inherit"
              underline="hover"
            >
               Teams
            </Link>
            <Typography color="textPrimary">{team_name} Workspace</Typography>
          </Breadcrumbs>
          <div className="admin_page_heading"></div>
          <div className="admin_page_heading"> Workspace Management</div>
          <TableWithPagination
            data={workspace_data}
            key={workspace_data.length}

            fields={[
              {
                type: "id",
                field: "workspace_id",
                headerName: "ID",
                minWidth: 1,
                width: 1,
                maxWidth: 260,
              },
              {
                type: "field",
                field: "workspace_name",
                headerName: "Workspace",
                search_type: "text_search",
                minWidth: 180,
                width: 200,
                maxWidth: 250,
              },
              {
                type: "field",
                field: "workspace_description",
                headerName: "Workspace Description",
                search_type: "text_search",
                minWidth: 180,
                width: 200,
                maxWidth: 250,
              },
              {
                type: "field",
                field: "start_date",
                headerName: "Start Date",
                search_type: "text_search",
                minWidth: 150,
                width: 150,
                maxWidth: 160,
              },
              {
                type: "field",
                field: "deadline",
                headerName: "Deadline",
                search_type: "text_search",
                minWidth: 150,
                width: 150,
                maxWidth: 160,
              },
              {
                type: "field",
                field: "status",
                search_type: "text_search",
                headerName: "status",
                minWidth: 120,
                width: 130,
                maxWidth: 160,
              },
              {
                type: "field",
                field: "user_name",
                search_type: "text_search",
                headerName: "Owner",
                minWidth: 140,
                width: 160,
                maxWidth: 180,
              },

              {
                type: "action",
                field: "actions",
                headerName: "Actions",
                minWidth: 110,
                width: 150,
                maxWidth: 150,
                renderCell: (params) => {
                  return (
                    <div className="action_icons_">
                      <Tooltip title="Edit">
                        <IconButton onClick={() => handleEditworkspace(params)}>
                          <LiaEdit className="table_action_icon" />
                        </IconButton>
                      </Tooltip>

                      <Tooltip title="Delete">
                        <IconButton
                          onClick={() => handleIndividualDeleteClick(params)}
                        >
                          <DeleteOutlineIcon className="table_action_icon" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Update Owner">
                        <IconButton onClick={() => handleEditUser(params)}>
                          <PersonSearchIcon className="table_action_icon" />
                        </IconButton>
                      </Tooltip>
                      {/* <Tooltip title="Open">
                        <IconButton
                          onClick={() => handleOpenTeamSection(params)}
                        >
                          <IoMdOpen className="table_action_icon" />
                        </IconButton>
                      </Tooltip> */}
                    </div>
                  );
                },
              },
            ]}
            bulk_actions={[
              {
                action: "add",
                renderCell: (params) => {
                  return (
                    <Tooltip title="Add Workspace">
                      <div
                        className="bulk_classify_button download_icons"
                        onClick={handleAddOpen}
                      >
                        <IoIosAddCircleOutline />
                      </div>
                    </Tooltip>
                  );
                },
              },
              {
                action: "delete_multiple",
                renderCell: (params) => {
                  return (
                    <Tooltip title="Delete Selected Workspaces">
                      <div
                        onClick={() => handleBulkworkspaceDeleteClick(params)}
                        className="bulk_delete_button download_icons"
                      >
                        <MdOutlineDeleteSweep fontSize={30}/>
                      </div>
                    </Tooltip>
                  );
                },
              },
            ]}
          />
          {/* {workspaceID && <Team_Workspace workspaceId={workspaceID} />} */}
          <Modal
            open={showAddworkspaceForm}
            onClose={handleAddClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box id="model_box">
              <div className="add-user-form">
                <div className="modal_header">Add New workspace Name</div>
                <Box
                  component="form"
                  sx={{
                    "& > :not(style)": {
                      m: 2,
                      width: "80%",
                      marginLeft: "10%",
                    },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <TextField
                    id="workspace_name"
                    label="workspace Name"
                    variant="standard"
                    name="workspace_name"
                    value={newworkspaceData.workspace_name}
                    onChange={handleNewInputChange}
                    className="modal_form_input"
                  />
                </Box>
                <Box
                  component="form"
                  sx={{
                    "& > :not(style)": {
                      m: 2,
                      width: "80%",
                      marginLeft: "10%",
                    },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <TextField
                    id="workspace_description"
                    label="Workspace Description "
                    variant="standard"
                    name="workspace_description"
                    value={newworkspaceData.workspace_description}
                    onChange={handleNewInputChange}
                    className="modal_form_input"
                    multiline
                    rows={3}
                  />
                </Box>

               

                <Box
                  component="form"
                  sx={{
                    "& > :not(style)": {
                      m: 2,
                      width: "80%",
                      marginLeft: "10%",
                    },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <TextField
                    id="start_date"
                    type="date"
                    helperText="Select Start Date"
                    variant="standard"
                    name="start_date"
                    value={newworkspaceData.start_date}
                    onChange={handleNewInputChange}
                    className="modal_form_input"
                  />
                </Box>
                <Box
                  component="form"
                  sx={{
                    "& > :not(style)": {
                      m: 2,
                      width: "80%",
                      marginLeft: "10%",
                    },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <TextField
                    id="deadline"
                    type="date"
                    helperText="Select Deadline Date"
                    variant="standard"
                    name="deadline"
                    value={newworkspaceData.deadline}
                    onChange={handleNewInputChange}
                    className="modal_form_input"
                  />
                </Box>
                <Box
                  component="form"
                  sx={{
                    "& > :not(style)": {
                      m: 2,
                      width: "80%",
                      marginLeft: "10%",
                    },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <TextField
                    id="status"
                    select
                    label="Status"
                    helperText="Select Your Status"
                    variant="standard"
                    name="status"
                    value={newworkspaceData.status}
                    onChange={handleNewInputChange}
                    className="modal_form_input"
                  >
                     <MenuItem value="Not Started"> Not Started</MenuItem>
                      <MenuItem value="Progress"> Progress</MenuItem>
                      <MenuItem value="Done"> Done</MenuItem>
                  </TextField>
                </Box>
                <Box
                  component="form"
                  sx={{
                    "& > :not(style)": {
                      m: 2,
                      width: "80%",
                      marginLeft: "10%",
                    },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <Autocomplete
                    id="search_user"
                    options={searchResults}
                    getOptionLabel={(option) => `${option.user_name}`}
                    onChange={(event, newValue) =>
                      handleNewUserSelect(newValue)
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Search Owner"
                        variant="standard"
                        onChange={handleSearchInputChange}
                        className="modal_form_input"
                      />
                    )}
                  />
                </Box>
                {selectedUser && (
                  <div className="modal_text">
                    <Typography>
                      Selected Owner:
                      {selectedUser.user_name}
                    </Typography>

                    {/* Use the selected user's ID */}
                    <TextField
                      id="user_id"
                      variant="standard"
                      name="user_id"
                      value={newworkspaceData.user_id}
                      disabled={!newworkspaceData.user_id}
                      //   value={selectedUser.user_id} // Display selected user's ID
                      //  disabled={!selectedUser.user_id}
                      className="modal_form_input"
                    />
                  </div>
                )}

                <button
                  onClick={handleAddworkspaceSubmit}
                  className="add-user-btn"
                >
                  Add workspace{" "}
                </button>
              </div>
            </Box>
          </Modal>

          <Modal
            open={showEditworkspaceModal}
            onClose={handleEditClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box id="model_box">
              <div className="add-user-form">
                <div className="modal_header">Update workspace</div>
                <Box
                  component="form"
                  sx={{
                    "& > :not(style)": {
                      m: 2,
                      width: "80%",
                      marginLeft: "10%",
                    },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <TextField
                    id="workspace_name"
                    label="workspace Name"
                    variant="standard"
                    name="workspace_name"
                    value={
                      selectedworkspace ? selectedworkspace.workspace_name : ""
                    }
                    onChange={handleInputChange}
                    className="modal_form_input"
                  />
                </Box>

                <Box
                  component="form"
                  sx={{
                    "& > :not(style)": {
                      m: 2,
                      width: "80%",
                      marginLeft: "10%",
                    },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <TextField
                    id="workspace_description"
                    label="Workspace Description"
                    variant="standard"
                    name="workspace_description"
                    value={
                      selectedworkspace ? selectedworkspace.workspace_description : ""
                    }
                    onChange={handleInputChange}
                    className="modal_form_input"
                    multiline
                    rows={3}
                  />
                </Box>

                <Box
                  component="form"
                  sx={{
                    "& > :not(style)": {
                      m: 2,
                      width: "80%",
                      marginLeft: "10%",
                    },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <TextField
                    id="start_date"
                    type="date"
                    helperTex="Select Start Date"
                    variant="standard"
                    name="start_date"
                    value={
                      selectedworkspace ? selectedworkspace.start_date : ""
                    }
                    onChange={handleInputChange}
                    className="modal_form_input"
                  />
                </Box>
                <Box
                  component="form"
                  sx={{
                    "& > :not(style)": {
                      m: 2,
                      width: "80%",
                      marginLeft: "10%",
                    },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <TextField
                    id="deadline"
                    type="date"
                    helperTex="Select Deadline Date"
                    variant="standard"
                    name="deadline"
                    value={selectedworkspace ? selectedworkspace.deadline : ""}
                    onChange={handleInputChange}
                    className="modal_form_input"
                  />
                </Box>

                <Box
                  component="form"
                  sx={{
                    "& > :not(style)": {
                      m: 2,
                      width: "80%",
                      marginLeft: "10%",
                    },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <TextField
                    id="status"
                    select
                    helperText="Select Your Status"
                    label="Status"
                    variant="standard"
                    name="status"
                    value={selectedworkspace ? selectedworkspace.status : ""}
                    onChange={handleInputChange}
                    className="modal_form_input"
                  >
                      <MenuItem value="Not Started"> Not Started</MenuItem>
                      <MenuItem value="Progress"> Progress</MenuItem>
                      <MenuItem value="Done"> Done</MenuItem>
                  </TextField>
                </Box>
                <Box
                  component="form"
                  sx={{
                    "& > :not(style)": {
                      m: 2,
                      width: "80%",
                      marginLeft: "10%",
                    },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <TextField
                    id="user_id"
                    label="User Id"
                    variant="standard"
                    name="user_id"
                    value={selectedworkspace ? selectedworkspace.user_id : ""}
                    onChange={handleInputChange}
                    className="modal_form_input"
                  />
                </Box>
                <button
                  onClick={handleEditworkspaceData}
                  className="add-user-btn"
                >
                  Update workspace
                </button>
              </div>
            </Box>
          </Modal>

          <Modal
            open={showDeleteworkspaceModal}
            onClose={handleDeleteworkspaceModalClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box id="model_box">
              <div className="delete-user-form">
                <div className="modal_header">Delete workspace(s)</div>
                {selectedworkspacesForDelete.map((workspace) => (
                  <div key={workspace.workspace_id}>
                    <div className="modal_text">
                      workspace: {workspace.workspace_name} (
                      {workspace.duration})
                    </div>
                  </div>
                ))}
                <div className="modal_text">
                  Are you sure you want to delete selected workspace(s)?
                </div>
                <div className="form-group">
                  <Button onClick={handleDeleteworkspaceConfirm} color="error">
                    <MdDelete className="formIcons" />
                  </Button>
                  <Button onClick={handleDeleteworkspaceModalClose}>
                    <IoMdCloseCircle className="formIcons" />
                  </Button>
                </div>
              </div>
            </Box>
          </Modal>
          {/*Bulk Delete Workspaces Model */}
          <Modal
            open={showBulkDeleteModal}
            onClose={handleBulkDeleteClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box id="model_box">
              <div className="delete-user-form">
                <div className="modal_header">Delete workspace(s)</div>

                {selectedworkspacesForDelete.map((workspace) => (
                  <div key={workspace}>
                    <div className="modal_text">
                      workspace Name: {workspace}{" "}
                    </div>
                  </div>
                ))}
                <div className="modal_text">
                  Are you sure you want to delete selected workspace(s)?
                </div>
                <div className="form-group">
                  <Button onClick={handleBulkDeleteConfirm} color="error">
                    <MdDelete className="formIcons" />
                  </Button>
                  <Button onClick={handleBulkDeleteClose}>
                    <IoMdCloseCircle className="formIcons" />
                  </Button>
                </div>
              </div>
            </Box>
          </Modal>
          <Modal
            open={showUserSeachModal}
            onClose={handleUserSearchClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box id="model_box">
              <div className="delete-user-form">
                <div className="modal_header">Update Owner</div>
                <Box
                  component="form"
                  sx={{
                    "& > :not(style)": {
                      m: 2,
                      width: "80%",
                      marginLeft: "10%",
                    },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <Autocomplete
                    id="search_user"
                    options={searchResults}
                    getOptionLabel={(option) =>
                      `${option.user_name} - ${option.email_id}`
                    }
                    onChange={(event, newValue) => handleUserSelect(newValue)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Search Owner"
                        variant="standard"
                        onChange={handleSearchInputChange}
                        className="modal_form_input"
                      />
                    )}
                  />
                </Box>
                {/* Render search results */}
                {selectedUser && (
                  <div className="modal_text">
                    <Typography>
                      Selected User: {selectedUser.user_name} -{" "}
                      {selectedUser.email_id}
                    </Typography>

                    {/* Use the selected user's ID */}
                    <TextField
                      id="user_id"
                      variant="standard"
                      name="user_id"
                      value={
                        new_user_for_workspace
                          ? new_user_for_workspace.user_id
                          : ""
                      }
                      disabled={!new_user_for_workspace}
                      className="modal_form_input"
                    />
                    <div className="form-group">
                      {/* Button to confirm user selection */}
                      <Button
                        onClick={handleEditUserForWorkspace}
                        color="primary"
                      >
                        Confirm
                      </Button>
                      {/* Button to close the modal */}
                      <Button onClick={handleUserSearchCancel} color="error">
                        Cancel
                      </Button>
                    </div>
                  </div>
                )}
                <div className="form-group">
                  <Button onClick={handleUserSearchClose} color="error">
                    Close
                  </Button>
                </div>
              </div>
            </Box>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default Team_Workspace;
