import LeftSectionWithMenu from "../common/Header/LeftSectionWithMenu/LeftSectionWithMenu";
import Header from "../common/Header/Header";
import React, { useState, useEffect } from "react";
import TableWithPagination from "../../utils/TableWithPagination/TableWithPagination";
import {
  getFullApiUrl,
  ADD_NEW_WORKSPACE,
  GET_ALL_WORKSPACE_BY_TEAM,
  DELETE_WORKSPACE,
  GET_ALL_WORKSPACE,
  UPDATE_WORKSPACE,
  UPDATE_USER_OF_WORKSPACE,
  GET_ALL_USERS,
  GET_ALL_TEAM,
  UPDATE_TEAM_OF_WORKSPACE,
  TEAM_NAME_FROM_ID,
  GET_TEAM_MEMBER_DESIGNATION,
  GET_ALL_WORKSPACE_BY_TEAM_ID,
  GET_WORKSPACE_DETAILS,
  GET_TEAM_DETAILS,
  CHECK_TEAM_ACCESS,
} from "../../utils/apiPath";
import { RiAddLine, RiDeleteBack2Fill } from "react-icons/ri";
import {
  MdEdit,
  MdDelete,
  MdOutlineDeleteSweep,
  MdOutlineWorkOutline,
} from "react-icons/md";
import Modal from "@mui/material/Modal";
import {
  Box,
  Typography,
  TextField,
  Button,
  useTheme,
  Tooltip,
  IconButton,
  Autocomplete,
  Breadcrumbs,
  Link,
  MenuItem,
  Snackbar,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Alert,
  CircularProgress,
} from "@mui/material";
import { IoMdCloseCircle, IoIosAddCircleOutline } from "react-icons/io";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditNoteIcon from "@mui/icons-material/";
import { LiaEdit } from "react-icons/lia";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import { IoMdOpen } from "react-icons/io";
import MyWorkspace from "../MyWorkspaceCampaigns/MyWorkspaceCampaigns";
import { NavigateNext } from "@mui/icons-material";
import MyTeamWorkspaceCampaign from "../MyTeamWorkspaceCampaign/MyTeamWorkspaceCampaign";
import MyTeamWorkspaceMember from "../MyTeamWorkspaceMember/MyTeamWorkspaceMember";
import { MdPeopleAlt } from "react-icons/md";
import { MdCampaign } from "react-icons/md";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CloseIcon from "@mui/icons-material/Close";
import StatusModalComponent from "../../components/StatusModalComponent/StatusModalComponent";

const MyTeamWorkspace = ({ toggleTheme }) => {
  const theme = useTheme();
  const { teamId } = useParams();
  const [workspace_data, set_workspace_data] = useState([]);
  const [errorFetchMassage, setErrorFetchMassage] = useState(null);
  const [selectedworkspacesForDelete, setselectedworkspacesForDelete] =
    useState([]);
  const [selected_workspace, set_selected_workspace] = useState([]); // State to store selected workspaces for deletion
  const [showDeleteworkspaceModal, setshowDeleteworkspaceModal] =
    useState(false);
  const [selectedworkspacesForBulkDelete, setselectedworkspacesForBulkDelete] =
    useState([]);

  // State to store the selected workspace for deletion
  const [user_data, set_user_data] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [searchTerm, setSearchTerm] = useState(""); // State to store the search term
  const [searchResults, setSearchResults] = useState([]);
  const [selectedworkspace, setselectedworkspace] = useState({
    workspace_id: "",
    workspace_name: "",
    workspace_description: "",
    start_date: "",
    deadline: "",
    status: "",
    user_id: localStorage.getItem("user_id"),
    team_id: teamId,
  });
  const [newworkspaceData, setnewworkspaceData] = useState({
    workspace_name: "",
    workspace_description: "",
    start_date: "",
    deadline: "",
    status: "",
    user_id: localStorage.getItem("user_id"),
    team_id: teamId,
  });
  const [newworkspaceDataToUpdate, setnewworkspaceDataToUpdate] = useState({
    workspace_name: "",
    start_date: "",
    deadline: "",
    status: "",
    user_id: localStorage.getItem("user_id"),
    team_id: teamId,
  });

  const [new_user_for_workspace, set_new_user_for_workspace] = useState({
    workspace_id: "",
    user_id: localStorage.getItem("user_id"),
  });
  const [teamMemberDetails, setTeamMemberDetails] = useState({
    member_designation: "",
  });
  const [errorMessage, setErrorMessage] = useState("");

  const fetchTeamMemberDetails = async () => {
    try {
      const response = await fetch(
        `${getFullApiUrl(
          GET_TEAM_MEMBER_DESIGNATION
        )}?team_id=${teamId}&team_member_id=${localStorage.getItem("user_id")}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const teamData = await response.json();
      console.log("API Response:", teamData); // Log API response
      setTeamMemberDetails(teamData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const navigate = useNavigate();

  const fetchDataAndCheckAccess = async () => {
    if (!teamId) {
      navigate("/");
      return;
    }

    try {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          user_id: localStorage.getItem("user_id"),
          team_id: teamId,
        }),
      };

      const response = await fetch(
        getFullApiUrl(CHECK_TEAM_ACCESS),
        requestOptions
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      // setHasAccess(data === true);
      if (data !== true) {
        alert("You don't have access to this page.");
        navigate("/");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      navigate("/");
    }
  };

  useEffect(() => {
    fetchDataAndCheckAccess();
    getTeamDetails(teamId);
    fetchTeamMemberDetails();
  }, []);
  const isOwnerOrManager =
    teamMemberDetails === "Owner" || teamMemberDetails === "Manager";
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setselectedworkspace({ ...selectedworkspace, [name]: value });
  };
  const handleNewInputChange = (e) => {
    const { name, value } = e.target;
    setnewworkspaceData({ ...newworkspaceData, [name]: value });
  };
  const handleNewInputChangeForUser = (e) => {
    const { name, value } = e.target;
    set_new_user_for_workspace({ ...new_user_for_workspace, [name]: value });
  };
  const [modalState, setModalState] = useState({
    isOpen: false,
    type: "",
    message: "",
  });
  const handleAddworkspaceSubmit = async () => {
    if (!newworkspaceData.workspace_name) {
      setErrorMessage("Please add workspace name before adding.");
      return;
    }
    try {
      setModalState({
        isOpen: true,
        type: "loading",
        message: "Adding new Workspace...",
      });
      setErrorMessage("");
      const response = await fetch(getFullApiUrl(ADD_NEW_WORKSPACE), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newworkspaceData),
      });

      if (!response.ok) {
        throw new Error("Failed to add new workspace");
      }
      setModalState({
        isOpen: true,
        type: "success",
        message: "New Workspace added successfully!",
      });

      setTimeout(() => {
        setModalState({ isOpen: false, type: "", message: "" });
      }, 2000);
      handleAddClose();

      setnewworkspaceData({
        workspace_name: "",
        start_date: "",
        deadline: "",
        status: "",
        user_id: localStorage.getItem("user_id"),
        team_id: teamId,
      });
      fetchData();

      console.log("New workspace added successfully");
    } catch (error) {
      console.error("Error adding new workspace:", error.message);
      setModalState({ isOpen: false, type: "", message: "" });
    }
  };
  const [loadingTableData, setLoadingTableData] = useState(false);

  const fetchData = async () => {
    fetchteamName();
    const teams = await fetchTeamList();
    const users = await fetchUserList();
    await fetchworkspaceList(teamId, users, teams);
  };
  const fetchIinitialData = async () => {
    setLoadingTableData(true);
    fetchteamName();
    const teams = await fetchTeamList();
    const users = await fetchUserList();
    await fetchworkspaceList(teamId, users, teams);
    setLoadingTableData(false);
  };
  useEffect(() => {
    fetchIinitialData();
  }, []);



  const fetchworkspaceList = async (team_id, users, teams) => {
    try {
      const user_id = localStorage.getItem("user_id"); // Get user_id from localStorage

      const response = await fetch(getFullApiUrl(GET_ALL_WORKSPACE_BY_TEAM_ID), {
        method: "POST", // Use POST method
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          "team_id": team_id,
          "user_id": user_id
        }), // Pass team_id and user_id in JSON body
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const workspaceListData = await response.json();
      const updatedWorkspaceListData = workspaceListData.map((workspaceRow) => {
        const userWorkspace = users.find((u) => u.user_id === workspaceRow.user_id);
        if (userWorkspace) {
          workspaceRow.user_name = userWorkspace.user_name;
        }
        const teamWorkspace = teams.find((t) => t.team_id === workspaceRow.team_id);
        if (teamWorkspace) {
          workspaceRow.team_name = teamWorkspace.team_name;
        }
        return workspaceRow;
      });

      set_workspace_data(updatedWorkspaceListData); // Use the updated data here
      setErrorFetchMassage(null);
    } catch (error) {
      console.error("Error fetching data:", error);
      // setErrorFetchMassage("There was a server error. Please contact your admin.");
    }
  };


  const handleIndividualDeleteClick = (workspace) => {
    if (!isOwnerOrManager) {
      setNotificationMessage("You don't have access to delete.");
      setOpenSnackbar(true);
      return;
    }
    setselectedworkspacesForDelete([workspace]); // Select only the clicked workspace
    setshowDeleteworkspaceModal(true);
  };

  const handleBulkworkspaceDeleteClick = async (params) => {
    if (!isOwnerOrManager) {
      setNotificationMessage("You don't have access to delete multiple.");
      setOpenSnackbar(true);

      return;
    }
    if (!params || params.length === 0 || !Array.isArray(params)) {
      console.error("Invalid or empty params:", params);
      return;
    }

    try {
      const response = await fetch(`${getFullApiUrl(GET_ALL_WORKSPACE)}`);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const allworkspaces = await response.json();

      const workspaceDetails = params.map((workspaceId) => {
        const workspaces = allworkspaces.find(
          (u) => u.workspace_id === workspaceId
        );
        console.log("ss", workspaces);
        return workspaces
          ? `${workspaces.workspace_name} (${workspaces.description})`
          : "";
      });
      console.log("detail", workspaceDetails);

      setselectedworkspacesForBulkDelete(params);
      setselectedworkspacesForDelete(workspaceDetails);
      setShowBulkDeleteModal(true);
    } catch (error) {
      console.error("Error fetching workspace data:", error);
    }
  };

  const handleBulkDeleteConfirm = async () => {
    // console.log(selectedworkspacesForBulkDelete)
    try {
      if (
        !selectedworkspacesForBulkDelete ||
        !Array.isArray(selectedworkspacesForBulkDelete)
      ) {
        console.error(
          "Invalid or empty params:",
          selectedworkspacesForBulkDelete
        );
        return;
      } else {
        await handleBulkDelete(selectedworkspacesForBulkDelete);
        // Close modal and refresh workspace list
        setShowBulkDeleteModal(false);
        fetchData();
      }
    } catch (error) {
      console.error("Error deleting workspaces in bulk:", error.message);
    }
  };
  const handleDeleteworkspaceConfirm = async () => {
    try {
      await Promise.all(
        selectedworkspacesForDelete.map((workspace) =>
          handleDeleteworkspace(workspace.workspace_id)
        )
      );
      setselectedworkspacesForDelete([]);
      setshowDeleteworkspaceModal(false);
      fetchData(); // Refresh workspace list after deletion
    } catch (error) {
      console.error("Error deleting workspaces:", error.message);
    }
  };
  const handleBulkDelete = async (selected_workspace) => {
    set_selected_workspace(selected_workspace);
    try {
      // Iterate over selected workspaces and delete each one
      for (let workspaceId of selected_workspace) {
        await handleDeleteworkspace(workspaceId);
      }
      console.log("Bulk delete successful");
      // After bulk deletion, refresh the workspace list
      fetchData();
    } catch (error) {
      console.error("Error deleting workspaces in bulk:", error.message);
    }
  };

  const handleDeleteworkspace = async (workspaceId) => {
    console.log("delete click");
    console.log(workspaceId);
    try {
      const response = await fetch(getFullApiUrl(DELETE_WORKSPACE), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ workspace_id: workspaceId }),
      });

      if (!response.ok) {
        throw new Error("Failed to delete workspace");
      }

      console.log("workspace deleted successfully:", workspaceId);
      // Refresh workspace list after deletion
      fetchData();
    } catch (error) {
      console.error("Error deleting workspace:", error.message);
    }
  };
  const handleEditworkspace = (workspace) => {
    if (!isOwnerOrManager) {
      setNotificationMessage("You don't have access to edit.");
      setOpenSnackbar(true);

      return;
    }
    setselectedworkspace(workspace);
    setShowEditworkspaceModal(true);
  };
  const handleEditUser = (workspace) => {
    set_new_user_for_workspace({
      workspace_id: workspace.workspace_id,
      user_id: workspace.user_id,
      start_date: workspace.start_date,
      deadline: workspace.deadline,
      status: workspace.status,
    });
    setShowUserSearchModal(true);
  };
  const handleUserSearchCancel = () => {
    setSelectedUser(null);
  };

  const handleEditworkspaceData = async () => {
    try {
      const response = await fetch(getFullApiUrl(UPDATE_WORKSPACE), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(selectedworkspace),
      });

      if (!response.ok) {
        throw new Error("Failed to edit workspace");
      }

      console.log("workspace edited successfully");
      setSelectedUser(null);

      setShowEditworkspaceModal(false);
      // Refresh workspace list after editing
      fetchData();
    } catch (error) {
      console.error("Error editing workspace:", error.message);
    }
  };

  const fetchUserList = async () => {
    try {
      const response = await fetch(getFullApiUrl(GET_ALL_USERS));
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      set_user_data(data);
      return data;
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSearchInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    if (!searchTerm) {
      setSearchResults(user_data);
      return;
    }

    const filteredUsers = user_data.filter((user) =>
      user.user_name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setSearchResults(filteredUsers);
  }, [searchTerm, user_data]);

  const handleEditUserForWorkspace = async () => {
    try {
      const response = await fetch(getFullApiUrl(UPDATE_USER_OF_WORKSPACE), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          workspace_id: new_user_for_workspace.workspace_id,
          user_id: new_user_for_workspace.user_id,
        }),
      });
      console.log(new_user_for_workspace);

      if (!response.ok) {
        throw new Error("Failed to update user for the workspace");
      }

      // console.log("User for the workspace updated successfully");
      setShowUserSearchModal(false);
      // Refresh workspace list after updating user for the workspace
      fetchData();
    } catch (error) {
      console.error("Error updating user for the workspace:", error.message);
    }
  };
  //model open

  const [showAddworkspaceForm, setShowAddworkspaceForm] = React.useState(false);
  const [showEditworkspaceModal, setShowEditworkspaceModal] =
    React.useState(false);
  const [showBulkDeleteModal, setShowBulkDeleteModal] = React.useState(false);
  const [showUserSeachModal, setShowUserSearchModal] = React.useState(false);
  const handleAddOpen = () => {
    if (!isOwnerOrManager) {
      setNotificationMessage("You don't have access to add.");
      setOpenSnackbar(true);
      return;
    }
    setShowAddworkspaceForm(true);
  };
  const handleAddClose = () => setShowAddworkspaceForm(false);
  const handleEditOpen = () => setShowEditworkspaceModal(true);
  const handleEditClose = () => setShowEditworkspaceModal(false);
  const handleBulkDeleteOpen = () => setShowBulkDeleteModal(true);
  const handleBulkDeleteClose = () => setShowBulkDeleteModal(false);
  const handleUserSearchClose = () => {
    setShowUserSearchModal(false);
    setSelectedUser(null);
  };

  const handleUserSelect = (user) => {
    // Copy user_id from selectedUser to new_user_for_workspace
    set_new_user_for_workspace({
      ...new_user_for_workspace,
      user_id: user.user_id,
    });
    // Set the selected user
    setSelectedUser(user);
  };
  const handleNewUserSelect = (user) => {
    // Copy user_id from selectedUser to newworkspaceData
    setnewworkspaceData({
      ...newworkspaceData,
      user_id: user.user_id,
    });
    // Set the selected user
    setSelectedUser(user);
  };

  const handleDeleteworkspaceModalClose = () => {
    setshowDeleteworkspaceModal(false);
  };

  // search team......

  const [selectedTeam, setSelectedTeam] = useState(null);
  const [selectedTeamToUpdate, setSelectedTeamToUpdate] = useState(null);
  const [team_data, set_team_data] = useState([]);
  const [team_data_for_update, set_team_data_for_update] = useState([]);
  const [searchTermteam, setSearchTermteam] = useState("");
  const [searchTermteamToUpdate, setSearchTermteamToUpdate] = useState("");
  const [searchTeamResults, setSearchTeamResults] = useState([]);
  const [searchTeamResultsToUpdate, setSearchTeamResultsToUpdate] = useState(
    []
  );

  const fetchTeamList = async () => {
    try {
      const response = await fetch(getFullApiUrl(GET_ALL_TEAM));
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const teamData = await response.json();
      set_team_data(teamData);
      set_team_data_for_update(teamData);
      return teamData;
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSearchTeamInputChange = (e) => {
    setSearchTermteam(e.target.value);
  };
  const handleSearchTeamInputChangeToUpdate = (e) => {
    setSearchTermteamToUpdate(e.target.value);
  };

  useEffect(() => {
    if (!searchTermteam) {
      setSearchTeamResults(team_data);
      return;
    }

    const filteredTeams = team_data.filter((team) =>
      team.team_name.toLowerCase().includes(searchTermteam.toLowerCase())
    );
    setSearchTeamResults(filteredTeams);
  }, [searchTermteam, team_data]);
  useEffect(() => {
    if (!searchTermteamToUpdate) {
      setSearchTeamResultsToUpdate(team_data_for_update);
      return;
    }

    const filteredTeams = team_data_for_update.filter((team) =>
      team.team_name
        .toLowerCase()
        .includes(searchTermteamToUpdate.toLowerCase())
    );
    setSearchTeamResultsToUpdate(filteredTeams);
  }, [searchTermteamToUpdate, team_data_for_update]);

  const handleTeamSelect = (team) => {
    // Copy user_id from selectedUser to new_user_for_workspace
    setnewworkspaceData({
      ...newworkspaceData,
      team_id: team.team_id,
    });
    // Set the selected user
    setSelectedTeam(team);
  };
  const handleteamSelectToUpdate = (team) => {
    // Copy user_id from selectedUser to new_user_for_workspace
    setnewworkspaceDataToUpdate({
      ...newworkspaceDataToUpdate,
      team_id: team.team_id,
    });
    // Set the selected user
    setSelectedTeamToUpdate(team);
  };

  // update team....
  const [showTeamSeachModal, setShowTeamSearchModal] = React.useState(false);

  const handleteamSearchClose = () => {
    setShowTeamSearchModal(false);
    setSelectedTeam(null);
  };
  const [new_team_for_workspace, set_new_team_for_workspace] = useState({
    workspace_id: "",
    team_id: "",
  });
  const [
    new_team_for_workspace_to_update,
    set_new_team_for_workspace_to_update,
  ] = useState({
    workspace_id: "",
    team_id: "",
  });
  const handleEditTeam = (workspace) => {
    set_new_team_for_workspace_to_update({
      workspace_id: workspace.workspace_id,
      team_id: workspace.team_id,
    });
    setShowTeamSearchModal(true);
  };
  const handleteamSelect = (user) => {
    // Copy user_id from selectedUser to new_user_for_workspace
    set_new_team_for_workspace({
      ...new_team_for_workspace,
      team_id: user.team_id,
    });
  };
  const handleTeamSearchCancel = () => {
    setSelectedTeam(null);
  };

  const handleEditTeamForWorkspace = async () => {
    try {
      const response = await fetch(getFullApiUrl(UPDATE_TEAM_OF_WORKSPACE), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          workspace_id: new_team_for_workspace_to_update.workspace_id,
          team_id: new_team_for_workspace_to_update.team_id,
        }),
      });
      console.log(new_team_for_workspace_to_update);

      if (!response.ok) {
        throw new Error("Failed to update user for the workspace");
      }

      // console.log("User for the workspace updated successfully");
      setShowTeamSearchModal(false);
      // Refresh workspace list after updating user for the workspace
      fetchData();
    } catch (error) {
      console.error("Error updating user for the workspace:", error.message);
    }
  };
  const [workspaceID, setWorkspaceID] = useState(null);
  const [teamID, setTeamID] = useState(null);
  const handleOpenTeamSection = (workspace) => {
    const workspace_id = workspace.workspace_id;
    const team_id = workspace.team_id;
    // Set the teamId state
    setWorkspaceID(workspace_id);
    setTeamID(team_id);
    console.log(workspaceID);
    console.log(teamID);

    // Navigate to the MyTeam page
    return `/my_workspace_campaign/${workspace_id}`;
  };

  const handleOpenTeamWorkspaceMemberSection = (workspace) => {
    const workspace_id = workspace.workspace_id;
    const team_id = workspace.team_id;
    // Set the teamId state
    setWorkspaceID(workspace_id);
    setTeamID(team_id);
    console.log(workspaceID);
    console.log(teamID);

    // Navigate to the MyTeam page
    return `/my_workspace_members/${workspace_id}`;
  };
  const [team_name, set_team_name] = useState([]);
  const fetchteamName = async () => {
    try {
      const response = await fetch(
        `${getFullApiUrl(TEAM_NAME_FROM_ID)}?team_id=${teamId}`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const teamData = await response.json();
      set_team_name(teamData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleOpenWorkspaceSection = (workspace) => {
    const workspace_id = workspace.workspace_id;

    // Navigate to the MyTeam page
    return `/my_workspace/${workspace_id}`;
  };
  const [workspaceData, setWorkspaceData] = useState({
    team_id: "",
    team_name: "",
    workspace_name: "",
  });
  const [teamData, setTeamData] = useState({
    team_name: "",
  });

  const getTeamDetails = async (teamId) => {
    try {
      const response = await fetch(`${getFullApiUrl(GET_TEAM_DETAILS)}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          team_id: teamId,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to fetch team details");
      }

      const data = await response.json();
      setTeamData(data);
    } catch (error) {
      console.error(error.message);
    }
  };

  return (
    <div className="page_Section">
      <div className="leftSection_withMenu">
        <LeftSectionWithMenu />
      </div>
      <div className="rightSection_withMenu">
        <div className="headerSection_withMenu">
          <Header onToggleTheme={toggleTheme} />
        </div>
        <div className="bodySection">
          <Breadcrumbs
            id="Breadcrumbs"
            separator={<NavigateNext />}
            aria-label="breadcrumb"
          >
            <div className="breadcrumbs_text">
              <Link
                component={RouterLink}
                to="/"
                color="inherit"
                underline="hover"
              >
                Home
              </Link>
            </div>
            <div className="breadcrumbs_text">
              <Link
                component={RouterLink}
                to="/my_teams"
                color="inherit"
                underline="hover"
              >
                My Teams
              </Link>
            </div>
            <div className="breadcrumbs_text">
              <Link
                component={RouterLink}
                to={`/my_team/${teamId}`}
                color="inherit"
                underline="hover"
              >
                {teamData.team_name}
              </Link>
            </div>
            <div className="breadcrumbs_text">

              {teamData.team_name} Workspaces
            </div>
          </Breadcrumbs>

          <div className="admin_page_heading"></div>
          <div className="admin_page_heading">Workspace Management</div>
          {errorFetchMassage ? (
            <div className="alert_massage_container">
              <Alert severity="error">{errorFetchMassage}</Alert>
            </div>
          ) : loadingTableData ? (
            <div className="loading_spinner">
              <CircularProgress />
            </div>
          ) : (
            <TableWithPagination
              file_name={`${teamData.team_name}_workspaces_data`}
              data={workspace_data}
              key={workspace_data.length}

              fields={[
                {
                  type: "action",
                  field: "actions",
                  headerName: "Actions",
                  width: 80,
                  renderCell: (params) => {
                    return (
                      <div className="action_icons_">
                        <div>&nbsp;&nbsp;</div>
                        <Tooltip title="Edit">
                          <IconButton
                            onClick={() => handleEditworkspace(params)}
                          >
                            <LiaEdit className="table_action_icon" />
                          </IconButton>
                        </Tooltip>

                        <Tooltip title="Delete">
                          <IconButton
                            onClick={() => handleIndividualDeleteClick(params)}
                          >
                            <DeleteOutlineIcon className="table_action_icon" />
                          </IconButton>
                        </Tooltip>
                      </div>
                    );
                  },
                },

                {
                  type: "field",
                  field: "workspace_name",
                  headerName: "Workspace",
                  search_type: "text_search",
                  minWidth: 250,
                  width: 280,
                  maxWidth: 1500,
                },
                {
                  type: "action",
                  field: "workspace_info",
                  headerName: "",
                  minWidth: 80,
                  width: 90,
                  maxWidth: 180,
                  renderCell: (params) => {
                    return (
                      <div className="action_icons_">
                        <div>&nbsp;&nbsp;&nbsp;</div>
                        <Tooltip title="Open Workspace Info">
                          <IconButton>
                            <RouterLink to={handleOpenWorkspaceSection(params)}>
                              <MdOutlineWorkOutline className="table_action_icon" />
                            </RouterLink>
                          </IconButton>
                        </Tooltip>

                        <Tooltip title="Open Workspace Member">
                          <IconButton>
                            <RouterLink
                              to={handleOpenTeamWorkspaceMemberSection(params)}
                            >
                              <MdPeopleAlt className="table_action_icon" />
                            </RouterLink>
                          </IconButton>
                        </Tooltip>
                      </div>
                    );
                  },
                },

                {
                  type: "field",
                  field: "team_name",
                  headerName: "Team Name",
                  search_type: "text_search",
                  minWidth: 200,
                  width: 220,
                  maxWidth: 1500,
                },
                {
                  type: "field",
                  field: "member_designation",
                  headerName: "member_designation",
                  search_type: "text_search",
                  minWidth: 200,
                  width: 250,
                  maxWidth: 1500,
                },
                {
                  type: "field",
                  field: "owner_name",
                  headerName: "Owner Name",
                  search_type: "text_search",
                  minWidth: 200,
                  width: 250,
                  maxWidth: 1500,
                },
                {
                  type: "action",
                  field: "campaign_info",
                  headerName: "",
                  minWidth: 40,
                  width: 50,
                  maxWidth: 180,
                  renderCell: (params) => {
                    return (
                      <div className="action_icons_">
                        <div>&nbsp;&nbsp;&nbsp;</div>

                        <Tooltip title="Open Campaign">
                          <IconButton>
                            <RouterLink to={handleOpenTeamSection(params)}>
                              <MdCampaign className="table_action_icon" />
                            </RouterLink>
                          </IconButton>
                        </Tooltip>

                      </div>
                    );
                  },
                },
                {
                  type: "field",
                  field: "total_campaigns",
                  headerName: "Total Campaign",
                  search_type: "text_search",
                  minWidth: 200,
                  width: 220,
                  maxWidth: 1500,
                },

                {
                  type: "field",
                  field: "created_at",
                  headerName: "Created At",
                  search_type: "text_search",
                  minWidth: 200,
                  width: 250,
                  maxWidth: 1500,
                },
                {
                  type: "id",
                  field: "workspace_id",
                  headerName: "",
                  minWidth: 1,
                  width: 1,
                  maxWidth: 360,
                },
              ]}
              bulk_actions={[
                {
                  action: "add",
                  renderCell: (params) => {
                    return (
                      <Tooltip title="Add Workspace">
                        <div
                          className="bulk_classify_button download_icons"
                          onClick={handleAddOpen}
                        >
                          <IoIosAddCircleOutline />
                        </div>
                      </Tooltip>
                    );
                  },
                },
                {
                  action: "delete_multiple",
                  renderCell: (params) => {
                    return (
                      <Tooltip title="Delete Selected Workspaces">
                        <div
                          onClick={() => handleBulkworkspaceDeleteClick(params)}
                          className="bulk_delete_button download_icons"
                        >
                          <MdOutlineDeleteSweep fontSize={30} />
                        </div>
                      </Tooltip>
                    );
                  },
                },
              ]}
              tableDisplay={"Table"}
            />
          )}


          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            open={openSnackbar}
            autoHideDuration={6000}
            onClose={handleSnackbarClose}
            message={notificationMessage}
          />
          {/* {workspaceID && <MyTeamWorkspaceCampaign workspaceId={workspaceID} />}
          {workspaceID && <MyTeamWorkspaceMember workspaceId={workspaceID} />} */}
          <Modal
            open={showAddworkspaceForm}
            onClose={handleAddClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box id="model_box">
              <div className="add-user-form">
                <div className="modal_header_container">
                  <div className="modal_header_text">Add New workspace</div>
                  <div className="modal_header_close_icon">
                    <IconButton onClick={handleAddClose}>
                      <CloseIcon />
                    </IconButton>
                  </div>
                </div>
                <Box
                  component="form"
                  sx={{
                    "& > :not(style)": {
                      m: 2,
                      width: "80%",
                      marginLeft: "10%",
                    },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <TextField
                    id="workspace_name"
                    label="workspace Name"
                    variant="standard"
                    name="workspace_name"
                    value={newworkspaceData.workspace_name}
                    onChange={handleNewInputChange}
                    className="modal_form_input"
                  />
                </Box>

                <Accordion id="accordion_modal">
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    s
                    id="panel1a-header"
                    sx={{
                      padding: "0",
                    }}
                  >
                    <div>Add More Workspace Details</div>
                  </AccordionSummary>
                  <AccordionDetails>
                    {/* Start Date */}
                    <Box
                      component="form"
                      sx={{
                        "& > :not(style)": {
                          m: "10px 0",
                          width: "100%",
                        },
                      }}
                      noValidate
                      autoComplete="off"
                    >
                      <TextField
                        type="date"
                        id="start_date"
                        helperText="Please select Start Date"
                        variant="standard"
                        name="start_date"
                        value={newworkspaceData.start_date}
                        onChange={handleNewInputChange}
                        className="modal_form_input"
                      />
                    </Box>

                    {/* Deadline */}
                    <Box
                      component="form"
                      sx={{
                        "& > :not(style)": {
                          m: "10px 0",
                          width: "100%",
                        },
                      }}
                      noValidate
                      autoComplete="off"
                    >
                      <TextField
                        type="date"
                        id="deadline"
                        helperText="Please select Deadline Date"
                        variant="standard"
                        name="deadline"
                        value={newworkspaceData.deadline}
                        onChange={handleNewInputChange}
                        className="modal_form_input"
                      />
                    </Box>

                    {/* Status */}
                    <Box
                      component="form"
                      sx={{
                        "& > :not(style)": {
                          m: "10px 0",
                          width: "100%",
                        },
                      }}
                      noValidate
                      autoComplete="off"
                    >
                      <TextField
                        id="status"
                        select
                        label="Status"
                        name="status"
                        helperText="Please select your Status"
                        value={newworkspaceData.status}
                        onChange={handleNewInputChange}
                        className="modal_form_input"
                      >
                        <MenuItem value="Not Started">Not Started</MenuItem>
                        <MenuItem value="Progress">Progress</MenuItem>
                        <MenuItem value="Done">Done</MenuItem>
                      </TextField>
                    </Box>

                    {/* Workspace Description */}
                    <Box
                      component="form"
                      sx={{
                        "& > :not(style)": {
                          m: "10px 0",
                          width: "100%",
                        },
                      }}
                      noValidate
                      autoComplete="off"
                    >
                      <TextField
                        type="textarea"
                        id="workspace_description"
                        label="Workspace Description"
                        variant="standard"
                        name="workspace_description"
                        value={newworkspaceData.workspace_description}
                        onChange={handleNewInputChange}
                        className="modal_form_input"
                        multiline
                        rows={6}
                      />
                    </Box>
                  </AccordionDetails>
                </Accordion>
                {errorMessage && (
                  <div
                    style={{
                      color: "red",
                      paddingLeft: "58px",
                      textAlign: "left",
                    }}
                  >
                    {errorMessage}
                  </div>
                )}

                <button
                  onClick={handleAddworkspaceSubmit}
                  className="add-user-btn"
                >
                  Add{" "}
                </button>
              </div>
            </Box>
          </Modal>

          <Modal
            open={showEditworkspaceModal}
            onClose={handleEditClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box id="model_box">
              <div className="add-user-form">
                <div className="modal_header_container">
                  <div className="modal_header_text">Update workspace</div>
                  <div className="modal_header_close_icon">
                    <IconButton onClick={handleEditClose}>
                      <CloseIcon />
                    </IconButton>
                  </div>
                </div>
                <Box
                  component="form"
                  sx={{
                    "& > :not(style)": {
                      m: 2,
                      width: "80%",
                      marginLeft: "10%",
                    },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <TextField
                    id="workspace_name"
                    label="workspace Name"
                    variant="standard"
                    name="workspace_name"
                    value={
                      selectedworkspace ? selectedworkspace.workspace_name : ""
                    }
                    onChange={handleInputChange}
                    className="modal_form_input"
                  />
                </Box>
                <Accordion id="accordion_modal">
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    s
                    id="panel1a-header"
                    sx={{
                      padding: "0",
                    }}
                  >
                    <div>Update other Workspace Details</div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box
                      component="form"
                      sx={{
                        "& > :not(style)": {
                          m: "10px 0",
                          width: "100%",
                        },
                      }}
                      noValidate
                      autoComplete="off"
                    >
                      <TextField
                        id="workspace_description"
                        label="workspace Description"
                        variant="standard"
                        name="workspace_description"
                        value={
                          selectedworkspace
                            ? selectedworkspace.workspace_description
                            : ""
                        }
                        multiline
                        rows={6}
                        onChange={handleInputChange}
                        className="modal_form_input"
                      />
                    </Box>
                    <Box
                      component="form"
                      sx={{
                        "& > :not(style)": {
                          m: "10px 0",
                          width: "100%",
                        },
                      }}
                      noValidate
                      autoComplete="off"
                    >
                      <TextField
                        type="date"
                        id="start_date"
                        helperText="Please select Start Date"
                        variant="standard"
                        name="start_date"
                        value={
                          selectedworkspace ? selectedworkspace.start_date : ""
                        }
                        onChange={handleInputChange}
                        className="modal_form_input"
                      />
                    </Box>
                    <Box
                      component="form"
                      sx={{
                        "& > :not(style)": {
                          m: "10px 0",
                          width: "100%",
                        },
                      }}
                      noValidate
                      autoComplete="off"
                    >
                      <TextField
                        type="date"
                        id="deadline"
                        helperText="Please select deadline Date"
                        variant="standard"
                        name="deadline"
                        value={
                          selectedworkspace ? selectedworkspace.deadline : ""
                        }
                        onChange={handleInputChange}
                        className="modal_form_input"
                      />
                    </Box>

                    <Box
                      component="form"
                      sx={{
                        "& > :not(style)": {
                          m: "10px 0",
                          width: "100%",
                        },
                      }}
                      noValidate
                      autoComplete="off"
                    >
                      <TextField
                        id="status"
                        select
                        label="Status"
                        variant="standard"
                        name="status"
                        value={
                          selectedworkspace ? selectedworkspace.status : ""
                        }
                        onChange={handleInputChange}
                        className="modal_form_input"
                      >
                        <MenuItem value=" Not Started"> Not Started</MenuItem>
                        <MenuItem value="Progress"> Progress</MenuItem>
                        <MenuItem value="Done"> Done</MenuItem>
                      </TextField>
                    </Box>
                  </AccordionDetails>
                </Accordion>

                <button
                  onClick={handleEditworkspaceData}
                  className="add-user-btn"
                >
                  Update workspace
                </button>
              </div>
            </Box>
          </Modal>

          <Modal
            open={showDeleteworkspaceModal}
            onClose={handleDeleteworkspaceModalClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box id="model_box">
              <div className="delete-user-form">
                <div className="modal_header_container">
                  <div className="modal_header_text">Delete workspace(s)</div>
                  <div className="modal_header_close_icon">
                    <IconButton onClick={handleDeleteworkspaceModalClose}>
                      <CloseIcon />
                    </IconButton>
                  </div>
                </div>
                {selectedworkspacesForDelete.map((workspace) => (
                  <div key={workspace.workspace_id}>
                    <div className="modal_text">
                      workspace: {workspace.workspace_name}
                    </div>
                  </div>
                ))}
                <div className="modal_text">
                  Are you sure you want to delete selected workspace(s)?
                </div>
                <div className="form-group">
                  <Button onClick={handleDeleteworkspaceConfirm} color="error">
                    <MdDelete className="formIcons" />
                  </Button>
                  <Button onClick={handleDeleteworkspaceModalClose}>
                    <IoMdCloseCircle className="formIcons" />
                  </Button>
                </div>
              </div>
            </Box>
          </Modal>
          {/*Bulk Delete Workspaces Model */}
          <Modal
            open={showBulkDeleteModal}
            onClose={handleBulkDeleteClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box id="model_box">
              <div className="delete-user-form">
                <div className="modal_header_container">
                  <div className="modal_header_text">Delete workspace(s)</div>
                  <div className="modal_header_close_icon">
                    <IconButton onClick={handleBulkDeleteClose}>
                      <CloseIcon />
                    </IconButton>
                  </div>
                </div>

                {selectedworkspacesForDelete.map((workspace) => (
                  <div key={workspace}>
                    <div className="modal_text">
                      workspace Name: {workspace}{" "}
                    </div>
                  </div>
                ))}
                <div className="modal_text">
                  Are you sure you want to delete selected workspace(s)?
                </div>
                <div className="form-group">
                  <Button onClick={handleBulkDeleteConfirm} color="error">
                    <MdDelete className="formIcons" />
                  </Button>
                  <Button onClick={handleBulkDeleteClose}>
                    <IoMdCloseCircle className="formIcons" />
                  </Button>
                </div>
              </div>
            </Box>
          </Modal>
          <Modal
            open={showUserSeachModal}
            onClose={handleUserSearchClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box id="model_box">
              <div className="delete-user-form">
                <div className="modal_header">Update Owner</div>
                <Box
                  component="form"
                  sx={{
                    "& > :not(style)": {
                      m: 2,
                      width: "80%",
                      marginLeft: "10%",
                    },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <Autocomplete
                    id="search_user"
                    options={searchResults}
                    getOptionLabel={(option) =>
                      `${option.user_name} - ${option.email_id}`
                    }
                    onChange={(event, newValue) => handleUserSelect(newValue)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Search Owner"
                        variant="standard"
                        onChange={handleSearchInputChange}
                        className="modal_form_input"
                      />
                    )}
                  />
                </Box>
                {/* Render search results */}
                {selectedUser && (
                  <div className="modal_text">
                    <Typography>
                      Selected User: {selectedUser.user_name} -{" "}
                      {selectedUser.email_id}
                    </Typography>

                    {/* Use the selected user's ID */}
                    <TextField
                      id="user_id"
                      variant="standard"
                      name="user_id"
                      value={
                        new_user_for_workspace
                          ? new_user_for_workspace.user_id
                          : ""
                      }
                      disabled={!new_user_for_workspace}
                      className="modal_form_input"
                    />
                    <div className="form-group">
                      {/* Button to confirm user selection */}
                      <Button
                        onClick={handleEditUserForWorkspace}
                        color="primary"
                      >
                        Confirm
                      </Button>
                      {/* Button to close the modal */}
                      <Button onClick={handleUserSearchCancel} color="error">
                        Cancel
                      </Button>
                    </div>
                  </div>
                )}
                <div className="form-group">
                  <Button onClick={handleUserSearchClose} color="error">
                    Close
                  </Button>
                </div>
              </div>
            </Box>
          </Modal>
          <StatusModalComponent
            isOpen={modalState.isOpen}
            type={modalState.type}
            message={modalState.message}
            onClose={() => setModalState({ isOpen: false, type: "", message: "" })}
          />
        </div>
      </div>
    </div>
  );
};

export default MyTeamWorkspace;
