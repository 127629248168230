import React, { useState, useEffect, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  withRouter,
} from "react-router-dom";
import Papa from "papaparse";
//import {Router, browserHistory} from 'react-router';
import { useNavigate } from "react-router-dom";
import { Input, Tabs, Tab, Box, Grid, Button, Skeleton } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { IconButton, Modal, Typography, Tooltip } from "@mui/material";
import {
  Checkbox,
  FormControlLabel,
  Autocomplete,
  TextField,
  TextareaAutosize,
} from "@mui/material";
//import Tooltip from '@mui/material/Tooltip';
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DownloadIcon from "@mui/icons-material/Download";
import CloseIcon from "@mui/icons-material/Close";
import page_classification_icon from "../../../assets/icons/page_classification_icon2.png";
import save_button_icon from "../../../assets/icons/save_button_icon.png";

import { styled } from "@mui/material/styles";
import "./PageQualityCheckComponent.css";
import {
  GET_IDENTIFIED_PARAGRAPHS_DATA_FILTERED,
  LOAD_PREVIOUSLY_CLASSIFIED_DATA,
  USER_UPLOAD_IDENTIFIED_PARAGRAPHS_DATA,
  getFullApiUrl,
} from "../../../utils/apiPath";
import TableWithPagination from "../../../utils/TableWithPagination";
import { MdOutlineDeleteOutline } from "react-icons/md";

const PageQualityCheckComponent = ({ props }) => {
  const navigate = useNavigate();
  const { campaignId } = useParams();
  //var fileInputRef = useRef(null);

  //const [client_urls_data, set_client_urls_data] = useState([]);
  //const [selected_client_url, set_selected_client_url] = useState({});

  //const [blog_urls_data, set_blog_urls_data] = useState([]);
  //const [analyzed_blog_urls_data, set_analyzed_blog_urls_data] = useState([]);

  const [identified_paragraphs_data, set_identified_paragraphs_data] = useState(
    []
  );

  //const [initial_data_loaded_client, set_initial_data_loaded_client] = useState(0);
  //const [initial_data_loaded_blog, set_initial_data_loaded_blog] = useState(0);
  const [
    initial_data_loaded_identified_paragraphs,
    set_initial_data_loaded_identified_paragraphs,
  ] = useState(0);

  //const [filter_count_of_keyword_match, set_filter_count_of_keyword_match] = useState(0);
  const [filter_similarity_score, set_filter_similarity_score] = useState({
    from: 33,
    to: 70,
  });

  //const [open, setOpen] = React.useState(false);

  const [filtered_scored_data, set_filtered_scored_data] = useState([]);
  const [filtered_blog_urls, set_filtered_blog_urls] = useState([]);
  //const [search_data, set_search_data] = useState([]);

  const [file_upload_field_key, set_file_upload_field_key] = useState(
    Math.random()
  );
  const [filtered_scored_data_1, set_filtered_scored_data_1] = useState(
    Math.random()
  );

  useEffect(() => {}, []);

  const get_identified_paragraphs_data_filtered = () => {
    set_initial_data_loaded_identified_paragraphs(1);
    fetch(getFullApiUrl(GET_IDENTIFIED_PARAGRAPHS_DATA_FILTERED), {
      method: "POST",
      body: JSON.stringify({
        request_data: {
          campaign_id: campaignId,
          from: filter_similarity_score.from,
          to: filter_similarity_score.to,
        },
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((inputData) => {
        console.log(inputData.data);
        var transformedData = [];
        var search_result_id = 0;
        inputData.data.forEach((entry) => {
          const clientUrl = entry.client_url;
          const blogUrls = entry.blog_urls;

          blogUrls.forEach((blog) => {
            search_result_id = search_result_id + 1;
            const transformedEntry = {
              search_result_id: search_result_id,
              client_url: clientUrl,
              blog_url: blog.blog_url,
              blog_title: blog.title,
              keywords: blog.keywords,
              //matched_paragraph: blog.matched_paragraph,
              selected: blog.selected,
              similarity_score: blog.similarity_score,
            };
            transformedData.push(transformedEntry);
          });
        });
        console.log(transformedData);
        set_filtered_scored_data(transformedData);
        set_initial_data_loaded_identified_paragraphs(0);
      })
      .catch((error) => {
        console.error("Error:", error);
        set_filtered_scored_data([]);
        set_initial_data_loaded_identified_paragraphs(0);
      });
  };

  const upload_user_uploaded_file_1 = (event) => {
    console.log(
      "******************************************************************"
    );
    console.log("upload_user_uploaded_file");
    const user_uploaded_file = event.target.files[0];
    const formData = new FormData();
    formData.append("file", user_uploaded_file);

    fetch(getFullApiUrl(USER_UPLOAD_IDENTIFIED_PARAGRAPHS_DATA), {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("File uploaded successfully:", data);
      })
      .catch((error) => {
        console.error("Error uploading file:", error);
      });
    //fileInputRef = null;
    //fileInputRef.current.value = null;
    set_file_upload_field_key(Math.random());
  };

  const upload_user_uploaded_file = (event) => {
    console.log(
      "******************************************************************"
    );
    console.log("upload_user_uploaded_file");
    const user_uploaded_file = event.target.files[0];
    if (user_uploaded_file) {
      Papa.parse(user_uploaded_file, {
        header: true,
        dynamicTyping: true,
        complete: (results) => {
          var search_result_id = 0;
          const updatedData = results.data.map((obj) => {
            // Create a new object with the modified field name
            search_result_id = search_result_id + 1;
            return {
              ...obj,
              search_result_id: search_result_id,
              client_url: obj["Client URL"],
              blog_url: obj["Blog URL"],
              blog_title: obj["Blog Title"],
              similarity_score: obj["Similarity Score"],
              classification: obj["Page Type"],
            };
          });

          console.log(updatedData);
          set_filtered_scored_data(updatedData);
        },
      });
    }
    set_file_upload_field_key(Math.random());
  };

  const handleSaveMultiple = async (record_ids) => {
    var filteredDataWithRecordIds = filtered_scored_data.filter((item) =>
      record_ids.includes(item.search_result_id)
    );
    console.log(filteredDataWithRecordIds);
    var selectedFields = filteredDataWithRecordIds.map((item) => ({
      client_url: item.client_url,
      blog_url: item.blog_url,
      blog_title: item.blog_title,
      page_type: item.classification,
      similarity_score: item.similarity_score,
    }));
    //console.log(selectedFields);
    fetch(getFullApiUrl(USER_UPLOAD_IDENTIFIED_PARAGRAPHS_DATA), {
      method: "POST",
      body: JSON.stringify({
        request_data: { campaign_id: campaignId, data: selectedFields },
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("File uploaded successfully:", data);
      })
      .catch((error) => {
        console.error("Error uploading file:", error);
      });
  };

  const load_previously_classified_data = async () => {
    fetch(getFullApiUrl(LOAD_PREVIOUSLY_CLASSIFIED_DATA), {
      method: "POST",
      body: JSON.stringify({ request_data: { campaign_id: campaignId } }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        var search_result_id = 0;
        const updatedData = data.data.map((obj) => {
          search_result_id = search_result_id + 1;
          return {
            ...obj,
            search_result_id: search_result_id,
            classification: obj.page_type,
            /*client_url: obj["Client URL"],
            blog_url: obj["Blog URL"],
            similarity_score: obj["Similarity Score"]*/
          };
        });
        set_filtered_scored_data(updatedData);
        //console.log('File uploaded successfully:', data);
      })
      .catch((error) => {
        console.error("Error Downloading file:", error);
      });
  };

  const get_blog_urls_from_uploaded_data = () => {};

  const get_prompt = (blog_url) => {
    var classification_prompt =
      "\nThe URL of the webpage is " +
      blog_url["blog_url"] +
      "\n" +
      "The webpage title is " +
      blog_url["blog_title"] +
      "\n\n" +
      "Based on the above webpage URL and webpage title, now classify the webpage in one of the following classes:\n" +
      //"Based on the above webpage URL, now classify the webpage in one of the following classes:\n" +
      "- About Us Page\n" +
      "- Blog Page\n" +
      "- Contact Us Page\n" +
      "- FAQ Page\n" +
      "- Home Page\n" +
      "- Landing Page\n" +
      "- News Page\n" +
      "- Privacy Policy Page\n" +
      "- Service Page\n" +
      "- Terms of Service Page\n" +
      "- Testimonials/Reviews Page\n" +
      "- Product Page\n" +
      "- Ecommerce Product Page\n" +
      "- Sales Page\n" +
      "- Forum Page\n" +
      "- Job Listing Page\n" +
      "- Category Page\n" +
      "- Tag Page\n" +
      "- Ecommerce Category Page\n" +
      "- Blog Page of Product Listicles\n" +
      "- Directory Listing Page\n" +
      "- Pricing Page\n" +
      "- Resource Blog Page\n" +
      "- Profile Page\n" +
      "- Product Listicles Blog Page\n" +
      "- Help Desk Pages\n\n" +
      "Remember, I want only one class without any explanation.";
    return classification_prompt;
  };
  const apiEndpoints = [
    "https://m5p5ee4g5l.execute-api.us-east-2.amazonaws.com",
  ];

  const handleClassifyMultiple = async (record_ids) => {
    let filteredDataWithRecordIds = filtered_scored_data.filter((item) =>
      record_ids.includes(item.search_result_id)
    );
    //let uniqueBlogURLs = [...new Set(filteredDataWithRecordIds.map(item => item.blog_url))];
    //let uniqueBlogURLs = [...new Set(filteredDataWithRecordIds.map(item => ({ blog_url: item.blog_url, blog_title: item.blog_title })))];
    let BlogURLs_list = [
      ...new Set(
        filteredDataWithRecordIds.map((item) => ({
          blog_url: item.blog_url,
          blog_title: item.blog_title,
        }))
      ),
    ];
    let uniqueBlogURLs = BlogURLs_list.filter(
      (value, index, self) =>
        index === self.findIndex((obj) => obj.blog_url === value.blog_url)
    );

    console.log(uniqueBlogURLs);

    const totalRows = uniqueBlogURLs.length;
    const batchSize = 10; // Number of API calls per batch
    const totalBatches = Math.ceil(totalRows / batchSize);

    let updatedData = [...filtered_scored_data];

    const classifyBatch = async (batchIndex) => {
      const startIndex = batchIndex * batchSize;
      const endIndex = Math.min(startIndex + batchSize, totalRows);
      const batchUniqueBlogURLs = uniqueBlogURLs.slice(startIndex, endIndex);

      //const apiIndex = batchIndex % apiEndpoints.length; // Rotating through API endpoints

      const classifyURL = async (blogURL) => {
        //console.log(blogURL);
        const index = uniqueBlogURLs.indexOf(blogURL);
        //console.log(index);
        const record_id = record_ids[index];
        const classification_prompt = get_prompt(blogURL);
        const apiIndex = index % apiEndpoints.length;

        try {
          const response = await fetch(apiEndpoints[apiIndex], {
            method: "POST",
            body: JSON.stringify({
              request_data: { prompt: classification_prompt },
            }),
            headers: {
              "Content-Type": "application/json",
            },
          });

          if (response.ok) {
            const data = await response.json();
            console.log(
              `Classification result for url ${blogURL["blog_url"]}:`,
              data.response
            );

            // Update data based on blog_url
            updatedData = updatedData.map((item) =>
              item.blog_url === blogURL["blog_url"]
                ? { ...item, classification: data.response }
                : item
            );

            set_filtered_scored_data(updatedData);
          } else {
            console.error(
              `Error classifying url ${blogURL["blog_url"]}:`,
              response.statusText
            );
          }
        } catch (error) {
          console.error(`Error classifying url ${blogURL["blog_url"]}:`, error);
        }
      };

      const classifyBatchURLs = batchUniqueBlogURLs.map(classifyURL);
      await Promise.all(classifyBatchURLs);

      if (startIndex + batchSize < totalRows) {
        classifyBatch(batchIndex + 1); // Proceed to the next batch
      }
    };

    classifyBatch(0); // Start with the first batch
  };

  const handlePageQualityCheck = async (record_ids) => {
    let filteredDataWithRecordIds = filtered_scored_data.filter((item) =>
      record_ids.includes(item.search_result_id)
    );
    let uniqueBlogURLs = [
      ...new Set(filteredDataWithRecordIds.map((item) => item.blog_url)),
    ];

    console.log(uniqueBlogURLs);
    console.log(uniqueBlogURLs);

    try {
      const response = await fetch(getFullApiUrl, {
        method: "POST",
        body: JSON.stringify({
          queueName: "Uipath_Queue",
          commitType: "AllOrNothing",
          queueItems: [
            {
              SpecificContent: {
                Matrix: "Website Traffic",
                "Company URL":
                  "connectcre.com/chicago-midwest/,https://www.python.org/downloads/,https://code.visualstudio.com/docs/?dv=win64user,https://chat.openai.com/auth/login",
              },
              Reference: "03122022_600",
            },
          ],
        }),
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        const data = await response.json();
        console.log(
          "--------------------------------------------------------------------"
        );
        console.log(
          "--------------------------------------------------------------------"
        );
        console.log(
          "--------------------------------------------------------------------"
        );
        console.log(
          "--------------------------------------------------------------------"
        );
        console.log(data);
        console.log(
          "--------------------------------------------------------------------"
        );
        console.log(
          "--------------------------------------------------------------------"
        );
        console.log(
          "--------------------------------------------------------------------"
        );
        console.log(
          "--------------------------------------------------------------------"
        );
        /*
            console.log(`Classification result for url ${blogURL["blog_url"]}:`, data.response);

            // Update data based on blog_url
            updatedData = updatedData.map((item) =>
                item.blog_url === blogURL["blog_url"] ? { ...item, classification: data.response } : item
            );

            set_filtered_scored_data(updatedData);
            */
      } else {
        console.error(`Error in BOT processing : - `, response.statusText);
      }
    } catch (error) {
      console.error(`Error in BOT processing : - `, error);
    }
  };

  const [value, setValue] = React.useState("1");
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <>
      <div className="classify_page_container">
        <div className="data_option_section_container">
          <TabContext value={value}>
            <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                <Tab label="Previously Classified data" value="1" />
                <Tab label="Upload Classified data" value="3" />
              </TabList>
            </Box>

            <TabPanel value="1">
              <div style={{ margin: "auto", width: "fit-content" }}>
                <Button
                  component="label"
                  variant="contained"
                  endIcon={<DownloadIcon />}
                  onClick={load_previously_classified_data}
                >
                  Load
                </Button>
              </div>
            </TabPanel>

            <TabPanel value="3">
              <div style={{ margin: "auto", width: "fit-content" }}>
                <Button
                  component="label"
                  variant="contained"
                  startIcon={<CloudUploadIcon />}
                >
                  Upload file
                  <Input
                    type="file"
                    id="file-input"
                    onChange={upload_user_uploaded_file}
                    key={file_upload_field_key}
                    style={{
                      clip: "rect(0 0 0 0)",
                      clipPath: "inset(50%)",
                      height: 1,
                      overflow: "hidden",
                      position: "absolute",
                      bottom: 0,
                      left: 0,
                      whiteSpace: "nowrap",
                      width: 1,
                    }}
                  />
                </Button>
              </div>
            </TabPanel>
          </TabContext>
        </div>
        <div className="data_table_section_container">
          {initial_data_loaded_identified_paragraphs === 1 ? (
            <div style={{ position: "relative", width: "100%", height: "70%" }}>
              {/* <Skeleton
              variant="rectangular"
              width="100%"
              height="50vh"
            /> */}
              <CircularProgress />
            </div>
          ) : (
            <TableWithPagination
            key={filtered_scored_data.length}

              data={filtered_scored_data}
              fields={[
                {
                  type: "action",
                  field: "delete",
                  headerName: "Delete",
                  width: 65,
                  renderCell: (params) => {
                    return (
                      <>
                        {/* <div>&nbsp;</div> */}
                        <Tooltip title="Delete">
                          <IconButton
                          // onClick={() => {
                          //   handleDelete(
                          //     params.search_result_id
                          //   );
                          // }}
                          >
                            <MdOutlineDeleteOutline className="table_action_icon_link_exchange_row" />
                          </IconButton>
                        </Tooltip>
                      </>
                    );
                  },
                },

                {
                  type: "field",
                  field: "client_url",
                  headerName: "Client URL",
                  search_type: "text_search",
                  minWidth: 200,
                  width: 200,
                  maxWidth: 500,
                },
                {
                  type: "field",
                  field: "blog_url",
                  headerName: "Blog URL",
                  search_type: "text_search",
                  minWidth: 200,
                  width: 300,
                  maxWidth: 1000,
                },
                {
                  type: "field",
                  field: "blog_title",
                  headerName: "Blog Title",
                  search_type: "text_search",
                  minWidth: 200,
                  width: 300,
                  maxWidth: 1000,
                },
                {
                  type: "field",
                  field: "classification",
                  search_type: "text_search",
                  headerName: "Page Type",
                  minWidth: 100,
                  width: 300,
                  maxWidth: 1000,
                },
                {
                  type: "field",
                  field: "similarity_score",
                  headerName: "Similarity Score",
                  search_type: "number_search",
                  minWidth: 100,
                  width: 200,
                  maxWidth: 500,
                },
                {
                  type: "field",
                  field: "traffic",
                  headerName: "traffic",
                  search_type: "number_search",
                  minWidth: 100,
                  width: 200,
                  maxWidth: 500,
                },
                {
                  type: "id",
                  field: "search_result_id",
                  headerName: "ID",
                  minWidth: 1,
                  width: 1,
                  maxWidth: 90,
                },
              ]}
              bulk_actions={[
                {
                  action: "save",
                  renderCell: (params) => {
                    return (
                      <div
                        onClick={() => {
                          handleSaveMultiple(params);
                        }}
                        className="save_multiple"
                        style={{ backgroundImage: `url(${save_button_icon})` }}
                      ></div>
                    );
                  },
                },
                /*{
                action: "classify",
                renderCell: (params) => {
                  return (
                    <div onClick={() => {handleClassifyMultiple(params)}} className="bulk_classify_button" style={{ backgroundImage: `url(${page_classification_icon})` }}></div>
                  )
                }
              },*/
                {
                  action: "PageQualityCheck",
                  renderCell: (params) => {
                    return (
                      <div
                        onClick={() => {
                          handlePageQualityCheck(params);
                        }}
                        className="bulk_PageQualityCheck_button"
                        style={{
                          backgroundImage: `url(${page_classification_icon})`,
                        }}
                      ></div>
                    );
                  },
                },
              ]}
            />
          )}
        </div>
      </div>
    </>
  );
};
export default PageQualityCheckComponent;

/*
    <Button
      sx={{
        marginRight: '10px'
      }}
      variant="contained"
      color="secondary"
      size="small"
      onClick={() => {classify()}}
    >
    Classify
    </Button>
*/
