import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  withRouter,
  useParams,
} from "react-router-dom";
//import {Router, browserHistory} from 'react-router';

import {
  Box,
  Grid,
  Button,
  IconButton,
  Modal,
  Typography,
  Breadcrumbs,
  Link,
  Snackbar,
  Tooltip,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Skeleton,
} from "@mui/material";
import { TextareaAutosize } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import CloseIcon from "@mui/icons-material/Close";

import "./FindContactsFromLinkComponent.css";
import TableWithPagination from "./../../../utils/TableWithPagination";
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
import { MdMan2 } from "react-icons/md";
import { GiFlatHammer } from "react-icons/gi";
import { FaProjectDiagram, FaSave } from "react-icons/fa";
import {
  CLEAR_FIND_CONTACT_FROM_LINK_DATA,
  GET_CREDIT_POINTS_BY_BUTTON_NAME,
  GET_FIND_CONTACT_FROM_LINK_DATA,
  GET_POINTS_DETAILS,
  SCRAPE_URLS,
  CHECK_CREDIT_CONDITION,
  SCRAPE_WEBSITE_TO_FIND_AUTHOR,
  UPDATE_CREDIT_POINT_OF_DEDUCTION,
  USER_UPLOAD_FIND_CONTACT_FROM_LINK_DATA,
  getFullApiUrl,
} from "../../../utils/apiPath";
import { BsHammer } from "react-icons/bs";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import LoopIcon from "@mui/icons-material/Loop";

const FindContactsFromLinkComponent = ({ toggleTheme }) => {
  const { campaignId } = useParams();
  const [initial_loader_open, set_initial_loader_open] = React.useState(false);

  const [totalCreditAfterDeduction, setTotalCreditAfterDeduction] = useState(0);
  const [creditWeekLimitAfterDeduction, setCreditWeekLimitAfterDeduction] =
    useState(0);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [errorMassageForTotalCredits, setErrorMassageForTotalCredits] =
    useState("");
  const [errorMassageForCreditWeekLimits, setErrorMassageForCreditWeekLimits] =
    useState("");

  const [totalCreditPoints, setTotalCreditPoints] = useState(0);
  const [creditPointsLimit, setCreditPointsLimit] = useState(0);
  const [deductionPoints, setDeductionPoints] = useState(1);

  const [show_selectScrapOption_Section, set_show_selectScrapOption_Section] =
    useState(false);
  const [selected_scrapper_option, set_selected_scrapper_option] = useState("");
  const [scrapingPerformed, setScrapingPerformed] = useState(false);
  const [selectedRecords, setSelectedRecords] = useState([]);
  const [selected_url_for_scrape, set_selected_url_for_scrape] = useState([]);
  const [
    show_should_we_scrape_urls_modal,
    set_show_should_we_scrape_urls_modal,
  ] = useState(false);

  const [editableScrappedData, set_editableScrappedData] = useState("");

  const [scrapping_field, set_scrapping_field] = useState({});

  // const [tableData, set_search_data] = useState([]);
  const [showEditScrapSection, set_showEditScrapSection] = useState(false);

  const [listofURL, setListofURL] = useState("");
  const [tableData, setTableData] = useState([]);
  const [
    show_should_we_classify_urls_modal,
    set_show_should_we_classify_urls_modal,
  ] = useState(false);
  const [loading, set_loading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const changeListofURLTextArea = (event) => {
    setListofURL(event.target.value);
  };
  const createScrappingJobsTable = () => {
    const urlsArray = listofURL
      .split(/\r?\n/)
      .filter((url) => url.trim() !== "");
    const uniqueURLs = [...new Set(urlsArray)];

    const newData = uniqueURLs.reduce((accumulator, url) => {
      const isDuplicate = tableData.some(
        (item) => item.blog_url === url.trim()
      );

      if (!isDuplicate) {
        accumulator.push({
          search_result_id: tableData.length + accumulator.length + 1,
          blog_url: url.trim(),
          blog_url_data: "",
          blog_title: "",
          classification: "",
          author_name: "",
          contact_number: "",
          email_id: "",
          twitter_link: "",
          linkedin_link: "",
        });
      }

      return accumulator;
    }, []);

    setTableData([...tableData, ...newData]);
  };
  // const createScrappingJobsTable = () => {
  //   console.log("List of URLs:", listofURL);

  //   const urlsArray = listofURL
  //     .split(/\r?\n/)
  //     .filter((url) => url.trim() !== "");
  //   console.log("Filtered URLs:", urlsArray);

  //   const uniqueURLs = [...new Set(urlsArray)];
  //   console.log("Unique URLs:", uniqueURLs);

  //   const newData = uniqueURLs.reduce((accumulator, url) => {
  //     const isDuplicate = tableData.some(
  //       (item) => item.blog_url === url.trim()
  //     );

  //     if (!isDuplicate) {
  //       accumulator.push({
  //         search_result_id: tableData.length + accumulator.length + 1,
  //         blog_url: url.trim(),
  //         blog_url_data: "",
  //         blog_title: "",
  //         classification: "",
  //         author_name: "",
  //         contact_number: "",
  //         email_id: "",
  //         twitter_link: "",
  //         linkedin_links: "",

  //       });
  //     }

  //     return accumulator;
  //   }, []);

  //   console.log("New data to be added:", newData);

  //   setTableData([...tableData, ...newData]);
  //   console.log("Updated table data:", tableData);
  // };

  const handleClose = () => {
    set_showEditScrapSection(false);
  };

  const fetchpointsDetails = async (points_to_be_deducted) => {
    try {
      const response = await fetch(getFullApiUrl(CHECK_CREDIT_CONDITION), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          user_id: localStorage.getItem("user_id"),
          campaign_id: campaignId,
          points_to_be_deducted: points_to_be_deducted,
        }),
      });
      console.log("button");

      const data = await response.json();
      setCreditPointsLimit(data.credits_limit_left_this_week_before_deduction);
      setTotalCreditPoints(data.owner_credits_left_before_deduction);
      setTotalCreditAfterDeduction(data.owner_credits_left_after_deduction);
      setCreditWeekLimitAfterDeduction(
        data.credits_limit_left_this_week_after_deduction
      );
      return data;
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const getCreditPointToDeductByButton = async (button) => {
    try {
      const response = await fetch(
        getFullApiUrl(GET_CREDIT_POINTS_BY_BUTTON_NAME),
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            button_name: button,
          }),
        }
      );
      console.log("button");

      const data = await response.json();

      return data;
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const updateCreditPoints = async (points) => {
    try {
      const deductionResponse = await fetch(
        `${getFullApiUrl(
          UPDATE_CREDIT_POINT_OF_DEDUCTION
        )}?campaign_id=${campaignId}&user_id=${localStorage.getItem(
          "user_id"
        )}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            points_deducted: points,
          }),
        }
      );

      if (!deductionResponse.ok) {
        throw new Error("Failed to deduct points");
      }
      // setNotificationMessage(`${points} Points deducted successfully`);
      // setOpenSnackbar(true);
      fetchpointsDetails(0);
      // Close the Modal after deduction
    } catch (error) {
      console.error("Error deducting points:", error);
    }
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const changeEditScrappedData = (event) => {
    var temp_editableScrappedData = { ...editableScrappedData };
    console.log(
      "-------------------------------------------------------------------------------"
    );
    console.log("temp_editableScrappedData");
    console.log(temp_editableScrappedData);
    //temp_editableScrappedData["link_from_data"] = event.target.value;
    temp_editableScrappedData[scrapping_field["data_field"]] =
      event.target.value;
    set_editableScrappedData(temp_editableScrappedData);
  };

  const handleDeleteMultiple = (idsToDelete) => {
    // Filter out the deleted records
    const newData = tableData.filter(
      (item) => !idsToDelete.includes(item.search_result_id)
    );

    // Assign new search_result_id based on the remaining URLs
    const updatedData = newData.map((item, index) => {
      return {
        ...item,
        search_result_id: index + 1,
      };
    });

    setTableData(updatedData);
    setSelectedRecords([]); // Clear selected records after deletion
  };

  const [selected_blog_url_for_scrap, set_selected_blog_url_for_scrap] =
    useState([]);

  const handle_should_we_scrape_urls_close = () => {
    set_show_should_we_scrape_urls_modal(false);
    setTotalCreditPoints(0);
    setCreditPointsLimit(0);
    setErrorMessage("");
    setCreditWeekLimitAfterDeduction(0);
    setErrorMassageForCreditWeekLimits("");
    setErrorMassageForTotalCredits("");
    setTotalCreditAfterDeduction(0);
  };

  const validate_scrap_urls = async (params, button) => {
    // Ensure params is valid and an array
    if (!params || !Array.isArray(params) || params.length === 0) {
      console.error("Invalid or empty params:", params);
      setNotificationMessage("No URLs selected for scrapping.");
      setOpenSnackbar(true); // Show a notification to the user
      return; // Exit early to avoid further processing
    }
    // Fetch user credit points and credit limits

    const deduction_point = await getCreditPointToDeductByButton(button);

    // Validate the response from getCreditPointToDeductByButton
    // const deduction_point_raw = deduction_point_data?.credit_points;
    // const deduction_point = (deduction_point_raw) || 1; // Default to 1 if not found or invalid

    const totalRows = params.length; // Number of selected items
    // const credits_to_be_deducted = totalRows * deduction_point; // Total points to deduct
    const credits_to_be_deducted = parseFloat(
      (totalRows * parseFloat(deduction_point)).toFixed(3)
    );

    set_selected_blog_url_for_scrap(params);
    set_selected_url_for_scrape(params);

    setDeductionPoints(credits_to_be_deducted);
    const data = await fetchpointsDetails(credits_to_be_deducted);
    const threshold_value = data.threshold_value;

    // Check conditions separately
    let shouldOpenModal = false;

    if (
      !data.does_owner_have_enough_credits &&
      !data.does_user_have_enough_credits_week_limit
    ) {
      setErrorMassageForTotalCredits("Insufficient Credit Points");
      setErrorMassageForCreditWeekLimits("Insufficient Weekly credits limit");
      shouldOpenModal = true;
    } else if (!data.does_owner_have_enough_credits) {
      setErrorMassageForTotalCredits("Insufficient Credit Points");
      shouldOpenModal = true;
    } else if (!data.does_user_have_enough_credits_week_limit) {
      setErrorMassageForCreditWeekLimits("Insufficient Weekly credits limit");
      shouldOpenModal = true;
    } else if (credits_to_be_deducted === 0) {
      setErrorMessage(
        "There are no blog URLs available for scraping at the moment."
      );
      shouldOpenModal = true;
    }

    // Check if modal should be opened or execute processScrapeAndUpdate
    if (shouldOpenModal) {
      set_show_should_we_scrape_urls_modal(true);
    } else if (threshold_value > credits_to_be_deducted) {
      await handleScrapMultiple(selected_url_for_scrape);
      await updateCreditPoints(credits_to_be_deducted);
    } else {
      set_show_should_we_scrape_urls_modal(true);
    }
  };

  const processScrapeAndUpdate = async () => {
    await handleScrapMultiple(selected_url_for_scrape);
    await updateCreditPoints(deductionPoints);
  };

  const handleScrapMultiple = async (recordIds) => {
    set_show_should_we_scrape_urls_modal(false);

    const apiEndpoints = [
      "https://5po5s0eo70.execute-api.us-east-2.amazonaws.com",
      "https://l4bzdp8da9.execute-api.us-east-2.amazonaws.com",
      "https://h0fary5mbh.execute-api.us-east-2.amazonaws.com",
      "https://o0od5remnh.execute-api.us-east-2.amazonaws.com",
      "https://g1gpp5coqe.execute-api.us-east-2.amazonaws.com",
      "https://xmmi48n6i6.execute-api.us-east-2.amazonaws.com",
      "https://7wgl5kfmh3.execute-api.us-east-2.amazonaws.com",
      "https://3urq85a0hg.execute-api.us-east-2.amazonaws.com",
      "https://5slhaq9nx5.execute-api.us-east-2.amazonaws.com",
      "https://u9c84acq7l.execute-api.us-east-2.amazonaws.com",
    ];

    // Create a copy of the existing table data
    const updatedData = [...tableData];

    // Set batch size and rate-limiting
    const batchSize = 10; // Number of records to process in each batch
    const concurrentBatchLimit = 1; // Limit the number of concurrent batches

    // Loop through the record IDs in batches
    for (let i = 0; i < recordIds.length; i += batchSize) {
      // Get the current batch of record IDs
      const currentBatchIds = recordIds.slice(i, i + batchSize);

      // Create promises for asynchronous fetch operations
      const fetchPromises = currentBatchIds.map(async (id) => {
        const record = updatedData.find((item) => item.search_result_id === id);
        if (!record) {
          console.error(`Record with ID ${id} not found.`);
          return;
        }

        // Select an API endpoint based on the batch index
        const apiIndex = i % apiEndpoints.length;
        const requestData = {
          request_data: {
            urls: [record.blog_url],
            scrapper: "BeautifulSoup",
          },
        };

        try {
          // Make a POST request to scrape data
          const response = await fetch(apiEndpoints[apiIndex], {
            method: "POST",
            body: JSON.stringify(requestData),
            headers: {
              "Content-Type": "application/json",
            },
          });

          if (!response.ok) {
            throw new Error(`Failed to fetch data for record ID ${id}`);
          }

          const data = await response.json();
          if (
            data.hasOwnProperty("response") &&
            Array.isArray(data["response"]) &&
            data["response"].length > 0
          ) {
            const firstResponse = data["response"][0];
            const scrapedData = firstResponse["text"];

            // Update the record with the scraped data
            record.blog_url_data = scrapedData ? scrapedData : "Not available";

            const recordIndex = updatedData.findIndex(
              (item) => item.search_result_id === id
            );
            if (recordIndex !== -1) {
              updatedData[recordIndex] = { ...record };
            } else {
              console.error(`Record with ID ${id} not found in updatedData.`);
            }
          }
        } catch (error) {
          console.error(`Error scraping data for record ID ${id}:`, error);
        }
      });

      // Wait for all fetch promises in the current batch to complete
      await Promise.all(fetchPromises);

      // Add a delay to avoid rate-limiting or excessive API requests
      if (currentBatchIds.length > 10) {
        await new Promise((resolve) => setTimeout(resolve, 5000));
      }
    }

    // Update the table data state with the scraped data
    setTableData(updatedData);
  };

  const editScrapedData = (record_id, field, data_field) => {
    console.log(
      "-------------------------------------------------------------------------------"
    );
    console.log("record_id");
    console.log(record_id);
    const editableScrappedData_temp = tableData.find(
      (item) => item.search_result_id === record_id
    );
    console.log(
      "-------------------------------------------------------------------------------"
    );
    console.log("editableScrappedData_temp");
    console.log(editableScrappedData_temp);
    set_editableScrappedData(editableScrappedData_temp);
    console.log({ field: field, data_field: data_field });
    console.log(editableScrappedData[scrapping_field["data_field"]]);

    set_scrapping_field({ field: field, data_field: data_field });
    set_showEditScrapSection(true);
  };

  // Author....

  const [show_should_we_get_author_modal, set_show_should_we_get_author_modal] =
    useState(false);

  const [selected_url_to_get_author, set_selected_url_to_get_author] = useState(
    []
  );

  const handle_should_we_get_author_close = () => {
    set_show_should_we_get_author_modal(false);
    setTotalCreditPoints(0);
    setCreditPointsLimit(0);
    setErrorMessage("");
    setErrorMassageForCreditWeekLimits("");
    setErrorMassageForTotalCredits("");
    setCreditWeekLimitAfterDeduction(0);
    setTotalCreditAfterDeduction(0);
  };

  const handleClassifyMultipleAuthor = async (selectedIds) => {
    set_show_should_we_get_author_modal(false);

    // Get unique blog URLs from selected IDs
    const uniqueLinkFromValues = [
      ...new Set(
        selectedIds.map(
          (id) =>
            tableData.find((item) => item.search_result_id === id)?.blog_url
        )
      ),
    ];

    const totalRequests = uniqueLinkFromValues.length;
    const batchSize = 10;
    const totalBatches = Math.ceil(totalRequests / batchSize);

    // Create a copy of the table data to update
    let updatedData = [...tableData];

    const processBatch = async (batchIndex) => {
      const startIndex = batchIndex * batchSize;
      const endIndex = Math.min(startIndex + batchSize, totalRequests);
      const batchLinkValues = uniqueLinkFromValues.slice(startIndex, endIndex);

      console.log("Batch Link Values:", batchLinkValues);

      // Process each blog URL in the current batch
      for (const blog_url of batchLinkValues) {
        console.log("Processing linkValue:", blog_url);
        const rowData = tableData.find((item) => item.blog_url === blog_url);
        console.log("Row Data:", rowData);
        const blog_url_data = rowData?.blog_url_data;

        try {
          console.log("Fetching author info for URL:", blog_url);
          const response = await fetch(
            getFullApiUrl(SCRAPE_WEBSITE_TO_FIND_AUTHOR),
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                link_from_data: blog_url_data,
                link_from: blog_url,
              }),
            }
          );

          if (!response.ok) {
            console.error("Failed to fetch author info for URL:", blog_url);
            continue;
          }

          const data = await response.json();
          console.log("Fetched data:", data);

          // Update the corresponding row in the updatedData array
          updatedData = updatedData.map((item) => {
            if (item.blog_url === blog_url) {
              return {
                ...item,
                author_name: data.author_name || "Unknown Author",
                contact_number: data.contact_number || "N/A",
                email_id: data.email_id || "N/A",
                linkedin_link: data.linkedin_link || "N/A",
                twitter_link: data.twitter_link || "N/A",
                contact_link: data.contact_link || "N/A",
              };
            }
            return item;
          });
        } catch (error) {
          console.error("Error fetching author info:", error);
        }
      }

      // Update the table data state with the new updatedData array
      setTableData(updatedData);

      // Process the next batch if there are more batches to process
      if (startIndex + batchSize < totalRequests) {
        processBatch(batchIndex + 1);
      } else {
        // Optionally handle completion
        console.log("All batches processed.");
      }
    };

    // Start processing the first batch
    processBatch(0);
  };

  const validate_get_author = async (params, button) => {
    if (!params || !Array.isArray(params) || params.length === 0) {
      console.error("Invalid or empty params:", params);
      return; // Exit the function early
    }

    // Extracting search_result_id list from params
    const selectedIds = params;
    const totalRows = selectedIds.length;
    set_selected_url_to_get_author(params);

    // set_selected_url_for_scrape(params);

    //console.log(uniqueLinkWithFields);
    // const totalRows = uniqueLinkWithFields.length;
    const deduction_point = await getCreditPointToDeductByButton(button);

    // const credits_to_be_deducted = totalRows * parseInt(deduction_point);
    const credits_to_be_deducted = parseFloat(
      (totalRows * parseFloat(deduction_point)).toFixed(3)
    );
    setDeductionPoints(credits_to_be_deducted);
    const data = await fetchpointsDetails(credits_to_be_deducted);
    const threshold_value = data.threshold_value;

    // Check conditions separately
    let shouldOpenModal = false;

    if (
      !data.does_owner_have_enough_credits &&
      !data.does_user_have_enough_credits_week_limit
    ) {
      setErrorMassageForTotalCredits("Insufficient Credit Points");
      setErrorMassageForCreditWeekLimits("Insufficient Weekly credits limit");
      shouldOpenModal = true;
    } else if (!data.does_owner_have_enough_credits) {
      setErrorMassageForTotalCredits("Insufficient Credit Points");
      shouldOpenModal = true;
    } else if (!data.does_user_have_enough_credits_week_limit) {
      setErrorMassageForCreditWeekLimits("Insufficient Weekly credits limit");
      shouldOpenModal = true;
    } else if (credits_to_be_deducted === 0) {
      setErrorMessage(
        "There are no blog URLs available for scraping at the moment."
      );
      shouldOpenModal = true;
    }

    // Check if modal should be opened or execute processScrapeAndUpdate
    if (shouldOpenModal) {
      set_show_should_we_get_author_modal(true);
    } else if (threshold_value > credits_to_be_deducted) {
      await handleClassifyMultipleAuthor(selected_url_to_get_author);
      await updateCreditPoints(deductionPoints);
    } else {
      set_show_should_we_get_author_modal(true);
    }
  };

  const processGetAuthorAndUpdate = async () => {
    await handleClassifyMultipleAuthor(selected_url_to_get_author);
    await updateCreditPoints(deductionPoints);
  };

  // classify.....
  const [selected_url_to_classify, set_selected_url_to_classify] = useState([]);
  const [progress_classify, set_progress_classify] = useState(0);

  const handle_should_we_classify_urls_close = () => {
    set_show_should_we_classify_urls_modal(false);
    setTotalCreditPoints(0);
    setCreditPointsLimit(0);
    setErrorMessage("");
    setCreditWeekLimitAfterDeduction(0);
    setTotalCreditAfterDeduction(0);
    setErrorMassageForCreditWeekLimits("");
    setErrorMassageForTotalCredits("");
  };

  const validate_classify_urls = async (params, button) => {
    if (!params || !Array.isArray(params) || params.length === 0) {
      console.error("Invalid or empty params:", params);
      return; // Exit the function early
    }

    // Extracting search_result_id list from params
    const selectedIds = params;

    const totalRows = selectedIds.length;

    set_selected_url_to_classify(params);

    const deduction_point = await getCreditPointToDeductByButton(button);

    var not_scrapped_count = totalRows;

    // const deduction_point = 1; // Assuming a fixed deduction point for now
    // const credits_to_be_deducted =
    //   not_scrapped_count * parseInt(deduction_point);
    const credits_to_be_deducted = parseFloat(
      (not_scrapped_count * parseFloat(deduction_point)).toFixed(3)
    );
    setDeductionPoints(credits_to_be_deducted);
    const data = await fetchpointsDetails(credits_to_be_deducted);
    const threshold_value = data.threshold_value;

    // Check conditions separately
    let shouldOpenModal = false;

    if (
      !data.does_owner_have_enough_credits &&
      !data.does_user_have_enough_credits_week_limit
    ) {
      setErrorMassageForTotalCredits("Insufficient Credit Points");
      setErrorMassageForCreditWeekLimits("Insufficient Weekly credits limit");
      shouldOpenModal = true;
    } else if (!data.does_owner_have_enough_credits) {
      setErrorMassageForTotalCredits("Insufficient Credit Points");
      shouldOpenModal = true;
    } else if (!data.does_user_have_enough_credits_week_limit) {
      setErrorMassageForCreditWeekLimits("Insufficient Weekly credits limit");
      shouldOpenModal = true;
    } else if (credits_to_be_deducted === 0) {
      setErrorMessage(
        "There are no blog URLs available for scraping at the moment."
      );
      shouldOpenModal = true;
    }

    // Check if modal should be opened or execute processScrapeAndUpdate
    if (shouldOpenModal) {
      set_show_should_we_classify_urls_modal(true);
    } else if (threshold_value > credits_to_be_deducted) {
      await handleClassifyMultiple(selected_url_to_classify);
      await updateCreditPoints(deductionPoints);
    } else {
      set_show_should_we_classify_urls_modal(true);
    }

    // If all conditions passed, open the modal
    set_show_should_we_classify_urls_modal(true);
  };

  const processClassificatonAndUpdate = async () => {
    await handleClassifyMultiple(selected_url_to_classify);
    await updateCreditPoints(deductionPoints); // Deduct points only if the process was successful
  };

  const get_prompt = (blog_url) => {
    //console.log(blog_url);
    var classification_prompt =
      "\nThe URL of the webpage is " +
      blog_url["link_from"] +
      "\n" +
      "The webpage title is " +
      blog_url["title"] +
      "\n\n" +
      "Based on the above webpage URL and webpage title, now classify the webpage in one of the following classes:\n" +
      //"Based on the above webpage URL, now classify the webpage in one of the following classes:\n" +
      "- About Us Page\n" +
      "- Blog Page\n" +
      "- Contact Us Page\n" +
      "- FAQ Page\n" +
      "- Home Page\n" +
      "- Landing Page\n" +
      "- News Page\n" +
      "- Privacy Policy Page\n" +
      "- Service Page\n" +
      "- Terms of Service Page\n" +
      "- Testimonials/Reviews Page\n" +
      "- Product Page\n" +
      "- Ecommerce Product Page\n" +
      "- Sales Page\n" +
      "- Forum Page\n" +
      "- Job Listing Page\n" +
      "- Category Page\n" +
      "- Tag Page\n" +
      "- Ecommerce Category Page\n" +
      "- Blog Page of Product Listicles\n" +
      "- Directory Listing Page\n" +
      "- Pricing Page\n" +
      "- Resource Blog Page\n" +
      "- Profile Page\n" +
      "- Product Listicles Blog Page\n" +
      "- Help Desk Pages\n\n" +
      "Remember, I want only one class without any explanation.";
    //console.log(classification_prompt);
    return classification_prompt;
  };

  const classify_apiEndpoints = [
    "https://m5p5ee4g5l.execute-api.us-east-2.amazonaws.com",
  ];

  const handleClassifyMultiple = async (record_ids) => {
    set_show_should_we_classify_urls_modal(false);

    set_progress_classify(0);
    let filteredDataWithRecordIds = tableData.filter((item) =>
      record_ids.includes(item.search_result_id)
    );
    //let uniqueBlogURLs = [...new Set(filteredDataWithRecordIds.map(item => item.link_from))];
    let BlogURLs_list = [
      ...new Set(
        filteredDataWithRecordIds.map((item) => ({
          blog_url: item.blog_url,
          title: item.title,
        }))
      ),
    ];
    let uniqueBlogURLs = BlogURLs_list.filter(
      (value, index, self) =>
        index === self.findIndex((obj) => obj.blog_url === value.blog_url)
    );

    //console.log(BlogURLs_list);
    //console.log(uniqueBlogURLs);

    const totalRows = uniqueBlogURLs.length;
    const batchSize = 10; // Number of API calls per batch
    const totalBatches = Math.ceil(totalRows / batchSize);
    console.log("-------------------------------------");
    //console.log(filteredDataWithRecordIds);
    //console.log(uniqueBlogURLs);

    let updatedData = [...tableData];

    const classifyBatch = async (batchIndex) => {
      const startIndex = batchIndex * batchSize;
      const endIndex = Math.min(startIndex + batchSize, totalRows);
      const batchUniqueBlogURLs = uniqueBlogURLs.slice(startIndex, endIndex);

      //const apiIndex = batchIndex % classify_apiEndpoints.length; // Rotating through API endpoints

      const classifyURL = async (blogURL) => {
        const index = uniqueBlogURLs.indexOf(blogURL);
        const record_id = record_ids[index];
        const classification_prompt = get_prompt(blogURL);
        const apiIndex = index % classify_apiEndpoints.length;

        const currentProgress =
          ((startIndex + batchUniqueBlogURLs.indexOf(blogURL) + 1) /
            totalRows) *
          100;

        try {
          console.log(currentProgress);
          const response = await fetch(classify_apiEndpoints[apiIndex], {
            method: "POST",
            body: JSON.stringify({
              request_data: { prompt: classification_prompt },
            }),
            headers: {
              "Content-Type": "application/json",
            },
          });

          if (response.ok) {
            const data = await response.json();
            console.log(
              `Classification result for blog_url ${blogURL["link_from"]}:`,
              data.response
            );

            // Update data based on blog_url
            updatedData = updatedData.map((item) =>
              item.link_from === blogURL["link_from"]
                ? { ...item, classification: data.response }
                : item
            );

            setTableData(updatedData);
          } else {
            console.error(
              `Error classifying blog_url ${blogURL["blog_url"]}:`,
              response.statusText
            );
          }
          set_progress_classify(currentProgress);
        } catch (error) {
          console.error(
            `Error classifying blog_url ${blogURL["blog_url"]}:`,
            error
          );
          set_progress_classify(currentProgress);
        }
      };

      const classifyBatchURLs = batchUniqueBlogURLs.map(classifyURL);
      await Promise.all(classifyBatchURLs);

      if (startIndex + batchSize < totalRows) {
        classifyBatch(batchIndex + 1); // Proceed to the next batch
      } else {
        set_progress_classify(100); // Set progress to 100% when processing is complete
      }
    };

    classifyBatch(0);
  };

  const handleSaveMultiple = async (record_ids) => {
    set_loading(true);
    var filteredDataWithRecordIds = tableData.filter((item) =>
      record_ids.includes(item.search_result_id)
    );
    console.log(filteredDataWithRecordIds);
    var selectedFields = filteredDataWithRecordIds.map((item) => ({
      blog_url: item.blog_url,
      blog_url_data: item.blog_url_data,
      blog_title: item.blog_title,
      page_type: item.classification,
      author_name: item.author_name,
      contact_number: item.contact_number,
      email_id: item.email_id,
      twitter_link: item.twitter_link,
      linkedin_link: item.linkedin_link,
    }));
    //console.log(selectedFields);
    fetch(getFullApiUrl(USER_UPLOAD_FIND_CONTACT_FROM_LINK_DATA), {
      method: "POST",
      body: JSON.stringify({
        request_data: { campaign_id: campaignId, data: selectedFields },
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("File uploaded successfully:", data);
        set_loading(false);
      })
      .catch((error) => {
        console.error("Error uploading file:", error);
      });
  };

  const fetchFindContactFromLinkData = async () => {
    set_initial_loader_open(true);
    fetch(getFullApiUrl(GET_FIND_CONTACT_FROM_LINK_DATA), {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ request_data: { campaign_id: campaignId } }),
    })
      .then((response) => response.json())
      .then((data) => {
        // Process the fetched data
        const updatedRows = data.data.map((row, index) => ({
          ...row,
          search_result_id: index + 1,
        }));
        setTableData(updatedRows);

        const urlList = updatedRows.map((row) => row.blog_url).join("\n");
        setListofURL(urlList);
        set_initial_loader_open(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        setErrorMessage("Failed to fetch data");
      });
  };
  const fetchToClearFindContactFromLinkData = async () => {
    set_initial_loader_open(true);
    fetch(getFullApiUrl(CLEAR_FIND_CONTACT_FROM_LINK_DATA), {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ request_data: { campaign_id: campaignId } }),
    })
      .then((response) => response.json())
      .then((data) => {
        // Clear the table data and list of URLs
        setTableData([]);
        setListofURL("");
        set_initial_loader_open(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        setErrorMessage("Failed to fetch data");
        set_initial_loader_open(false);
      });
  };
  return (
    <>
      {initial_loader_open == true ? (
        <div style={{ position: "relative", width: "100%", height: "70%" }}>
          {/* <Skeleton
              variant="rectangular"
              width="100%"
              height="50vh"
            /> */}
          <CircularProgress />
        </div>
      ) : (
        <>
          <TextareaAutosize
            className="text_area_of_link_insertion"
            value={listofURL}
            onChange={changeListofURLTextArea}
            placeholder="Enter Client URLs one by one in each line"
            minRows={5}
            overflowY="auto"
            maxRows={20}
            style={{ width: "100%" }}
          />
          <div className="load_and_upload_button_container">
            <div>
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={createScrappingJobsTable}
                style={{ marginRight: "8px" }}
              >
                Load URLs into Table
              </Button>
            </div>
            <div className="load_clear_button_container">
              <div className="load_clear_button">
                <Button
                  component="label"
                  variant="contained"
                  startIcon={<LoopIcon />}
                  onClick={fetchFindContactFromLinkData}
                >
                  Load Previously Processed Data
                </Button>
              </div>
              <div className="load_clear_button">
                <Button
                  component="label"
                  variant="contained"
                  startIcon={<HighlightOffIcon />}
                  onClick={fetchToClearFindContactFromLinkData}
                >
                  Clear Previously Processed Data
                </Button>
              </div>
            </div>
          </div>
          <TableWithPagination
          file_name={"find_contact_from_link_component_data"}
            data={tableData}
            key={tableData.length}

            fields={[
              {
                type: "id",
                field: "search_result_id",
                headerName: "",
                minWidth: 1,
                width: 1,
                maxWidth: 90,
              },
              {
                type: "field",
                field: "blog_url",
                search_type: "text_search",
                headerName: "URL",
                minWidth: 200,
                width: 330,
                maxWidth: 1000,
              },
              {
                type: "action",
                field: "blog_url_action",
                headerName: " Scraped ?",
                renderCell: (params) => {
                  return (
                    <div
                      style={{
                        margin: "auto 0",
                        display: "flex",
                        justifyContent: "space-around",
                        width: "50%",
                        alignItems: "center",
                      }}
                    >
                      <div
                        onClick={() => {
                          editScrapedData(
                            params.search_result_id,
                            "blog_url",
                            "blog_url_data"
                          );
                        }}
                      >
                        <BsHammer />
                      </div>
                      {params.blog_url_data == "Not Analyzed" ? (
                        <div style={{ float: "left" }}>Not Analyzed</div>
                      ) : params.blog_url_data ? (
                        <div style={{ margin: "auto" }}>Scraped</div>
                      ) : (
                        <div style={{ margin: "auto" }}>Not Scraped</div>
                      )}
                    </div>
                  );
                },
                width: 110,
              },

              {
                type: "field",
                field: "blog_title",
                headerName: "Blog Title",
                minWidth: 100,
                width: 150,
                maxWidth: 1000,
                search_type: "text_search",
              },
              {
                type: "field",
                field: "classification",
                headerName: "Page Type",
                minWidth: 100,
                width: 150,
                maxWidth: 1000,
                search_type: "text_search",
              },
              {
                type: "field",
                field: "author_name",
                headerName: "author_name",
                search_type: "text_search",
                minWidth: 100,
                width: 200,
                maxWidth: 500,
              },
              {
                type: "field",
                field: "contact_number",
                search_type: "text_search",
                headerName: "contact_number",
                minWidth: 100,
                width: 200,
                maxWidth: 500,
              },
              {
                type: "field",
                field: "email_id",
                headerName: "email_id",
                search_type: "text_search",
                minWidth: 100,
                width: 200,
                maxWidth: 500,
              },
              {
                type: "field",
                search_type: "text_search",
                field: "twitter_link",
                headerName: "Twitter",
                minWidth: 100,
                width: 300,
                maxWidth: 500,
              },
              {
                type: "field",
                field: "linkedin_link",
                search_type: "text_search",
                headerName: "Linkedin",
                minWidth: 100,
                width: 400,
                maxWidth: 500,
              },
              // {
              //   type: "field",
              //   field: "contact_link",
              //   headerName: "contact Link",
              //   minWidth: 100,
              //   width: 200,
              //   maxWidth: 500,
              // },
            ]}
            bulk_actions={[
              {
                action: "save",
                renderCell: (params) => {
                  return (
                    <>
                      {loading === true ? (
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          disabled
                          endIcon={<CircularProgress size="10" />}
                        >
                          Saving . . . . . . . . . .
                        </Button>
                      ) : (
                        <Tooltip title="Save">
                          <IconButton
                            onClick={() => {
                              handleSaveMultiple(params);
                            }}
                          >
                            <FaSave className="table_action_icon_link_exchange_row" />
                          </IconButton>
                        </Tooltip>
                      )}
                    </>
                  );
                },
              },
              {
                action: "delete_multiple",
                renderCell: (params) => {
                  return (
                    <Tooltip title="Bulk Delete">
                      <IconButton
                        onClick={() => {
                          handleDeleteMultiple(params);
                        }}
                      >
                        <DeleteSweepIcon className="table_action_icon_link_exchange" />
                      </IconButton>
                    </Tooltip>
                  );
                },
              },

              {
                action: "scrape",
                renderCell: (params) => {
                  return (
                    <Tooltip title="scrape">
                      <IconButton
                        onClick={() => {
                          validate_scrap_urls(params, "Run Scrapper");
                        }}
                      >
                        <GiFlatHammer className="table_action_icon_link_exchange" />
                      </IconButton>
                    </Tooltip>
                  );
                },
              },

              {
                action: "classify",
                renderCell: (params) => {
                  return (
                    <Tooltip title="classify">
                      <IconButton
                        onClick={() => {
                          validate_classify_urls(params, "Classify URL");
                        }}
                      >
                        <FaProjectDiagram className="table_action_icon_link_exchange_row" />
                      </IconButton>
                    </Tooltip>
                  );
                },
              },
              {
                action: "Get Author",
                renderCell: (params) => {
                  return (
                    <Tooltip title="Get Author">
                      <IconButton
                        onClick={() => {
                          validate_get_author(params, "Get Author");
                        }}
                      >
                        <MdMan2 className="table_action_icon_link_exchange" />
                      </IconButton>
                    </Tooltip>
                  );
                },
              },
            ]}
          />
        </>
      )}

      <Modal
        open={showEditScrapSection}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box id="LinkExchange_model_box">
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </div>

          {showEditScrapSection ? (
            <Box className="modalContentCantainer">
              <div className="editable_scrapped_data_url">
                {editableScrappedData[scrapping_field["field"]]}
                <Tooltip title="Open in New Tab">
                  <OpenInNewIcon
                    fontSize="small"
                    className="OpenInNewIcon"
                    // onClick={(e) => {
                    //   e.preventDefault();
                    //   e.stopPropagation();
                    //   open_in_new_tab(
                    //     editableScrappedData[scrapping_field["field"]]
                    //   );
                    // }}
                  />
                </Tooltip>
              </div>
              <FormControl sx={{ width: "150px" }}>
                <InputLabel id="demo-simple-select-label">
                  Select Scraper
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Age"
                  onChange={(event) => {
                    //console.log(event.target.value);
                    set_selected_scrapper_option(event.target.value);
                  }}
                >
                  <MenuItem value="BeautifulSoup">BeautifulSoup</MenuItem>
                  <MenuItem value="Webcache">Webcache</MenuItem>
                  <MenuItem value="Selenium">Selenium</MenuItem>
                </Select>
              </FormControl>
              {selected_scrapper_option == "Selenium"
                ? "Note : using Selenium scrapper takes much time"
                : ""}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "100%",
                  margin: "20px 0px 8px 0px",
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  // onClick={() =>
                  //   api_call_scrape_urls(
                  //     editableScrappedData[scrapping_field["field"]]
                  //   )
                  // }
                  style={{ marginBottom: "1%" }}
                >
                  Run Scrapper
                </Button>
              </Box>
              <TextareaAutosize
                className="text_area_of_link_exchange"
                value={editableScrappedData[scrapping_field["data_field"]]}
                onChange={changeEditScrappedData}
                placeholder="Edit your scrapped text"
                minRows={5}
                overflowY="auto"
                maxRows={20}
                style={{ width: "100%", borderRadius: "5px" }}
              />

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "100%",
                  margin: "20px 0px 8px 0px",
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  // onClick={() => saveEditableScrappedData()}
                  // style={{ marginRight: "8px" }}
                >
                  Save Scrap Text
                </Button>
              </Box>
            </Box>
          ) : (
            <div></div>
          )}
        </Box>
      </Modal>

      <Modal
        open={show_should_we_scrape_urls_modal}
        onClose={handle_should_we_scrape_urls_close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box id="LinkExchange_model_box">
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <IconButton onClick={handle_should_we_scrape_urls_close}>
              <CloseIcon />
            </IconButton>
          </div>
          <div className="LinkExchange_modal_header">Current Credits</div>
          <div className="LinkExchange_modal_text_heading">
            Before proceeding with this action, please review the following:
          </div>
          <div className="LinkExchange_modal_text">
            Available Credits:{" "}
            <div className="LinkExchange_modal_number">
              <div
                style={{
                  color: errorMassageForTotalCredits ? "red" : "inherit",
                }}
              >
                {totalCreditPoints}
              </div>
            </div>
          </div>
          <div className="LinkExchange_modal_text">
            Weekly Credits Limit Left:{" "}
            <div className="LinkExchange_modal_number">
              <div
                style={{
                  color: errorMassageForCreditWeekLimits ? "red" : "inherit",
                }}
              >
                {creditPointsLimit}
              </div>
            </div>
          </div>
          {errorMessage !==
            "There are no blog URLs available for scraping at the moment." && (
            <div className="LinkExchange_modal_text_deduct">
              Performing this action will deduct{" "}
              <div className="LinkExchange_modal_deduct_number">
                {" "}
                {!isNaN(deductionPoints) ? deductionPoints : 0} credits{" "}
              </div>{" "}
              from your balance.
            </div>
          )}
          {(errorMessage ||
            errorMassageForTotalCredits ||
            errorMassageForCreditWeekLimits) && (
            <div
              style={{
                color: "red",
                textAlign: "center",

                fontWeight: "bolder",
                fontFamily: "monospace",
                fontSize: "larger",
              }}
            >
              <div>{errorMassageForTotalCredits}</div>
              <div>{errorMassageForCreditWeekLimits}</div>
              <div>{errorMessage}</div>
            </div>
          )}

          {!(
            errorMessage ||
            errorMassageForTotalCredits ||
            errorMassageForCreditWeekLimits
          ) && (
            <>
              <div className="LinkExchange_modal_header">After Deduction</div>
              <div className="LinkExchange_modal_text">
                Balance Credits :
                <div className="LinkExchange_modal_number">
                  {totalCreditPoints - deductionPoints}
                </div>
              </div>
              <div className="LinkExchange_modal_text">
                Weekly Credits Limit Left :{" "}
                <div className="LinkExchange_modal_number">
                  {creditPointsLimit - deductionPoints}
                </div>
              </div>

              <div className="LinkExchange_form_group">
                <Button
                  variant="contained"
                  size="small"
                  onClick={processScrapeAndUpdate}
                  color="primary"
                >
                  Confirm Action
                </Button>
              </div>
            </>
          )}
        </Box>
      </Modal>

      <Modal
        open={show_should_we_get_author_modal}
        onClose={handle_should_we_get_author_close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box id="LinkExchange_model_box">
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <IconButton onClick={handle_should_we_get_author_close}>
              <CloseIcon />
            </IconButton>
          </div>
          <div className="LinkExchange_modal_header">Current Credits</div>
          <div className="LinkExchange_modal_text_heading">
            Before proceeding with this action, please review the following:
          </div>
          <div className="LinkExchange_modal_text">
            Available Credits:{" "}
            <div className="LinkExchange_modal_number">
              <div
                style={{
                  color: errorMassageForTotalCredits ? "red" : "inherit",
                }}
              >
                {totalCreditPoints}
              </div>
            </div>
          </div>
          <div className="LinkExchange_modal_text">
            Weekly Credits Limit Left:{" "}
            <div className="LinkExchange_modal_number">
              <div
                style={{
                  color: errorMassageForCreditWeekLimits ? "red" : "inherit",
                }}
              >
                {creditPointsLimit}
              </div>
            </div>
          </div>
          {errorMessage !==
            "There are no blog URLs available for scraping at the moment." && (
            <div className="LinkExchange_modal_text_deduct">
              Performing this action will deduct{" "}
              <div className="LinkExchange_modal_deduct_number">
                {" "}
                {!isNaN(deductionPoints) ? deductionPoints : 0} credits{" "}
              </div>{" "}
              from your balance.
            </div>
          )}
          {(errorMessage ||
            errorMassageForTotalCredits ||
            errorMassageForCreditWeekLimits) && (
            <div
              style={{
                color: "red",
                textAlign: "center",

                fontWeight: "bolder",
                fontFamily: "monospace",
                fontSize: "larger",
              }}
            >
              <div>{errorMassageForTotalCredits}</div>
              <div>{errorMassageForCreditWeekLimits}</div>
              <div>{errorMessage}</div>
            </div>
          )}

          {!(
            errorMessage ||
            errorMassageForTotalCredits ||
            errorMassageForCreditWeekLimits
          ) && (
            <>
              <div className="LinkExchange_modal_header">After Deduction</div>
              <div className="LinkExchange_modal_text">
                Balance Credits :
                <div className="LinkExchange_modal_number">
                  {totalCreditAfterDeduction}
                </div>
              </div>
              <div className="LinkExchange_modal_text">
                Weekly Credits Limit Left :{" "}
                <div className="LinkExchange_modal_number">
                  {creditWeekLimitAfterDeduction}
                </div>
              </div>

              <div className="LinkExchange_form_group">
                <Button
                  variant="contained"
                  size="small"
                  onClick={processGetAuthorAndUpdate}
                  color="primary"
                >
                  Confirm Action
                </Button>
              </div>
            </>
          )}
        </Box>
      </Modal>

      <Modal
        open={show_should_we_classify_urls_modal}
        onClose={handle_should_we_classify_urls_close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box id="LinkExchange_model_box">
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <IconButton onClick={handle_should_we_classify_urls_close}>
              <CloseIcon />
            </IconButton>
          </div>
          <div className="LinkExchange_modal_header">Current Credits</div>
          <div className="LinkExchange_modal_text_heading">
            Before proceeding with this action, please review the following:
          </div>
          <div className="LinkExchange_modal_text">
            Available Credits:{" "}
            <div className="LinkExchange_modal_number">
              <div
                style={{
                  color: errorMassageForTotalCredits ? "red" : "inherit",
                }}
              >
                {totalCreditPoints}
              </div>
            </div>
          </div>
          <div className="LinkExchange_modal_text">
            Weekly Credits Limit Left:{" "}
            <div className="LinkExchange_modal_number">
              <div
                style={{
                  color: errorMassageForCreditWeekLimits ? "red" : "inherit",
                }}
              >
                {creditPointsLimit}
              </div>
            </div>
          </div>
          {errorMessage !==
            "There are no blog URLs available for scraping at the moment." && (
            <div className="LinkExchange_modal_text_deduct">
              Performing this action will deduct{" "}
              <div className="LinkExchange_modal_deduct_number">
                {" "}
                {!isNaN(deductionPoints) ? deductionPoints : 0} credits{" "}
              </div>{" "}
              from your balance.
            </div>
          )}
          {(errorMessage ||
            errorMassageForTotalCredits ||
            errorMassageForCreditWeekLimits) && (
            <div
              style={{
                color: "red",
                textAlign: "center",

                fontWeight: "bolder",
                fontFamily: "monospace",
                fontSize: "larger",
              }}
            >
              <div>{errorMassageForTotalCredits}</div>
              <div>{errorMassageForCreditWeekLimits}</div>
              <div>{errorMessage}</div>
            </div>
          )}

          {!(
            errorMessage ||
            errorMassageForTotalCredits ||
            errorMassageForCreditWeekLimits
          ) && (
            <>
              <div className="LinkExchange_modal_header">After Deduction</div>
              <div className="LinkExchange_modal_text">
                Balance Credits :
                <div className="LinkExchange_modal_number">
                  {totalCreditPoints - deductionPoints}
                </div>
              </div>
              <div className="LinkExchange_modal_text">
                Weekly Credits Limit Left :{" "}
                <div className="LinkExchange_modal_number">
                  {creditPointsLimit - deductionPoints}
                </div>
              </div>

              <div className="LinkExchange_form_group">
                <Button
                  variant="contained"
                  size="small"
                  onClick={processClassificatonAndUpdate}
                  color="primary"
                >
                  Confirm Action
                </Button>
              </div>
            </>
          )}
        </Box>
      </Modal>

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={notificationMessage}
      />
    </>
  );
};

export default FindContactsFromLinkComponent;
