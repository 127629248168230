import React, { useState, useEffect } from "react";
import { Button, Breadcrumbs, Link, Typography } from "@mui/material";
import { useNavigate, useParams, Link as RouterLink } from "react-router-dom";
import Header from "../common/Header/Header";
import Robo from "./../common/Robo";
import "./GetCampaignInChargeHistoryPage.css";
import LeftSectionWithMenu from "../common/Header/LeftSectionWithMenu/LeftSectionWithMenu";
import { NavigateNext } from "@mui/icons-material";
import {
  CHECK_USER_CAMPAIGN_ACCESS,
  GET_INDIVIDUAL_CAMPAIGN_DETAIL,
  GET_RECENT_IN_CHARGE_OF_CAMPAIGN,
  getFullApiUrl,
} from "../../utils/apiPath";
import TableWithPagination from "../../utils/TableWithPagination";

const GetCampaignInChargeHistoryPage = ({ toggleTheme }) => {
  const { campaignId } = useParams();

  const navigate = useNavigate();
  const goto = (page) => {
    navigate(page); // Navigate to the specified page
  };
  useEffect(() => {
    getCampaignDetails();
    getRectInChangeOfCampaignList()
  }, []);
  const [campaignData, setCampaignData] = useState({
    team_id: "",
    workspace_id: "",
    team_name: "",
    workspace_name: "",
    campaign_name: "",
  });

  const [get_recent_in_charge_data, set_get_recent_in_charge_data] = useState([])

  const getCampaignDetails = async () => {
    try {
      const response = await fetch(
        `${getFullApiUrl(GET_INDIVIDUAL_CAMPAIGN_DETAIL)}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            campaign_id: campaignId,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch campaign details");
      }

      const data = await response.json();
      setCampaignData(data);
    } catch (error) {
      console.error(error.message);
    }
  };

  const getRectInChangeOfCampaignList = async () => {
    try {
      const response = await fetch(
        `${getFullApiUrl(GET_RECENT_IN_CHARGE_OF_CAMPAIGN)}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            campaign_id: campaignId,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch campaign details");
      }

      const data = await response.json();
      set_get_recent_in_charge_data(data);
    } catch (error) {
      console.error(error.message);
    }
  };
  
  return (
    <div className="page_Section">
      <div className="leftSection_withMenu">
        <LeftSectionWithMenu />
      </div>
      <div className="rightSection_withMenu">
        <div className="headerSection_withMenu">
          <Header onToggleTheme={toggleTheme} />
        </div>
        <div className="bodySection">
          <Breadcrumbs
            id="Breadcrumbs"
            separator={<NavigateNext />}
            aria-label="breadcrumb"
          >
            <div className="breadcrumb_text">
              <Link
                component={RouterLink}
                to="/"
                color="inherit"
                underline="hover"
              >
                Home
              </Link>
            </div>
            <div className="breadcrumb_text">
              <Link
                component={RouterLink}
                to={`/my_teams`}
                color="inherit"
                underline="hover"
              >
                My Teams
              </Link>
            </div>
            <div className="breadcrumb_text">
              <Link
                component={RouterLink}
                to={`/my_team/${campaignData.team_id}`}
                color="inherit"
                underline="hover"
              >
                {campaignData.team_name}
              </Link>
            </div>
            <div className="breadcrumb_text">
              <Link
                component={RouterLink}
                to={`/my_team_workspaces/${campaignData.team_id}`}
                color="inherit"
                underline="hover"
              >
                {campaignData.team_name} Workspaces
              </Link>
            </div>
            <div className="breadcrumb_text">
              <Link
                component={RouterLink}
                to={`/my_workspace/${campaignData.workspace_id}`}
                color="inherit"
                underline="hover"
              >
                {campaignData.workspace_name}
              </Link>
            </div>
            <div className="breadcrumb_text">
              <Link
                component={RouterLink}
                to={`/my_campaign/${campaignId}`}
                color="inherit"
                underline="hover"
              >
                {campaignData.workspace_name} Campaigns
              </Link>
            </div>
            <div className="breadcrumb_text">{campaignData.campaign_name}</div>
          </Breadcrumbs>

          <div className="SolutionsSection">
          <div className="campaign_in_charge_container">
          <div className="campaign_in_charge_heading">Check Campaign In-Charge Update History</div>
          <TableWithPagination
            file_name={`get_in_charge_record`}

            data={get_recent_in_charge_data}
            key={get_recent_in_charge_data.length}

            fields={[
              {
                type: "id",
                field: "_id",
                headerName: "",
                minWidth: 1,
                width: 1,
                maxWidth: 380,
              },
              {
                type: "field",
                field: "campaign_name",
                headerName: "campaign",
                search_type: "text_search",
                minWidth: 150,
                width: 220,
                maxWidth: 240,
              },
              {
                type: "field",
                field: "new_in_charge_user_name",
                search_type: "text_search",
                headerName: "new_in_charge",
                minWidth: 290,
                width: 280,
                maxWidth: 350,
              },
              {
                type: "field",
                field: "updated_by_user_name",
                search_type: "text_search",
                headerName: "updated_by_user",
                minWidth: 290,
                width: 280,
                maxWidth: 350,
              },
              {
                type: "field",
                field: "updated_at",
                search_type: "text_search",
                headerName: "Last Updated",
                minWidth: 290,
                width: 280,
                maxWidth: 350,
              },
              
              {
                type: "action",
                field: "actions",
                headerName: "",
                minWidth: 1,
                width: 1,
                maxWidth: 200,
                renderCell: (params) => {
                  return (
                    <div className="action_icons_">
                      {/* <Tooltip title="Edit">
                    <IconButton onClick={() => handleEditsubscription(params)}>
                      <LiaEdit className="table_action_icon" />
                    </IconButton>
                  </Tooltip> */}

                      {/* <Tooltip title="Show Screenshot">
                        <IconButton
                          onClick={() => handle_show_screenshot(params)}
                        >
                          <CiImageOn className="table_action_icon" />
                        </IconButton>
                      </Tooltip> */}
                    </div>
                  );
                },
              },
            ]}
            bulk_actions={[
              {
                action: "add",
                renderCell: (params) => {
                  return (
                    // <Tooltip title="Add New Subscription">
                    //   <div
                    //     onClick={handleAddOpen}
                    //     className="bulk_classify_button download_icons"
                    //   >
                    //     <RiAddLine />
                    //   </div>
                    // </Tooltip>
                    <></>
                  );
                },
              },
              {
                action: "delete_multiple",
                renderCell: (params) => {
                  return (
                    // <Tooltip title="Delete Selected Subscription">
                    //   <div
                    //     onClick={() => handleBulkSubscriptionDeleteClick(params)}
                    //     className="bulk_delete_button download_icons"
                    //   >
                    //     <MdOutlineDeleteSweep fontSize={30}/>
                    //   </div>
                    // </Tooltip>
                    <></>
                  );
                },
              },
            ]}
            tableDisplay={"Table"}
          />
          </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GetCampaignInChargeHistoryPage;
